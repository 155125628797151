import firebase from './config'

const db = firebase.firestore();

export async function addClient({companyName, country, email, phone, firstName, lastName}) {
  const lastUpdatedOn = firebase.firestore.Timestamp.now();

  return db.collection("clients").add({
    companyName,
    country,
    email, 
    phone, 
    firstName,
    lastName,
    lastUpdatedOn
  });
}

export function fetchClientsFromFireStore(
    predicate,
    limit,
    lastDocSnapshot = null
  ) {
    //let clientId = predicate.get("client");
    let clientsRef = db
      .collection("clients");
      // .orderBy("createdOn", "desc")
      // .limit(limit);
  
   
    return clientsRef;
  }

  export function listenToClientProfile(clientId) {
     return db.collection('clients').doc(clientId);
  }
  