import { Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import EditIcon from '@mui/icons-material/Edit';

 export default function AccountDetails({ profile }) {
 
    return (
        <Card mb={6}>
            <CardContent>
                <Grid container justifyContent='space-between' spacing={2} p={3}>
                    <Typography variant="h6" gutterBottom>
                        Account Details
                    </Typography>

                    <IconButton aria-label="edit">
                        <EditIcon />
                    </IconButton>

                </Grid>

                <Table>
                    <TableBody>
                        <TableRow key='vehicleID'>
                            <TableCell component="th" scope="row">
                                Company Name
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {profile?.companyName} ({profile?.country})
                            </TableCell>
                        </TableRow>

                        <TableRow key='odeometerreading'>
                            <TableCell component="th" scope="row">
                                Trading Name
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {profile?.tradingName}
                            </TableCell>
                        </TableRow>

                        <TableRow key='trailerresdsdg'>
                            <TableCell component="th" scope="row">
                                Identifier
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {profile?.identifier}
                            </TableCell>
                        </TableRow>
                        <TableRow key='1123'>
                            <TableCell component="th" scope="row">
                                Address
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {profile?.address}
                            </TableCell>
                        </TableRow>

                        <TableRow key='dateLogged'>
                            <TableCell component="th" scope="row">
                                Contact
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {profile?.firstName} {profile?.lastName}
                            </TableCell>
                        </TableRow>

                    
                        <TableRow key='loggedBy'>
                            <TableCell component="th" scope="row">
                                Email
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {profile?.email}
                            </TableCell>
                        </TableRow>

                        <TableRow key='loggedBy11'>
                            <TableCell component="th" scope="row">
                                Phone
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {profile?.phone}
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
