import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dataFromSnapshotAsync } from "../../fireStore/frestoreService";
import { fetchHealthStatisticsForClient } from "../../fireStore/dashboard";
import { listenToWorkOrderSummaryReport } from "../../fireStore/workorder";


export const fetchStatsForClient = createAsyncThunk(
    "dashboard/fetchStats",
    async ({ clientId }) => {

        let response = await fetchHealthStatisticsForClient(clientId).get();
        return dataFromSnapshotAsync(response, []);
    }
);


const initialState = {
    stats: null,
    error: null,
    status: null,
    isLoading: false,
    reminders: [],
    woSummary: null
};

var woSummaryDefault = [
    ["Jan", 0, 0],
    ["Feb", 0, 0],
    ["Mar", 0, 0],
    ["Apr", 0, 0],
    ["May", 0, 0],
    ["Jun", 0, 0],
    ["Jul", 0, 0],
    ["Aug", 0, 0],
    ["Sep", 0, 0],
    ["Oct", 0, 0],
    ["Nov", 0, 0],
    ["Dec", 0, 0]
]

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setReminders: (state, action) => {
            state.reminders = action.payload.reminders;
        },
        setStats: (state, action) => {
            state.stats = action.payload.stats;
        },
        setWorkOrderSummary: (state, action) => {
            var summaryObj = [...woSummaryDefault];
            if (action.payload.woSummary && action.payload.woSummary?.items) {
                action.payload.woSummary?.items.forEach(item => {
                    var index = item.status === 'Open' ? 1 : 2;
                    var monthItem = [...summaryObj.filter(x => x[0] === item.createdDateMonth)[0]];
                    var monthIndex = summaryObj.indexOf(summaryObj.filter(x => x[0] === item.createdDateMonth)[0]);
                    monthItem[index] = item.grandTotal;
                    summaryObj[monthIndex] = monthItem;
                });
            }
            state.woSummary = [...summaryObj];
        }
    } 
})

export default dashboardSlice.reducer;