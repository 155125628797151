import { StepContent, Button, Card, CardContent, Grid, Stepper, Typography, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { ReactComponent as Logo } from "../../../vendor/logo.svg";
import styled from "styled-components/macro";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SignUpAccount from "./SignUpAccount";
import SignUpPayment from "./SignUpPayment";
import useAuth from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { signupSlice } from "../../../redux/slices/signup";
import SignUpReview from "./SignUpReview";
import SignUpOrderSummary from "./SignUpOrderSummary";
import { Helmet } from "react-helmet-async";




const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;


const signupSteps = [
    {
        label: 'Account',
        key: 'account',
    },
    {
        label: 'Payment Method',
        key: 'payment',
    },
    {
        label: 'Review',
        key: 'review',
    },
];


export default function SignUpWithPaymentMethod() {
    const { user, signOut } = useAuth();
    const [activeStep, setActiveStep] = React.useState(0);
    const [isAuthenticated, setAuthenticationStatus] = React.useState(false);
    const dispatch = useDispatch();
    const { paymentMethod } = useSelector(state => state.signup);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSignout = () => {
        signOut();
        dispatch(signupSlice.actions.clearPaymentMethod());
    }


    const handleClearPaymentMethod = () => {
        dispatch(signupSlice.actions.clearPaymentMethod());
    }

    useEffect(() => {
        if (user !== null && user.id !== undefined) {
            setAuthenticationStatus(true);
            if (paymentMethod != null) {
                setActiveStep(2);
            } else {
                setActiveStep(1);
            }
        }
        else {
            setAuthenticationStatus(false);
            setActiveStep(0);
        }
    }, [user, paymentMethod]);



    return (
        <React.Fragment>
            <Brand />
            <Wrapper>
                <Helmet title="Sign up" />

                <Typography component="h1" variant="h4" align="center" gutterBottom>
                    Get started
                </Typography>
                <Typography component="h2" variant="body1" align="center">
                    Thanks for choosing the smartest way to manage your fleets
                </Typography>

                    <Grid container mt={5}>
                        <Grid item xs={12} >
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {signupSteps.map((step) => (
                                    <Step key={step.key}>
                                        <StepLabel>{step.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={12} mt={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}  sm={8}  >
                                    <Stepper activeStep={activeStep} orientation="vertical">
                                        {signupSteps.map((step, index) => (
                                            <Step key={step.key}>
                                                <StepLabel sx={{ paddingTop: '0' }}>
                                                    <Grid container justifyContent='space-between' alignItems='center'>
                                                        <Typography variant="h5" >{step.label}</Typography>
                                                        {index === 0 && !isAuthenticated &&
                                                            <Typography>Already have an account?
                                                                <Button href="/auth/sign-in">Sign In
                                                                </Button>
                                                            </Typography>}

                                                        {index === 0 && isAuthenticated &&
                                                            <Typography>Logged in as {user.email}
                                                                <Button type="button" onClick={handleSignout}>Sign Out</Button>
                                                            </Typography>}

                                                        {index === 1 && paymentMethod !== null &&
                                                            <Typography>
                                                                **** **** **** {paymentMethod.card.last4} {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
                                                                <Button type="button" onClick={handleClearPaymentMethod}>Clear</Button>
                                                            </Typography>}
                                                    </Grid>
                                                </StepLabel>

                                                <StepContent sx={{ paddingRight: '0' }}>
                                                    <Card>
                                                        <CardContent>

                                                            {step.key === 'account' && <SignUpAccount handleNext={handleNext} handleBack={handleBack} />}
                                                            {step.key === 'payment' && <SignUpPayment handleNext={handleNext} />}
                                                            {step.key === 'review' && <SignUpReview />}

                                                        </CardContent>
                                                    </Card>
                                                </StepContent>

                                            </Step>

                                        ))}
                                    </Stepper>
                                </Grid>

                                <Grid item xs={12} sm={4} >
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom>
                                                Order Summary
                                            </Typography>

                                            <SignUpOrderSummary />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

            </Wrapper>
        </React.Fragment>
    )
}