import { Select } from "@mui/material";
import React from "react";



const SelectForFormik = ({ children, form, field, handleChange }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={e => {
        setFieldValue(name, e.target.value);
        if(handleChange !== undefined) handleChange(e.target.value)
      }}
    >
      {children}
    </Select>
  );
};

export default SelectForFormik;