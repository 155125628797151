import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { getUserProfile } from "../../fireStore/userService";

import useAuth from "../../hooks/useAuth";
import { clientSlice } from "../../redux/slices/clients";


function AuthGuard({ children }) {

  const [open, setOpen] = useState(false);
  const { isAuthenticated, isInitialized, user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    navigate('/auth/sign-up')
  };

  useEffect(() => {
    if(typeof user === 'undefined') return;
    async function fetchUser() {
      await getUserProfile(user?.id).then(snap => {
        const profileData = snap.data();

        dispatch(clientSlice.actions.setUserProfile({ userprofile: profileData }));

        if (profileData?.rootUser === true && typeof profileData?.stripeActiveSubscriptionID === 'undefined') {
          setOpen(true);
        }

      })
    }

    fetchUser();
  }, [user])

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }


  return <React.Fragment>
    <Dialog open={open} >
      <DialogTitle>Subscription</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your subscription and payment method setup was not complete, please click below to complete the setup.
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >Setup Subscription</Button>
      </DialogActions>
    </Dialog>

    {children}
  </React.Fragment>;
}

export default AuthGuard;
