import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    IconButton,
    Paper as MuiPaper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
} from "@mui/material";

import { spacing } from "@mui/system";
import { UserAccountTableHead } from "./userAccountTableHead";
import { UserAccountTableToolBar } from "./userAccountTableToolBar";
import UserAccountActions from "./userAccountActions";



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
        el,
        index,
    }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
}

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
`;


export default function UserAccountTable({ users, newItemAdded }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("customer");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

    return (
        <div>
            <Paper>
                <UserAccountTableToolBar numSelected={selected.length} newItemAdded={newItemAdded} />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="enhanced table"
                    >
                        <UserAccountTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={users.length}
                        />
                        <TableBody>
                            {stableSort(users, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {

                                    const { userInviteId, email, name, role, userId, status } = row;
                                    const id = typeof userId === undefined ? userInviteId : userId;
                                    const isItemSelected = isSelected(id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`${id}-${index}`}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                    onClick={(event) => handleClick(event, id)}
                                                />
                                            </TableCell>

                                            <TableCell align="left"  >
                                                <Button mr={2} component={Link} to={`/admin/account/${id}`}>
                                                    {name}
                                                </Button>

                                            </TableCell>
                                            <TableCell align="left">
                                                <Stack direction={{ xs: "row", sm: "row" }}>
                                                    {status === 'active' && <Chip label='Active' color="success" variant="filled" sx={{ mr: 1 }} />}
                                                    {status === 'invited' && <Chip label='Invited' color="warning" variant="filled" sx={{ mr: 1 }} />}
                                                    {status === 'deleted' && <Chip label='Deleted' color="error" variant="filled" sx={{ mr: 1 }} />}
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">{email}</TableCell>
                                            <TableCell align="left">{role}</TableCell>
                                            <TableCell align="right">
                                                <UserAccountActions userItem={row} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}