import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'


TimeAgo.addDefaultLocale(en)

export default function formatUsingTimeAgo(dateValue) {
    
    const timeAgo = new TimeAgo('en-US');
    return timeAgo.format(dateValue);
}