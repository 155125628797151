import { format, parse } from "date-fns";
import firebase from "../fireStore/config";

const CloudFunctions = firebase.app().functions('europe-west2');
 
export async function createNewWorkOrder(values, clientId, userId, selectedVehicle, issues, lineItems, summary, comment) {
    const cloudFunctionName = 'createNewWorkOrder';
    const createNewWorkOrderFunc = CloudFunctions.httpsCallable(cloudFunctionName);

    var { status, assignedTo, issuedBy, issuedDate, startDate, completionDate, vendor, invoiceNumber, poNumber, labels } = values.gn
    
    if(vendor === null) {
        vendor = {t: 'NP', id:''}
        invoiceNumber= '', 
        poNumber = ''
    }

    const issuedDateString = issuedDate !== null ?  format(issuedDate, 'yyyy-MM-dd') : 'NP';
    const startDateString = startDate !== null ?  format(startDate, 'yyyy-MM-dd') : 'NP';  
    const completionDateString = completionDate !== null ?  format(completionDate, 'yyyy-MM-dd') : 'NP';  

    const data = {
        selectedVehicle,
        status,
        assignedTo,
        issuedBy,
        issueDate: issuedDateString,
        startDate: startDateString,
        completionDate: completionDateString,
        vendor,
        invoiceNumber,
        poNumber,
        labels,
        issues,
        lineItems,
        summary,
        comment,
        clientId: clientId,
        createdByUserId: userId
    }

    return await createNewWorkOrderFunc(data).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })

}

export async function updateWorkOrderAsCompleted(completedBy, completedDate, comment, userId, clientId, workorderId, vehicleId) {
    const cloudFunctionName = 'updateWorkOrderStatusToCompleted';
    const updateWorkOrderStatusToCompleted = CloudFunctions.httpsCallable(cloudFunctionName);
    
    const completedDateStr = format(completedDate, 'yyyy-MM-dd');
    const data = {
        completedBy: completedBy, 
        completionDate : completedDateStr, 
        comment: comment,
        workorderDocId: workorderId, 
        vehicleId: vehicleId, 
        updatedUserId: userId, 
        clientId: clientId
    }

    return await updateWorkOrderStatusToCompleted(data).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}

export async function updateWorkOrderAsRejected(comment, userId, clientId, workorderId, vehicleId) {
    const cloudFunctionName = 'updateWorkOrderStatusToRejected';
    const updateWorkOrderStatusToRejected = CloudFunctions.httpsCallable(cloudFunctionName);

    const data = {
        comment: comment,
        workorderDocId: workorderId, 
        vehicleId: vehicleId, 
        updatedUserId: userId, 
        clientId: clientId
    }

    return await updateWorkOrderStatusToRejected(data).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}
