import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { ReactComponent as Logo } from "../../../vendor/logo.svg";
import { Paper, Typography } from "@mui/material";
import SignUpWithCodeForm from './SignUpWithCodeForm';

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export default function SignUpWithCode() {


    return (
        <React.Fragment>
            <Brand />
            <Wrapper>
                <Helmet title="Join" />

                <Typography component="h1" variant="h4" align="center" gutterBottom>
                    Join with Invite Code
                </Typography>
                <Typography component="h2" variant="body1" align="center">
                    Please enter the invite code that you received via email, make sure you the same email address below.  
                </Typography>

             <SignUpWithCodeForm />
            </Wrapper>
        </React.Fragment>
    )
}