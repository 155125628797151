import { Divider, IconButton, InputBase, List, ListItem, ListItemText, Paper } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { GridAddIcon } from "@mui/x-data-grid";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { addServiceTask } from "../../../fireStore/workorder";
import useAuth from "../../../hooks/useAuth";

export default function NewServiceTaskOption({newItemAdded}) {
    const [adding, setAdding] = useState(false);
    const [taskTitle, setTaskTitle] = useState('');

    const {user} = useAuth();

    function sleep(ms, resolve) {
        return new Promise(() => setTimeout(() => { return setAdding(false) }, ms));
    }


    const handleAdd = async () => {
        setAdding(true);
        await addServiceTask(taskTitle, user.clientId);

        await sleep(4000); 
        setAdding(false);
        setTaskTitle('');
        newItemAdded(state => !state)
    }

    const onTaskTitleChange = (event) => {
        setTaskTitle(event.target.value);
    }

    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >
            <IconButton sx={{ p: '10px' }} aria-label="menu">
                <MenuIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="New Service task"
                inputProps={{ 'aria-label': 'search google maps' }}
                value={taskTitle}
                onChange={onTaskTitleChange}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            {adding ? <LoadingButton loading={true} sx={{ p: '10px' }}  /> :
                <IconButton color="primary" sx={{ p: '10px' }}  onClick={handleAdd} disabled={taskTitle.replaceAll(' ','') === ''}>
                    <GridAddIcon />
                </IconButton>}
        </Paper>
    )
}