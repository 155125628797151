import React from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
    CardContent,
    Grid,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
    Box,
    LinearProgress,
    Tabs,
    Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DollarSign } from "react-feather";
import VehicleProfile from "./vehicleProfile";
import VehicleIssueChart from "./vehicleIssue/vehicleIssueChart";
import VehicleEngine from "./vehicleEngine";
import VehicleIssueTable from "./vehicleIssue/vehicleIssueTable";
import VehicleReminderTable from "./vehicleReminder/vehicleReminderTable";
import useFireStoreDoc from "../../../hooks/useFireStoreDoc";
import { addVehicleAttachment, deleteVehicleAttachment, listenToVehicleAttachments, listenToVehicleFromFireStore } from "../../../fireStore/vehiclesCollection";
import { vehicleSlice } from "../../../redux/slices/vehicles";
import { useDispatch, useSelector } from "react-redux";
import VehicleViewToolBar from "./vehicleViewToolBar";
import VehicleGeneral from "./vehicleGeneral";
import UploadAttachment from "../../common/uploadAttachments";
import useAuth from "../../../hooks/useAuth";
import useFireStoreCollection from "../../../hooks/useFireStoreCollection";
import { getClientContext } from "../../../common/util";


const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function VehicleDetailPage() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [value, setValue] = React.useState(0);
    const { user } = useAuth();
    const { selectedVehicle, selectedVehicleAttachments } = useSelector(x => x.vehicles);
    const { userprofile } = useSelector(state => state.clients);

    useFireStoreDoc({
        shouldExecute: !!id,
        query: () => listenToVehicleFromFireStore(id),
        data: (vehicle) => dispatch(vehicleSlice.actions.setSelectedVehicle({ selectedVehicle: vehicle })),
        deps: [id, dispatch],
        refFields: [],
    });

    useFireStoreCollection({
        query: () => listenToVehicleAttachments(id),
        data: (attachments) => dispatch(vehicleSlice.actions.setVehicleAttachments({ attachments: attachments })),
        deps: [id, dispatch],
        refFields: [],
    });

    const handleFileUpload = async (downloadUrl, name, size) => {
        const clientContext = getClientContext(userprofile);
        await addVehicleAttachment(id, downloadUrl, name, size, clientContext)
    }

    const handleFileDelete = (attachment) => {
        return deleteVehicleAttachment(attachment.vehicleId, attachment.id);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (selectedVehicle === null) return <div></div>
    return (
        <React.Fragment>
            <Helmet title="Vehicle Detail" />
            <Typography variant="h3" gutterBottom display="inline">
                Vehicle {selectedVehicle.reg}
            </Typography>



            <Grid container spacing={2} justifyContent='space-between' >
                <Grid item>
                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to="/dashboard">
                            Dashboard
                        </Link>
                        <Link component={NavLink} to="/vehicles">
                            Vehicles
                        </Link>
                        <Typography>Vehicle Details</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <VehicleViewToolBar selectedVehicle={selectedVehicle} />
                </Grid>
            </Grid>
            <Divider my={6} />

            <Tabs value={value} onChange={handleChange}  >
                <Tab label="General" />
                <Tab label="Issues" />
                <Tab label="Reminders" />
                <Tab label="Attachments" />
            </Tabs>

            {value === 0 &&

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={6} lg={6} xl={4}>
                        <VehicleGeneral vehicle={selectedVehicle} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={4}>
                        <VehicleProfile vehicle={selectedVehicle} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={4}>
                        <VehicleEngine vehicle={selectedVehicle} />
                    </Grid>
                </Grid>
            }

            {value === 1 &&

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} lg={12} xl={8}>
                        <VehicleIssueTable vehicle={selectedVehicle} />
                    </Grid>
                    <Grid item xs={12} lg={6} xl={4}>
                        <VehicleIssueChart vehicle={selectedVehicle} />
                    </Grid>
                </Grid>
            }

            {value === 2 &&

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}  >
                        <VehicleReminderTable vehicle={selectedVehicle} />
                    </Grid>
                </Grid>
            }

            {value === 3 &&
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}  >
                        <UploadAttachment
                            storagePath={`${user?.clientId}/${id}/attachments`}
                            handleFileUpload={handleFileUpload}
                            attachments={selectedVehicleAttachments}
                            handleAttachmentDelete={handleFileDelete}
                            accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf,.xlsx"
                        />
                    </Grid>
                </Grid>
            }

        </React.Fragment>
    );
}

export default VehicleDetailPage;
