import { LoadingButton } from "@mui/lab";
import { Alert, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStripe } from '@stripe/react-stripe-js';
import firebase from "../../../fireStore/config";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { add, format } from "date-fns";
import { getUserProfile } from "../../../fireStore/userService";
import PrivacyLabel from "../../common/privacyPolicyLabel";

export default function SignUpReview() {
    const stripe = useStripe();


    const [errorMessage, setErrorMessage] = useState(null);// eslint-disable-line
    const [processMessage, setProcessMessage] = useState(null);
    const [processing, setProcessing] = useState(false);
    const CloudFunctions = firebase.app().functions('europe-west2');
    const { subscription, paymentMethod } = useSelector(state => state.signup);
    const [terms, setTermsConditions] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const subscribe = async (e) => {
        setProcessing(true);
        setErrorMessage(null);

        setProcessMessage('Setting up your subscription...')
        if (!stripe) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const createSubscription = CloudFunctions.httpsCallable('createStripeSubscription');
        await createSubscription({
            planId: subscription.id,
            userId: user.id,
            paymentMethodId: paymentMethod.id,
            billing: {
                country: 'IE',
                state: 'Dublin'
            }
        }).then(res => {
            setProcessing(false);
            setProcessMessage('Subscription setup completed successfully')
            navigate("/dashboard");
        }).catch(err => {
            // if (!mountedRef.current) return null
            setProcessing(false);
            setErrorMessage(err.message);
            setProcessMessage(err.message)
        });
    }

    return (
        <Grid container spacing={2}  >
            <Grid item xs={12} >

                {processMessage !== null && processMessage !== '' && (
                    <Alert mt={2} mb={1} severity="info">
                        <Typography>{processMessage}</Typography>
                    </Alert>
                )}

            </Grid>

            <Grid item xs={12} >
                <Typography variant="h6">You almost there. </Typography>
            </Grid>

            {subscription?.name === 'Free' &&
                <Grid item xs={12} >
                    <Typography>
                        You selected our free plan, which will be always free. 
                    </Typography>
                </Grid>
            }

            {subscription?.name !== 'Free' &&
                <>
                    <Grid item xs={12} >
                        <Typography>Please enjoy your FREE first 1 month subscription, we are always there to help setting up the system for you.
                            You will not be charged any if you wish cancel the subscription at any stage during this trial period.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} >
                        <Typography> Your trial period end on <b>{format(add(new Date(), { days: 31 }), "MMM dd yyyy")}</b></Typography>

                    </Grid>
                </>
            }


            <Grid item xs={12}>
                <FormControlLabel control={<Checkbox value={terms} onChange={(e) => {
                    setTermsConditions(e.target.checked);
                }} />} label={<PrivacyLabel />} />
            </Grid>

            <Grid item xs={12}>
                <LoadingButton
                    variant="contained"
                    type="button"
                    disabled={!terms}
                    loading={processing}
                    sx={{ ml: 2, mt: 1, mr: 1 }}
                    onClick={subscribe}
                >
                    Join Subscription
                </LoadingButton>
            </Grid>

        </Grid>)
}