import {
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider, Grid,
    Link, Tab, Tabs, Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { listenToClientProfile } from "../../fireStore/clientsCollection";
import useAuth from "../../hooks/useAuth";
import useFireStoreDoc from "../../hooks/useFireStoreDoc";
import { clientSlice } from "../../redux/slices/clients";
import AccountDetails from "./accountDetails";
import AccountSubscriptionDetails from "./accountSubscriptionDetails";
import BillingPage from "./billing";
import UserAccounts from './userAccount/userAccounts';


// const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AccountPage() {

    const dispatch = useDispatch();
    const { profile, userprofile } = useSelector(x => x.clients);
    const [value, setValue] = React.useState(0);


    const { user } = useAuth();
    let clientId = user?.clientId;

    useFireStoreDoc({
        shouldExecute: typeof clientId !== 'undefined',
        query: () => listenToClientProfile(clientId),
        data: (profile) => dispatch(clientSlice.actions.fetchClientProfile({ profile: profile })),
        deps: [clientId, dispatch],
        refFields: [],
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const isSubscriptionUser = () => {
        const subscription = userprofile?.stripeActiveSubscriptionID;
        return typeof subscription !== 'undefined'
    }

    return (
        <React.Fragment>
            <Helmet title="Account" />
            <Typography variant="h3" gutterBottom display="inline">
                {profile?.companyName}
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Link component={NavLink} to="/admin/account">
                    Admin
                </Link>
                <Typography>Account</Typography>
            </Breadcrumbs>

            <Divider my={6} />


            <Tabs value={value} onChange={handleChange}  >
                <Tab label="Account" />
                {isSubscriptionUser() &&
                    <Tab label="Users" />
                }
                {isSubscriptionUser() &&
                    <Tab label="Billing" />
                }
            </Tabs>

            {value === 0 &&
                <Grid container spacing={2} mt={2} justifyContent='center' alignItems='center' >
                    <Grid item xs={12} md={8} xl={6} >
                        <AccountDetails profile={profile} />
                    </Grid>
                </Grid>
            }

            {value === 1 &&
                <Grid container spacing={2} mt={2}   >
                    <Grid item xs={12} md={12} xl={12} >
                        <UserAccounts />
                    </Grid>
                </Grid>
            }

            {value === 2 &&
                <Grid container spacing={2} mt={2} justifyContent='center' alignItems='center' >
                    <Grid item xs={12} md={12} xl={8} >
                        <BillingPage profile={profile} />
                    </Grid>
                </Grid>
            }

        </React.Fragment>
    );
}

export default AccountPage;
