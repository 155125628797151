
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Popover as MuiPopover,
    Typography
} from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';
import styled from "styled-components";
import { useRef, useState } from "react";
import React from "react";
 
import { format } from "date-fns";
import useFireStoreCollection from "../../hooks/useFireStoreCollection";


const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 350px;
    height: 500px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

export default function RecordedActionMenu({ query, dispatchAction,recordedActions, dispatch }) {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    useFireStoreCollection({
        query: query,
        data: (actions) => dispatchAction(actions),
        deps: [dispatch],
        refFields: [],
    });

    return (
        <>
            <IconButton color="primary" onClick={() => setOpen(true)} ref={ref}>
                <HistoryIcon />
            </IconButton>
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
            >
                <React.Fragment>
                    <List
                        disablePadding dense={true}
                        sx={{
                            width: '100%',
                            maxWidth: 350,
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 495,
                            '& ul': { padding: 0 },
                            padding: 1
                        }}
                        subheader={<ListSubheader>History</ListSubheader>}

                    >
                        {recordedActions.length === 0 && <ListItem key='23'><i>No history to display</i></ListItem>}
                        {recordedActions.length > 0 && recordedActions.map(item => (

                            <ListItem key={item.id}>
                                <ListItemText
                                    primary={item.title}
                                    primaryTypographyProps={{
                                        variant: "subtitle2",
                                        color: "text.primary",
                                    }}

                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'block' }}
                                                component="span"
                                                variant="subtitle2"
                                                color="text.secondary"
                                            >
                                                {item.details}
                                            </Typography>
                                            <Typography
                                                sx={{ display: 'block' }}
                                                component="span"
                                                variant="body"
                                                color="text.secondary"
                                            >
                                                on {format(item.actionTime, 'dd/MM/yyyy hh:mm')} by {item.userName}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            </Popover>
        </>
    )
}