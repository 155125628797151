import { Divider, IconButton, List, ListItem, ListItemButton, ListItemText, ListSubheader, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { GridAddIcon } from "@mui/x-data-grid";
import NewServiceTaskOption from "./serviceTaskNew";
import { listenServiceTasksFromLookup } from "../../../fireStore/workorder";
import useAuth from "../../../hooks/useAuth";
import useFireStoreCollection from "../../../hooks/useFireStoreCollection";
import { serviceTasksSlice } from "../../../redux/slices/servicetasks";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";

function ServiceTaskItem({ task, addServiceTask, closeServiceTask }) {
    return (
        <ListItem divider
            secondaryAction={
                <IconButton edge="end" onClick={() => {
                    addServiceTask(task)
                    closeServiceTask();
                }}>
                    <GridAddIcon />
                </IconButton>
            }
        >
            <ListItemButton>
                <ListItemText
                    primary={task.t}
                    primaryTypographyProps={{
                        variant: "subtitle2",
                        color: "textPrimary",
                    }}
                />
            </ListItemButton>
        </ListItem>
    );
}

export default function ServiceTaskList({ addServiceTask, closeServiceTask }) {

    const [itemAdded, newItemAdded] = useState(false);
    const dispatch = useDispatch();

    const { tasks } = useSelector(state => state.serviceTasks);

    const { user } = useAuth();
    let clientId = user?.clientId;

    useFireStoreCollection({
        query: () => listenServiceTasksFromLookup(clientId),
        data: (taskDocuments) => transformToLookUp(taskDocuments),
        deps: [user, itemAdded],
        refFields: [],
    });

    function transformToLookUp(taskDocuments) {
        let taskLookUp = [];
        taskDocuments.forEach(doc => {
            taskLookUp = [...taskLookUp, ...doc.tasks]
        });

        dispatch(serviceTasksSlice.actions.fetchServiceTasks({ tasks: taskLookUp.sort((a, b) => a.t.localeCompare(b.t)) }));
    }

    return (
        <Box >
            <NewServiceTaskOption newItemAdded={newItemAdded} />
            <Divider />
            <List
                disablePadding dense={true}
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    '& ul': { padding: 0 },
                    padding: 1
                }}
                subheader={<ListSubheader>Service Tasks</ListSubheader>}

            >
                {tasks.map(task => (
                    <ServiceTaskItem key={task.id} task={task} addServiceTask={addServiceTask} closeServiceTask={closeServiceTask} />
                ))}
            </List>
        </Box>


    )
}