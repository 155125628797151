import React, { useRef, useState } from "react";

import {
  IconButton,
  Toolbar,
  Tooltip,
  Popover as MuiPopover,
  Typography,
} from "@mui/material";
import {
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import styled from "styled-components/macro";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { Box } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import VehicleReminderForm from "./vehicleReminderForm";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 350px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;


export const VehicleReminderTableToolBar = ({ vehicle }) => {

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const { user } = useAuth();
  let clientId = user?.clientId;

  return (
    <>
      <IconButton aria-label="Delete" size="large" onClick={() => setOpen(true)} ref={ref} >
        <AddOutlinedIcon />
      </IconButton>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
        
      >
        <Box padding={4}>

          <Typography variant="h6">
            Add Reminder
          </Typography>

          <VehicleReminderForm vehicle={vehicle} setOpen={setOpen} />
        </Box>
      </Popover>
    </>
  );
};