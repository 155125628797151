import { Button, Card, CardContent, Chip, Grid, Tab, Table, TableBody, TableCell, TableRow, Tabs, Typography } from "@mui/material";

 
import styled from "styled-components/macro";
import React, { useState } from "react";
import IssueDetailSectionGrid from "./issueDetailSectionGrid";
import IssueImages from "./issueImageList";

const TableWrapper = styled.div`
  height: 550px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


export default function IssueDetailGeneral({ selectedIssue }) {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card>
            <CardContent>
                <Tabs value={value} onChange={handleChange}  >
                    <Tab label="Details" />
                    <Tab label="Images" />
                </Tabs>

                <TableWrapper>
                    {value === 0 &&
                        <IssueDetailSectionGrid selectedIssue={selectedIssue} />
                    }
                     {value === 1 &&
                        <IssueImages issue={selectedIssue} />
                    }
                </TableWrapper>
            </CardContent>
        </Card>
    )
}