import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
    Alert,
    Box,
    Breadcrumbs as MuiBreadcrumbs,
    Button,
    Card,
    CardContent,
    Divider as MuiDivider,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";

import WorkOrderItemTable from "./items/workOrderItemTable";
import WorkOrderIssueTableView from "./issues/workOrderIssueTableView";
import WorkItemsReadOnlyMiniFooter from "./items/workItemsReadOnlyMiniFooter";
import WorkOrderGeneralView from "./view/workOrderGeneralView";
import { useParams } from "react-router-dom";
import useFireStoreDoc from "../../hooks/useFireStoreDoc";
import { addWorkOrderAttachment, deleteWorkOrderAttachment, listenToWorkOrder, listenToWorkOrderAttachments } from "../../fireStore/workorder";
import { workorderSlice } from "../../redux/slices/workorder";
import { currencySymbol } from "../../fireStore/collections";
import Loader from "../../components/Loader";
import WorkOrderToolBar from "./view/workOrderToolBar";
import WorkOrderVendorView from "./view/workOrderVendor";
import WorkorderSummaryChart from "./view/workorderSummaryChart";
import WorkOrderLabels from "./view/workOrderLabels";
import WorkOrderSummaryTab from "./view/workOrderSummaryTab";
import WorkOrderDetailsTab from "./view/workOrderDetailsTab";
import UploadAttachment from "../common/uploadAttachments";
import useFireStoreCollection from "../../hooks/useFireStoreCollection";


const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);



function WorkOrderView() {
    const { vid, wid } = useParams();
    const { selectedWorkOrder, selectedWorkOrderAttachments } = useSelector(state => state.workorder);
    const { user } = useAuth();
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);

    useFireStoreDoc({
        shouldExecute: !!wid && !!vid,
        query: () => listenToWorkOrder(wid, vid),
        data: (workorder) => {
            dispatch(workorderSlice.actions.setSelectedWorkOrder({ workorder: workorder }));
            dispatch(workorderSlice.actions.updateWorkOrderLookUp({ workorder: workorder }));
        },
        deps: [wid, vid, dispatch],
        refFields: [],
    });

    useFireStoreCollection({
        query: () => listenToWorkOrderAttachments(vid, wid),
        data: (attachments) => dispatch(workorderSlice.actions.setWorkOrderAttachments({ attachments: attachments })),
        deps: [wid, vid, dispatch],
        refFields: [],
    });

    const handleFileUpload = async (downloadUrl, name, size) => {
        await addWorkOrderAttachment(vid, wid, downloadUrl, name, size, user?.id, user?.displayName)
    }

    const handleFileDelete = (attachment) => {
        return deleteWorkOrderAttachment(attachment.vehicleId, attachment.workorderId, attachment.id);
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (selectedWorkOrder === null) return <Loader />


    return (
        <React.Fragment>
            <Helmet title="Work Order" />
            <Typography variant="h3" gutterBottom display="inline">
                Work Order #{selectedWorkOrder?.workorderId}
            </Typography>

            <Grid container spacing={2} justifyContent='space-between' >
                <Grid item>
                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to="/dashboard">
                            Dashboard
                        </Link>
                        <Link component={NavLink} to="/service/workorders">
                            Work Orders
                        </Link>
                        <Typography>Work Order</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <WorkOrderToolBar workorderId={wid} vehicleId={vid} status={selectedWorkOrder.status} />
                </Grid>
            </Grid>

            <Divider my={4} />


            <Tabs value={value} onChange={handleChange}  >
                <Tab label="Summary" />
                <Tab label="Details" />
                <Tab label="Linked Issues" />
                <Tab label="Attachments" />
            </Tabs>

            {value === 0 &&
                <WorkOrderSummaryTab selectedWorkOrder={selectedWorkOrder} />
            }

            {value === 1 &&
                <WorkOrderDetailsTab selectedWorkOrder={selectedWorkOrder} />
            }

            {value === 2 &&
                <Grid container mt={2}>
                    <WorkOrderIssueTableView selectedWorkOrder={selectedWorkOrder} />
                </Grid>
            }

            {value === 3 &&
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}  >
                        <UploadAttachment
                            storagePath={`${user?.clientId}/${vid}/${wid}/attachments`}
                            handleFileUpload={handleFileUpload}
                            attachments={selectedWorkOrderAttachments}
                            handleAttachmentDelete={handleFileDelete}
                            accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf,.xlsx"  />
                    </Grid>
                </Grid>

            }


        </React.Fragment>
    );
}

export default WorkOrderView;
