import { add } from 'date-fns';
import { reminderCollection } from './collections';
import firebase from './config'

const db = firebase.firestore();
const vehicle_stats_document_id = 'Vehicle_Statistics'

export function fetchHealthStatisticsForClient(clientId) {

    return db.collection("clients").doc(clientId)
        .collection('statistics').doc(vehicle_stats_document_id);
}


export function fetchRemindersForDashboard(clientId) {
    let filterStartDate = add(new Date(), { days: -100 });
    filterStartDate.setHours(0, 0, 0, 0)

    let filterEndDate = add(new Date(), { days: 500 });
    filterEndDate.setHours(23, 59, 59, 999)

    let start = firebase.firestore.Timestamp.fromDate(filterStartDate);
    let end = firebase.firestore.Timestamp.fromDate(filterEndDate);

    return db.collectionGroup(reminderCollection)
        .where('expDate', '>', start)
        .where('expDate', '<', end)
        .where('clientId', '==', clientId)
        .where('isDeleted', '==', false)
        .orderBy('expDate', 'asc')
        .limit(8);
}
