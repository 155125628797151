export const newStatus = 'new';
export const assigned = 'assigned';
export const inprogress = 'inprogress';
export const resolved = 'resolved';
export const ignored = 'ignored';
export const deleted = 'deleted';


export const majorSeverity = 'Major'
export const criticalSeverity = 'Critical'
export const minorSeverity = 'Minor'
