import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vehicles: {
        items: [],
        maxPartition: 0,
        lastLoadedPartition: 0,
        length: 0
    },
    recordedActions: [],
    selectedVehicle: null,
    selectedVehicleIssues: [],
    selectedVehicleReminders: [],
    selectedVehicleAttachments: [], 
    status: null,
    error: null,
    isLoading: false
};

export const vehicleSlice = createSlice({
    name: "vechiles",
    initialState,
    reducers: {
        initVehicles: (state, action) => {
            const { maxPartition, length, items, lastLoadedPartition } = action.payload;
            state.vehicles.length = length;
            state.vehicles.maxPartition = maxPartition;
            state.vehicles.items = [...items];
            state.vehicles.lastLoadedPartition = lastLoadedPartition;
        },
        setVehicles: (state, action) => {
            const { items, lastLoadedPartition } = action.payload;
            if (lastLoadedPartition < state.vehicles.lastLoadedPartition) {
                state.vehicles.items = [...state.vehicles.items, ...items];
                state.vehicles.lastLoadedPartition = lastLoadedPartition;
            }
        },
        addVehicle: (state, action) => {
            const {id, reg, make, model, fuel, transmission} = action.payload.vehicle; 
            const lookupItem = {
                health: true, 
                tr: transmission, 
                reg: reg, 
                id: id, 
                mk: make, 
                md: model, 
                fuel: fuel
            }
            state.vehicles.items = [lookupItem, ...state.vehicles.items];
        },
        resetVehicles: (state, action) => {
            state.items = [];
            state.maxPartition = 0;
            state.lastLoadedPartition = 0;
            state.length = 0
        },
        setSelectedVehicle: (state, action) => {
            state.selectedVehicle = action.payload.selectedVehicle;
        },
        setSelectedVehicleIssues: (state, action) => {
            state.selectedVehicleIssues = action.payload.issues;
        },
        setSelectedVehicleReminders: (state, action) => {
            state.selectedVehicleReminders = action.payload.reminders;
        },
        setRecordedActions: (state, action) => {
            state.recordedActions = action.payload.actions;
        },
        setVehicleAttachments: (state, action) => {
            state.selectedVehicleAttachments = action.payload.attachments;
        },
    }
})

export default vehicleSlice.reducer;
