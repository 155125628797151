import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
const initialState = {
    items: [],
    calenderItems: []
};


export const reminderSlice = createSlice({
    name: "reminders",
    initialState,
    reducers: {
        fetchReminders: (state, action) => {
            if (typeof action.payload.items === 'undefined') return;

            var reminderLookUpDocs = [...action.payload.items];
            reminderLookUpDocs.map(doc => (
                state.items = doc.items
            ))

            state.calenderItems= [];
            reminderLookUpDocs.map(doc => (
                doc.items.map(docItem => {
                    if (docItem.del === false) {
                        state.calenderItems.push({
                            title: `Reminder: ${docItem.t} (${docItem.reg}) is due`, 
                            start: format(docItem.exp.toDate(), "yyyy-MM-dd"),
                            id: `${docItem.vId};${docItem.id}`, 
                        })
                    }
                })
            ))
        }
    }
})


export default reminderSlice.reducer;