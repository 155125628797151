import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";
import InspectionTable from "./inspectionTable";

import CachedIcon from '@mui/icons-material/Cached';
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function InspectionsPage() {
  return (
    <React.Fragment>
      <Helmet title="Inspections" />

      <Typography variant="h3" gutterBottom display="inline">
        Inspections
      </Typography>

      <Grid container spacing={2} justifyContent='space-between' >
        <Grid item>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/dashboard">
              Maintanance
            </Link>
            <Typography>Inspections</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Grid container spacing={2} >
            <Grid item>
              <IconButton color="primary" aria-label="add to shopping cart">
                <CachedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <InspectionTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InspectionsPage;
