import { Button, Chip, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import React from "react"
import IssueSeverity from "../../common/issueSeverity"
import { format } from "date-fns";
import { Link } from "react-router-dom";
import IssueStatus from "../../common/issueStatus";
 

export default function IssueDetailSectionGrid({selectedIssue}) {
    const { vehicleId, details, severity, labels, reg, status, created, issue } = selectedIssue;
    return (
        <Grid container spacing={2} >
        <Grid item xs={12}>
        <Table size={"medium"}>
                    <TableBody>
                        <TableRow key='vehicle'>
                            <TableCell component="th" scope="row" width='20%'>
                                Issue
                            </TableCell>
                            <TableCell align="left" width='80%' sx={{ fontWeight: 500 }}>
                                <Typography variant="h7">{issue}</Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow key='vehicle1'>
                            <TableCell component="th" scope="row" width='20%'>
                                Details
                            </TableCell>
                            <TableCell align="left" height='100'
                                sx={{ fontWeight: 300 }}>
                                {details}
                            </TableCell>
                        </TableRow>

                        <TableRow key='vehicle2'>
                            <TableCell component="th" scope="row" width='20%'>
                                Created on
                            </TableCell>
                            <TableCell align="left" width='80%' sx={{ fontWeight: 500 }}>
                                {format(created, 'MM/dd/yyyy hh:mm')}
                            </TableCell>
                        </TableRow>

                        <TableRow key='vehicle3'>
                            <TableCell component="th" scope="row" width='20%'>
                                Vehicle
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                <Button component={Link} to={`/vehicles/${vehicleId}`}>
                                    {reg}
                                </Button>
                            </TableCell>
                        </TableRow>

                        <TableRow key='vehicle4'>
                            <TableCell component="th" scope="row" width='20%'>
                                Severity
                            </TableCell>
                            <TableCell align="left" width='80%' sx={{ fontWeight: 500 }}>
                                <IssueSeverity severity={severity} />
                            </TableCell>
                        </TableRow>

                        <TableRow key='vehicle5'>
                            <TableCell component="th" scope="row" width='20%'>
                                Status
                            </TableCell>
                            <TableCell align="left" width='80%' sx={{ fontWeight: 500 }}>
                                <IssueStatus status={status} />
                            </TableCell>
                        </TableRow>

                        <TableRow key='vehicle6'>
                            <TableCell component="th" scope="row" width='20%'>
                                Labels
                            </TableCell>
                            <TableCell align="left" width='80%' sx={{ fontWeight: 500 }}>
                                {typeof labels !== 'undefined' && labels.map(label => (
                                    <Chip label={label} key={label} />
                                ))}
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
        </Grid>
    </Grid>
    )
}