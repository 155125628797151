
import { Card, CardContent, Typography, Chip, Stack, Divider } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";

const TableWrapper = styled.div`
  height: 75px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


export default function WorkOrderLabels({ workorder }) {
    const {
        labels,
    } = workorder;


    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Labels
                </Typography>
                <TableWrapper>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        maxWidth={100}
                    >
                        {labels.map(label => (
                            <Chip label={label} key={label} />
                        ))}

                    </Stack>
                </TableWrapper>
            </CardContent>
        </Card>
    )
}