import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import WorkOrderReportSummary from "./workorder_report_summary";
import WorkOrderReportItems from "./workorder_report_items";
import ReportToolBar from "../reportToolBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeneralReportAsync } from "../../../redux/slices/report_wo";
import useAuth from "../../../hooks/useAuth";
import ReportLoading from "../reportLoading";
import Workorder_report_piechart from "./workorder_report_piechart";

export default function WorkOrderReports() {

  const { user } = useAuth();
  let clientId = user?.clientId;
  const dispatch = useDispatch();
  const { isLoading, filters, generalReport } = useSelector(state => state.reportWo);

  //eslint-disable-next-line
  const [predicate, setPredicate] = useState(
    new Map([
      ["currentMonth", filters.currentMonth]
    ])
  );

  function handleSetPredicate(filterValues) {
    let newMap = new Map(predicate);
    for (const filter in filterValues) {
      newMap.set(filter, filterValues[filter]);
    }
    setPredicate(newMap);
  }



  useEffect(() => {
    if (typeof clientId === 'undefined') return;

    dispatch(fetchGeneralReportAsync({ clientId: clientId, predicate: predicate }));
  }, [dispatch, clientId, predicate]);

  if (isLoading) return <ReportLoading />

  return (
    <React.Fragment>
      <Helmet title="Reports" />

      <Grid container spacing={2} >
        <Grid item xs={12}>
          <ReportToolBar title={'Work Orders Monthly Summary Report'} handleSetPredicate={handleSetPredicate} />
        </Grid>
        <Grid item xs={12} lg={5}>
          <WorkOrderReportSummary summary={generalReport?.summary} />
        </Grid>
        <Grid item xs={12} lg={7}>
          <Workorder_report_piechart summary={generalReport?.summary} />
        </Grid>
        <Grid item xs={12}>
          <WorkOrderReportItems items={generalReport?.rows} />
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

