
import { Button, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { CurrencyFormatCustom, NumberFormatCustom } from "../../../components/custom";
import UserLookUp from "../../common/userLookUp";

export default function AddLabourView({ addLabour, closeLabour }) {

    const initialValues = {
        selectedUser: null,
        hours: '',
        cost: '',
        amount: ''
    }

    const validationSchema = Yup.object().shape({
        selectedUser: Yup.object().shape({
            userId: Yup.string().required()
        }),
        hours: Yup.number()
            .positive('Must be > 0')
            .max(100, "Limit is 100")
            .required('Required'),
        cost: Yup.number()
            .positive('Must be > 0')
            .max(1000, "Limit is 1000")
            .required('Required'),
    })


    return (

        <Box padding={3}>
            <Typography variant="h6" >Add Labour</Typography>
            <Divider sx={{ pt: 3 }} />
            <Box sx={{ pt: 3 }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                        isValid,
                        setFieldValue
                    }) => (

                        <form noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    <UserLookUp
                                        clearOnEscape
                                        name='selectedUser'
                                        label='Labour'
                                        variant='standard'
                                        setFieldValue={setFieldValue}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField
                                        name="hours"
                                        label="Hours"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        variant="standard"
                                        required
                                        value={values.hours}
                                        error={Boolean(touched.hours && errors.hours)}
                                        fullWidth
                                        helperText={touched.hours && errors.hours}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    />

                                </Grid>
                                <Grid item xs={3} >
                                    <TextField
                                        name="cost"
                                        label="Per Hour"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputComponent: CurrencyFormatCustom,
                                        }}
                                        variant="standard"
                                        required
                                        value={values.cost}
                                        error={Boolean(touched.cost && errors.cost)}
                                        fullWidth
                                        helperText={touched.cost && errors.cost}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    />


                                </Grid>
                                <Grid item xs={12}  >
                                    <Grid container justifyContent="right" alignItems="center" >
                                        <Grid item >
                                            <Button type="button"
                                                disabled={isSubmitting || values.selectedUser === null || !isValid}
                                                onClick={() => {
                                                    let amount = values.hours * values.cost;
                                                    values.amount = amount.toFixed(2);
                                                    addLabour(values);
                                                    closeLabour();
                                                }}
                                                variant="contained" >Add</Button></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}