import { Chip, Stack } from "@mui/material";
import React from "react";
import { ignored, inprogress, newStatus, resolved } from "../../functions/constants";

export default function IssueStatus({ status }) {
    return (
        <Stack direction={{ xs: "row", sm: "row" }}>
            {status === newStatus && <Chip label='New' color="primary" variant="filled" size="small" sx={{ mr: 1 }} />}
            {status === inprogress && <Chip label='In WorkOrder' color='warning' size="small" variant="filled" sx={{ mr: 1 }} />}
            {status === resolved && <Chip label='Resolved' color='success' size="small" variant="filled" sx={{ mr: 1 }} />}
            {status === ignored && <Chip label='Rejected' color='error' size="small" variant="filled" sx={{ mr: 1 }} />}
        </Stack>
    )
}