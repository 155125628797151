import firebase from './config'
import { inspectionLookupPartitionSize } from '../functions/partitionSizes'
import { inspectionsCollection, vehicleCollection } from './collections';

const db = firebase.firestore();
 

export async function fetchInspectionsFromLookup(predicate, lastLoadedPartition) {
  const clientId = predicate.get("clientId");

  let result = { empty: true };

  if (lastLoadedPartition === 0) {
    return await db.collection(inspectionsCollection)
      .where('clientRef', '==', clientId)
      .orderBy('partitionIndex', "desc").limit(1).get().then((snaps) => {
        if (snaps.docs.length !== 0) {
          const { partitionIndex, items } = snaps.docs[0].data();
          result.maxPartition = partitionIndex;
          result.items = [...items];
          result.length = ((partitionIndex - 1) * inspectionLookupPartitionSize) + items.length;
          result.lastLoadedPartition = partitionIndex;
        }
        result.empty = false;
        return result;
      })
  } else {
    const nextPartition = lastLoadedPartition - 1;
    if (nextPartition >= 1) {
      return await db.collection(inspectionsCollection)
        .where('clientRef', '==', clientId)
        .where('partitionIndex', '==', nextPartition).limit(1).get().then(snaps => {
          if (snaps.docs.length !== 0) {
            const { partitionIndex, items } = snaps.docs[0].data();
            result.items = [...items];
            result.lastLoadedPartition = partitionIndex;
          }
          result.empty = false;
          return result;
        })
    } else {
      result.empty = false;
      result.items = [];
      result.lastLoadedPartition = 1;
      return result;
    }
  }
}


export function listenToInspectionDoc(vehicleId, inspectionId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection(inspectionsCollection).doc(inspectionId);
}
