import {
  Briefcase,
  CheckSquare,
  MessageSquare,
  HelpCircle,
  Navigation,
  Truck,
  Sliders,
  Settings
} from "react-feather";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
  },
  {
    href: "/vehicles",
    icon: Truck,
    title: "Vehicles",
  },
  {
    href: "/maintanance",
    icon: CheckSquare,
    title: "Maintanance",
    children: [
      {
        href: "/maintanance/issues",
        title: "Issues",
      },
      {
        href: "/maintanance/inspections",
        title: "Inspections",
      }

    ],
  },
  {
    href: "/service",
    icon: Settings,
    title: "Service",
    children: [
      {
        href: "/service/workorders",
        title: "Work Orders",
      },

      {
        href: "/service/reminders",
        title: "Reminders",
      },
      {
        href: "/service/tasks",
        title: "Service Tasks",
      }, {
        href: "/service/parts",
        title: "Parts",
      },
    ],
  },
  {
    href: "/reports",
    icon: Briefcase,
    title: "Reports",
  },
  {
    href: "/admin",
    icon: Settings,
    title: "Admin",
    children: [
      {
        href: "/admin/profile",
        title: "Profile",
      },
      {
        href: "/admin/account",
        title: "Account",
      }
    ],
  },
  {
    href: "/help",
    icon: HelpCircle,
    title: "Help",
    children: [
      {
        href: "/help/contact",
        title: "Support",
      }
    ],
  },
];


const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  }
];

export default navItems;
