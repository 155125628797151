import { Avatar, Box, Chip, Grid, Paper, Stack, Typography } from "@mui/material";
export default function AvatarChip({ name, srcObj }) {

    return (
        <Grid container justify="flex-end" spacing={1}>
            <Grid item>
                <Avatar alt={name} src={srcObj?.avatar} sx={{ width: 24, height: 24 }} />
            </Grid>
            <Grid item>
               <Typography mt={0.5}> {name}</Typography>
            </Grid>
        </Grid>
    )

}