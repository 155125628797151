import React from "react";
import styled from "styled-components/macro";

import {
    Avatar as MuiAvatar,
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    Chip,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Spacer = styled.div(spacing);
const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;


export default function ContactDetails() {
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Contact
                </Typography>

                <Spacer mb={4} />
                <Centered>
                    <Avatar alt={'Follis Solutions LTD'}  src="/static/img/avatars/follis.png" />
                    <Typography variant="body2" component="div" gutterBottom>
                        <Box fontWeight="fontWeightMedium">Follis Solutions Pvt Ltd</Box>
                    </Typography>

                    <Table size={"medium"}>
                        <TableBody>

                            <TableRow key='add'>
                                <TableCell component="th" scope="row">
                                    Address
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    Ground Floor, 71 Baggot Street, Dublin 2
                                </TableCell>
                            </TableRow>

                            <TableRow key='email'>
                                <TableCell component="th" scope="row">
                                    Email
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    help@follis.ie
                                </TableCell>
                            </TableRow>

                            <TableRow key='phone'>
                                <TableCell component="th" scope="row">
                                    Phone
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    +353 894620645
                                </TableCell>
                            </TableRow>

                            <TableRow key='country'>
                                <TableCell component="th" scope="row">
                                    Country
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    Ireland
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </Centered>



            </CardContent>
        </Card>
    );
}

