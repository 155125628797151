import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Autocomplete,
    Box,
    Breadcrumbs as MuiBreadcrumbs,
    Button,
    Card,
    CardContent,
    Divider as MuiDivider,
    Grid,
    Link,
    TextField,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import VehicleLookUp from "../../common/vehicleLookUp";
import LabelLookUp from "../../common/labelsLookUp";
import useAuth from "../../../hooks/useAuth";
import { isValid } from "date-fns";
import { createNewIssue } from '../../../functions/issue';
import { useDispatch } from "react-redux";
import { issuesSlice } from "../../../redux/slices/issues";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const severityOptions = ['Minor', 'Major', 'Critical'];

const initialValues = {
    vehicle: null,
    issue: '',
    details: '',
    severity: '',
    labels: []
}

function NewIssue() {

    const { user } = useAuth();
    let clientId = user?.clientId;

    const navigate = useNavigate();
    const dispatch = useDispatch(); 


    return (
        <React.Fragment>
            <Helmet title="Work Order" />
            <Typography variant="h3" gutterBottom display="inline">
                New Issue
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Link component={NavLink} to="/maintanance/issues">
                    Issues
                </Link>
                <Typography>New</Typography>

            </Breadcrumbs>

            <Divider my={6} />

            <Grid container spacing={2}
                alignItems="center"
                justifyContent="center"
            >
                <Grid item xs={12} lg={10}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                const outcome = await createNewIssue(values, user.id, clientId);
                                setSubmitting(false);

                                setStatus({ success: !(outcome === 'error') });

                                if (outcome !== 'error') {
                                    dispatch(issuesSlice.actions.addIssueItem(
                                        {
                                            issue: outcome, 
                                            userName: user?.displayName
                                        }
                                    )); 
                                    navigate('/maintanance/issues')
                                }
                            } catch (error) {
                                setStatus({ success: false });
                                setSubmitting(false);
                                console.log(error)
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            status
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                {errors.submit && (
                                    <Alert mt={2} mb={1} severity="warning">
                                        {errors.submit}
                                    </Alert>
                                )}

                                {status && status === 'success' && (
                                    <Alert severity="success" my={3}>
                                        Issue saved successfully!
                                    </Alert>
                                )}

                                <Card>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Issue Details
                                        </Typography>
                                        <Grid container spacing={2} mt={2} >
                                            <Grid item xs={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <VehicleLookUp
                                                            name='vehicle'
                                                            disablePortal
                                                            setFieldValue={setFieldValue}
                                                            error={Boolean(touched.vehicle && errors.vehicle)}
                                                            
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} >
                                                <Typography sx={{ marginTop: 5 }}>Please fill the issue details below</Typography>
                                            </Grid>

                                            <Grid item xs={6} >
                                                <Autocomplete
                                                    name='severity'
                                                    onChange={(event, value) => {
                                                        setFieldValue('severity', value);
                                                    }}
                                                    options={severityOptions}
                                                    getOptionLabel={(option) => option}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Severity"
                                                        error={Boolean(touched.severity && errors.severity)}
                                                        helperText={errors.severity}
                                                    />}
                                                > </Autocomplete>
                                            </Grid>

                                            <Grid item xs={12} >
                                                <TextField
                                                    type="text"
                                                    name="issue"
                                                    label="Issue Description"
                                                    value={values.issue}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    my={3}
                                                />
                                            </Grid>

                                            <Grid item xs={12} >
                                                <TextField
                                                    type="text"
                                                    name="details"
                                                    label="Details"
                                                    multiline
                                                    rows={4}
                                                    value={values.details}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    my={3}
                                                />
                                            </Grid>

                                            <Grid item xs={12} >
                                                <Typography sx={{ marginTop: 5 }}>You may optionally assign labels to this issue</Typography>
                                            </Grid>

                                            <Grid item xs={12} >
                                                <LabelLookUp
                                                    name='labels'
                                                    setFieldValue={setFieldValue}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justifyContent='right' alignItems='right'>
                                                    <Grid item>
                                                        <Button variant='outlined'  >Cancel</Button>
                                                    </Grid>

                                                    <Grid item>
                                                        <LoadingButton
                                                            type="submit"
                                                            variant='contained'
                                                            loading={isSubmitting}
                                                            disabled={!isValid}
                                                        >
                                                            Save Issue</LoadingButton>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

export default NewIssue;
