import React from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Grid,
    Link,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";

import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import IssueDetailGeneral from "./details/issueDetailGeneral";
import IssueDetailCommentList from "./details/issueDetailCommentList";
import IssueDetailToolBar from "./details/issueDetailToolBar";
import useFireStoreDoc from "../../hooks/useFireStoreDoc";
import { listenToIssue } from "../../fireStore/vehiclesCollection";
import { issuesSlice } from "../../redux/slices/issues";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function IssuesDetails() {
    const dispatch = useDispatch();
    const { vid, iid } = useParams();
    const { selectedIssue } = useSelector(state => state.issue)



    useFireStoreDoc({
        shouldExecute: !!iid && !!vid,
        query: () => listenToIssue(iid, vid),
        data: (issue) => {
            dispatch(issuesSlice.actions.setSelectedIssue({ issue: issue }))
            dispatch(issuesSlice.actions.updateIssueLookUp({ issue: issue }))
        },
        deps: [iid, vid, dispatch],
        refFields: [],
    });

    if(selectedIssue === null) return <></>

    return (
        <React.Fragment>
            <Helmet title="Issue" />
            <Typography variant="h3" gutterBottom display="inline">
                Issue #{selectedIssue?.issueId}
            </Typography>

            <Grid container spacing={2} justifyContent='space-between' >
                <Grid item>
                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to="/dashboard">
                            Dashboard
                        </Link>
                        <Link component={NavLink} to="/maintanance/issues">
                            Issues
                        </Link>
                        <Typography>Issue Details</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <IssueDetailToolBar issueId={iid} vehicleId={vid} selectedIssue={selectedIssue} />
                </Grid>
            </Grid>

            <Divider my={6} />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6} xl={8}>
                    <IssueDetailGeneral selectedIssue={selectedIssue} />
                </Grid>

                <Grid item xs={12} lg={6} xl={4}>
                    <IssueDetailCommentList  selectedIssue={selectedIssue}/>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}

export default IssuesDetails;
