import React from "react";
import styled from "styled-components/macro";


import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Typography,
} from "@mui/material";
import { StarBorder as StarIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;

const Wrapper = styled.div`
  overflow-x: hidden;
`;

function PricingPage() {
  return (
    <Wrapper pt={16} pb={20} id="pricing">


      <Header>
        <Typography variant="h3" gutterBottom align="center">
          We have a plan for everyone
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center">
          Whether you're a business or an individual, <b>1 month trial and free software onboarding support from FleOps team</b>. <br />
          You will be charged only after the trial period.
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Grid container spacing={6} alignItems="flex-end">
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardHeader
                  title="Basic"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Price>
                    <Typography
                      component="h2"
                      variant="h1"
                      color="textPrimary"
                      align="center"
                      display="inline"
                    >
                      €24.99
                    </Typography>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      align="center"
                      display="inline"
                    >
                      /mo
                    </Typography>
                  </Price>
                  <Typography variant="subtitle1" align="center">
                    Upto 10 vehicles
                    <br />
                    1 Fleet Manager Account
                    <br />
                    Upto 15 User Accounts
                    <br />
                    Email support
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant="outlined" color="primary">
                    BEGIN WITH BASIC
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardHeader
                  title="Standard"
                  subheader="Most popular"
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={<StarIcon />}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Price>
                    <Typography
                      component="h2"
                      variant="h1"
                      color="textPrimary"
                      align="center"
                      display="inline"
                    >
                      €79.99
                    </Typography>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      align="center"
                      display="inline"
                    >
                      /mo
                    </Typography>
                  </Price>
                  <Typography variant="subtitle1" align="center">
                    Up to 100 Vehicles
                    <br />
                    Job Management
                    <br />
                    Document Management
                    <br />
                    2 Fleet Manager Account
                    <br />
                    Upto 120 User Accounts
                    <br />
                    Email support
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant="contained" color="primary">
                    BEGIN WITH STANDARD
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardHeader
                  title="Enterprise"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Price>
                    <Typography
                      component="h2"
                      variant="h1"
                      color="textPrimary"
                      align="center"
                      display="inline"
                    >
                      €120.99
                    </Typography>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      align="center"
                      display="inline"
                    >
                      /mo
                    </Typography>
                  </Price>
                  <Typography variant="subtitle1" align="center">
                    100+ Vehicles
                    <br />
                    Job Management
                    <br />
                    Document Management
                    <br />
                    Unlimited Fleet Manager Accounts
                    <br />
                    Unlimited User Accounts
                    <br />
                    Email And Telephonic support
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant="outlined" color="primary">
                    BEGIN WITH ENTERPRISE 
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default PricingPage;
