import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import styled from "styled-components/macro";
import {
  Alert as MuiAlert,
  Box,
  // Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  // Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import firebase from "../../../fireStore/config";
import useAuth from "../../../hooks/useAuth";
import { vehicleSlice } from "../../../redux/slices/vehicles";
import { useDispatch } from "react-redux";

const CloudFunctions = firebase.app().functions('europe-west2');
const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const initialValues = {
  reg: "",
};

const validationSchema = Yup.object().shape({});

export default function CreateVehicleForm() {

  const [vehicle, setVehicle] = useState(null);
  const { user } = useAuth();
  const errorMessage = 'Failed to find the vehicle, please check the vehicle registration is valid. You may also contact support.';
  const alreadyExists = 'Vehicle with same registration number already exists for this client.';
  const dispatch = useDispatch();

  const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    const createVehicle = CloudFunctions.httpsCallable('identifyAndCreateVehicle');
    const data = {
      vehicleReg: values.reg,
      countryCode: 'UKI',
      clientId: user.clientId,
      userId: user.id
    }

    await createVehicle(data).then(result => {
      const newVehicle = result.data;

      if (newVehicle === 'Error') {
        setErrors({ submit: errorMessage });
        setStatus({ sent: false });
      } else if (newVehicle === 'Already Exists') {
        setErrors({ submit: alreadyExists });
        setStatus({ sent: false });
      } else {
        setVehicle(newVehicle);
        setStatus({ sent: true });
        dispatch(vehicleSlice.actions.addVehicle({ vehicle: newVehicle }))
      }

      setSubmitting(false);
    }).catch((err) => {
      setStatus({ sent: false });
      setSubmitting(false);
    })
  };

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              resetForm
            }) => (
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Create Vehicle
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Please enter the registration number of the vehicle to create new using the API (Only UK and Ireland registration numbers).
                  </Typography>

                  {status && status.sent && (
                    <Alert severity="success" my={3}>
                      Vehicle {vehicle?.reg} saved successfully!
                      <br />
                      <br />
                      <Typography variant="h7" >{`Details : ${vehicle.make} ${vehicle.model} ${vehicle.year_of_manufacture}`}</Typography>
                    </Alert>
                  )}


                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}

                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={6}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={6}>
                        <Grid item md={6}>
                          <TextField
                            name="reg"
                            label="Registration Number"
                            value={values.firstName}
                            error={Boolean(touched.firstName && errors.firstName)}
                            fullWidth
                            helperText="Powered by MototCheck Api"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Grid container spacing={3}>
                            <Grid item>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                mt={3}
                                disabled={isSubmitting || values.reg === ''}
                              >
                                Create Vehicle using API
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                mt={3}
                                disabled={vehicle === null}
                                onClick={() => {
                                  resetForm();
                                  setVehicle(null);
                                }}
                              >
                                Add Next
                              </Button>
                            </Grid>

                          </Grid>

                        </Grid>
                      </Grid>
                    </form>
                  )}
                </CardContent>
              </Card>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
