import React, { useEffect, useState } from "react";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from "../../../hooks/useAuth";
import { deleteIssueComment } from "../../../functions/issue";
import { LoadingButton } from "@mui/lab";
import formatUsingTimeAgo from "../../../utils/timeAgoUtil";



export default function IssueCommentItem({ comment }) {
    const { user } = useAuth();
    const [saving, setSaving] = useState(false);

    const handleDeleteComment = async () => {
        setSaving(true);
        await deleteIssueComment(comment.id, comment.vId, comment.iid);
        setSaving(false);
    }

    return (
        <ListItem alignItems="flex-start"
            secondaryAction={
                <LoadingButton edge="end" aria-label="delete"
                    disabled={comment.user.userId !== user.id}
                    loading={saving || comment?.dummy}
                    onClick={handleDeleteComment}>
                    <DeleteIcon />
                </LoadingButton>
            }
        >
            <ListItemAvatar>
                <Avatar>{comment.user.avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={comment.comment}
                secondary={
                    <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                        >
                            By {comment.user.userName}

                        </Typography>
                        on {formatUsingTimeAgo(comment.created.toDate())}
                    </React.Fragment>
                }

            />
        </ListItem>
    )
}