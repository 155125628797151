import React, { useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    IconButton,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import {
    MoreVert,

} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { VehicleReminderTableHead } from "./vehicleReminderTableHead";
import { useDispatch, useSelector } from "react-redux";
import useFireStoreCollection from "../../../../hooks/useFireStoreCollection";
import { listenToVehicleReminders } from "../../../../fireStore/vehiclesCollection";
import { vehicleSlice } from "../../../../redux/slices/vehicles";
import { differenceInDays, format } from "date-fns";
import DeleteIcon from '@mui/icons-material/Delete';
import { VehicleReminderTableToolBar } from "./vehicleReminderTableToolBar";
import { LoadingButton } from "@mui/lab";
import { removeVehicleReminder } from "../../../../functions/reminder";



const TableWrapper = styled.div`
  height: 450px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
        el,
        index,
    }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
}

const Paper = styled(MuiPaper)(spacing);


export default function VehicleReminderTable({ vehicle }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("customer");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [saving, setSaving] = useState(false);

    const dispatch = useDispatch();
    const { selectedVehicleReminders } = useSelector(x => x.vehicles);

    useFireStoreCollection({
        query: () => listenToVehicleReminders(vehicle.id),
        data: (reminders) => dispatch(vehicleSlice.actions.setSelectedVehicleReminders({ reminders: reminders })),
        deps: [dispatch, vehicle],
        refFields: [],
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = selectedVehicleReminders.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleDeleteReminder = async (vehicleId, reminderId) => {
        setSaving(true);
        await removeVehicleReminder(vehicleId, reminderId);
        setSaving(false);
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, selectedVehicleReminders.length - page * rowsPerPage);

    const differenceToNow = (date) => {
        return differenceInDays(date, new Date())
    }

    return (

        <Card>
            <CardHeader
                action={
                    <VehicleReminderTableToolBar vehicle={vehicle} />
                }
                title="Reminders"
            />

            <CardContent>
                <TableWrapper>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size={"small"}
                            aria-label="enhanced table"
                        >
                            <VehicleReminderTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={selectedVehicleReminders.length}
                            />
                            <TableBody>
                                {stableSort(selectedVehicleReminders, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={`${row.id}-${index}`}
                                                selected={isItemSelected}
                                            >

                                                <TableCell align="left"  >
                                                    {row.title}
                                                </TableCell>
                                                <TableCell align="right">{format(row.expDate, 'MM/dd/yyyy')}</TableCell>
                                                <TableCell  align="right">
                                                    {differenceToNow(row.expDate) < 20 && <Chip label={`${differenceToNow(row.expDate)} days`} color={'error'} />}
                                                    {differenceToNow(row.expDate) > 20 && <Chip label={`${differenceToNow(row.expDate)} days`} color={'warning'} />}
                                                </TableCell>
                                                <TableCell align="right">{row.remindMeBeforeXdays} days</TableCell>
                                                <TableCell padding="none" align="right">
                                                    <Box mr={2}>
                                                        <LoadingButton aria-label="share" size="large"
                                                            onClick={() => handleDeleteReminder(vehicle.id, row.id)}
                                                            loading={saving}
                                                        >
                                                            <DeleteIcon />
                                                        </LoadingButton>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableWrapper>
            </CardContent>
        </Card>

    );
}