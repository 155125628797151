import { FormControl, Grid, TableCell, MenuItem, Select, Stack, Table, TableBody, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumberFormatCustom } from "../../../components/custom";
import { currencySymbol } from "../../../fireStore/collections";
import { workorderSlice } from "../../../redux/slices/workorder";
import CountryCurrency from "../../common/countryCurrency";

export default function WorkItemFooter() {
    const [discount, setDiscount] = useState({ type: 'percent', value: 0 })
    const [tax, setTax] = useState({ type: 'percent', value: 0 })
    const { lineItems, summary, comment } = useSelector(state => state.workorder);

    const dispatch = useDispatch();

    useEffect(() => {
        if (lineItems.length === 0 || (lineItems.length === 1 && lineItems.subtasks === null)
            || (lineItems.length === 1 && lineItems.subtasks?.length === 0)) {
            return;
        }

        dispatch(workorderSlice.actions.recalculateWorkOrder({ discount: discount, tax: tax }));
    }, [lineItems, discount, tax])


    const handleDiscountTypeChange = (e) => {
        setDiscount(state => {
            return {
                ...state,
                type: e.target.value
            }
        })
    }

    const handleTaxTypeChange = (e) => {
        setTax(state => {
            return {
                ...state,
                type: e.target.value
            }
        })
    }

    const handleDiscountValueChange = (e) => {
        setDiscount(state => {
            return {
                ...state,
                value: e.target.value
            }
        })
    }

    const handleTaxValueChange = (e) => {
        setTax(state => {
            return {
                ...state,
                value: e.target.value
            }
        })
    }

    const handleCommentChange = (e) => {
        dispatch(workorderSlice.actions.setComment({ comment: e.target.value }));
    }

    return (

        <Grid container justifyContent='space-between' mt={3} >
            <Grid item xs={6} lg={6}>
                <TextField
                    id="filled-multiline-static"
                    label="Comments"
                    multiline
                    rows={6}
                    defaultValue={comment}
                    onChange={handleCommentChange}
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <Table >
                    <TableBody>
                        <TableRow>
                            <TableCell key='labour' align='right' width='70%'>Labour</TableCell>
                            <TableCell key='labour1' align='left' width='30%'><CountryCurrency value={summary.subTotalLabour} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key='labour12' align='right' width='70%'>Parts</TableCell>
                            <TableCell key='labour3' align='left' width='30%'><CountryCurrency value={summary.subTotalParts} />  </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key='labour4' align='right' width='70%'>Subtotal</TableCell>
                            <TableCell key='labour5' align='left' width='30%'><CountryCurrency value={summary.subTotalAmount} />   </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell key='labour6' align='right' width='70%'>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="baseline"
                                    spacing={2}
                                >
                                    <Typography>Discount</Typography>
                                    <Select
                                        defaultValue={'percent'}
                                        label=""
                                        autoWidth
                                        size="small"
                                        onChange={handleDiscountTypeChange}

                                    >
                                        <MenuItem value={'percent'}>%</MenuItem>
                                        <MenuItem value={'money'}>€</MenuItem>
                                    </Select>

                                    <FormControl sx={{ width: '20' }}>
                                        <TextField
                                            name='discountvalue'
                                            size="small"
                                            label={discount.type === 'percent' ? 'Percentage' : 'Amount'}
                                            style={{ width: 100 }}
                                            onChange={handleDiscountValueChange}
                                            value={discount.value}

                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                            </TableCell>
                            <TableCell key='222' align='left' width='30%'>
                                <CountryCurrency value={summary.discountAmt} /> 
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell key='labour6' align='right' width='70%'>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="baseline"
                                    spacing={2}
                                >
                                    <Typography>Tax</Typography>
                                    <Select
                                        defaultValue={'percent'}
                                        autoWidth
                                        size="small"
                                        onChange={handleTaxTypeChange}
                                    >
                                        <MenuItem value={'percent'}>%</MenuItem>
                                        <MenuItem value={'money'}>€</MenuItem>

                                    </Select>

                                    <FormControl sx={{ width: '20' }}>
                                        <TextField
                                            name='taxvalue'
                                            size="small"
                                            label={tax.type === 'percent' ? 'Percentage' : 'Amount'}
                                            onChange={handleTaxValueChange}
                                            style={{ width: 100 }}
                                            value={tax.value}

                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                            </TableCell>
                            <TableCell key='222' align='left' width='30%'>
                                <CountryCurrency />{summary.taxAmt}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell key='labour4' align='right' width='70%'>Grand Total</TableCell>
                            <TableCell key='labour5' align='left' width='30%'><CountryCurrency value={summary.grandTotal} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid >
    )
}