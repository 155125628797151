import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ClientsTable from './clientsTable'

import {
    Breadcrumbs as MuiBreadcrumbs,
    Button,
    Divider as MuiDivider,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import {
    Add as AddIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientsAsync } from "../../redux/slices/clients";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ClientsPage() {


    const limit = 20;
    const { clients } = useSelector((state) => state.clients);
    const dispatch = useDispatch();

    //eslint-disable-next-line
    const [predicate, setPredicate] = useState(
        new Map([
            ["client", "62pIS9qAvlxoueLCdGSf"]
        ])
    );



    useEffect(() => {
        dispatch(fetchClientsAsync({ limit: limit, predicate: predicate, lastDocSnapshot: null }));
    }, [dispatch, predicate, limit]);


    return (
        <React.Fragment>
            <Helmet title="Clients" />

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Clients
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to="/dashboard">
                            Dashboard
                        </Link>
                        <Typography>Clients</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" component={NavLink} to='create'  >
                            <AddIcon />
                            Create New
                        </Button>
                    </div>
                </Grid>
            </Grid>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <ClientsTable clients={clients} />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ClientsPage;