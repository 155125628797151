import React from "react";

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";


const headCellsWithSelection = [
  { id: "selection", alignment: "left", label: "", width: '5%' },
  { id: "issueId", alignment: "left", label: "Id", width: '5%' },
  { id: "title", alignment: "left", label: "Title", width: '40%' },
  { id: "status", alignment: "left", label: "Status", width: '15%' },
  { id: "createdBy", alignment: "left", label: "Created By", width: '15%' },
  { id: "created", alignment: "left", label: "Date", width: '15%' },
];

const headCells = [
  { id: "issueId", alignment: "left", label: "Id", width: '5%' },
  { id: "title", alignment: "left", label: "Title", width: '45%' },
  { id: "status", alignment: "left", label: "Status", width: '15%' },
  { id: "createdBy", alignment: "left", label: "Created By", width: '15%' },
  { id: "created", alignment: "left", label: "Date", width: '20%' },
];


export const WorkOrderIssueTableHeader = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    readonly
  } = props;

  const createSortHandler = (property) => (event) => {
    if (!readonly)
      onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {readonly && headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}

        {!readonly && headCellsWithSelection.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
