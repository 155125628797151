import { Autocomplete, Box, Button, Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { FormikProvider } from 'formik';
import React from 'react';

import VehicleLookUp from '../../common/vehicleLookUp';
import ContactLookUp from '../../common/contactsLookup';
import UserLookUp from '../../common/userLookUp';
import { DesktopDatePicker } from '@mui/lab';
import LabelLookUp from '../../common/labelsLookUp';
import { useSelector } from 'react-redux';

export default function WorkOrderGeneral({ formik, handleVehicleSelected, handleGeneralDetailsChange }) {

    const statusOptions = ['Open', 'Completed'];
    const { setFieldValue } = formik;
    const { selectedVehicle } = useSelector(state => state.workorder);

    const handleVendorSelect = (value) => {
       if(value === null) {
        setFieldValue('gn.invoiceNumber', '');
        setFieldValue('gn.poNumber', '');
       }
    }
    return (
        <FormikProvider value={formik}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Details
                    </Typography>
                    <Grid container spacing={2} mt={2} >
                        <Grid item xs={12} >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <VehicleLookUp
                                        name='gn.selectedVehicle'
                                        disablePortal
                                        handleSelect={handleVehicleSelected}
                                        setFieldValue={setFieldValue}
                                        error={Boolean(formik.touched.gn?.selectedVehicle && formik.errors.gn?.selectedVehicle)}
                                        helperText={typeof formik.errors.gn?.selectedVehicle !== 'undefined' ? 'Please select vehicle' : ''}
                                        value={selectedVehicle}
                                        onBlur={formik.handleBlur}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} >
                            <Typography sx={{ marginTop: 5 }}>Please fill the order details below</Typography>
                        </Grid>

                        <Grid item xs={6} >
                            <Autocomplete
                                name='gn.status'
                                onChange={(event, value) => {
                                    setFieldValue('gn.status', value);
                                    if (value !== 'Completed') {
                                        setFieldValue('gn.completionDate', null);
                                    }
                                }}
                                value={formik.values.gn.status}
                                options={statusOptions}
                                getOptionLabel={(option) => option}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option}
                                    </Box>
                                )}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Status"
                                    error={Boolean(formik.touched.gn?.status && formik.errors.gn?.status)}
                                    helperText={formik.errors.gn?.status !== 'undefined' ? 'Please select status' : ''}
                                />}
                            > </Autocomplete>
                        </Grid>

                        <Grid item xs={6} >
                            <UserLookUp
                                clearOnEscape
                                name='gn.assignedTo'
                                label='Assigned To'
                                setFieldValue={setFieldValue}
                                variant='outlined'
                                error={Boolean(formik.touched.gn?.assignedTo && formik.errors.gn?.assignedTo)}
                                helperText={typeof formik.errors.gn?.assignedTo !== 'undefined' ? 'Please select assigned to' : ''}
                            />
                        </Grid>

                        <Grid item xs={6} >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <UserLookUp
                                        clearOnEscape
                                        name='gn.issuedBy'
                                        label='Issued By'
                                        setFieldValue={setFieldValue}
                                        variant='outlined'
                                        error={Boolean(formik.touched.gn?.issuedBy && formik.errors.gn?.issuedBy)}
                                        helperText={typeof formik.errors.gn?.issuedBy !== 'undefined' ? 'Please select Issued By' : ''}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        name='gn.issuedDate'
                                        label="Issue Date"
                                        inputFormat="dd/MM/yyyy"
                                        value={formik.values.gn?.issuedDate}
                                        onChange={(value) => {
                                            setFieldValue('gn.issuedDate', value)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth
                                            error={Boolean(formik.touched.gn?.issuedDate && formik.errors.gn?.issuedDate)}
                                            helperText={typeof formik.errors.gn?.issuedDate !== 'undefined' ? 'Please select issue date' : ''}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        name='gn.startDate'
                                        label="Start Date"
                                        inputFormat="dd/MM/yyyy"
                                        value={formik.values.gn?.startDate}
                                        onChange={(value) => {
                                            setFieldValue('gn.startDate', value)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth
                                            error={Boolean(formik.touched.gn?.startDate && formik.errors.gn?.startDate)}
                                            helperText={typeof formik.errors.gn?.startDate !== 'undefined' ? 'Please select start date' : ''}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DesktopDatePicker
                                        name='gn.completionDate'
                                        label="Completed Date"
                                        inputFormat="dd/MM/yyyy"
                                        value={formik.values.gn?.completionDate}
                                        onChange={(value) => {
                                            setFieldValue('gn.completionDate', value)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        disabled={formik.values.gn.status !== 'Completed'}
                                    />

                                </Grid>
                            </Grid>


                        </Grid>

                        <Grid item xs={12} >
                            <Typography sx={{ marginTop: 5 }}>
                                Please fill vendor details if any part of the work is outsourced 
                            </Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ContactLookUp
                                        name='gn.vendor'
                                        clearOnEscape
                                        setFieldValue={setFieldValue}
                                        label='Vendor'
                                        contactType='Vendor'
                                        handleSelect={handleVendorSelect}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} >
                            <TextField
                                type="text"
                                name="gn.invoiceNumber"
                                label="Invoice Number"
                                value={formik.values.gn.invoiceNumber}
                                fullWidth
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                my={3}
                                disabled={formik.values.gn.vendor === null}
                            />
                        </Grid>

                        <Grid item xs={6} >
                            <TextField
                                type="text"
                                name="gn.poNumber"
                                label="PO Number"
                                value={formik.values.gn.poNumber}
                                fullWidth
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                my={3}
                                disabled={formik.values.gn.vendor === null}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Typography sx={{ marginTop: 5 }}>Please select or create any custom labels that would help with reports eg: Accident, External etc. </Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <LabelLookUp
                                name='gn.labels'
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </FormikProvider>

    )
}