
import { Card, CardContent, Typography, Table, TableBody, TableRow, TableCell, Chip, IconButton, Button, Link, Grid, Avatar, Box } from "@mui/material";
import React from "react";
import AvatarChip from "../../common/avatarChip";
import styled from "styled-components/macro";

const TableWrapper = styled.div`
  height: 170px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


export default function WorkOrderVendorView({ workorder }) {
    const {
        vendor,
        invoiceNumber,
        poNumber,
        labels,
    } = workorder;


    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Vendor
                </Typography>

                <TableWrapper>
                    <Table size={"medium"}>
                        <TableBody>
                            <TableRow key='vendor'>
                                <TableCell component="th" scope="row">
                                    Vendor
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    <AvatarChip name={vendor.t} />
                                </TableCell>
                            </TableRow>

                            <TableRow key='invoicenumber'>
                                <TableCell component="th" scope="row">
                                    Invoice Number
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {invoiceNumber}
                                </TableCell>
                            </TableRow>

                            <TableRow key='ponumber'>
                                <TableCell component="th" scope="row">
                                    PO Number
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {poNumber}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableWrapper>
            </CardContent>
        </Card>
    )
}