import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    subscription: null, 
    paymentMethod: null  
};


export const signupSlice = createSlice({
    name: "signup",
    initialState,
    reducers: {
        setSubscription: (state, action) => {
            state.subscription = action.payload.subscription; 
        }, 
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload.paymentMethod; 
        },
        clearPaymentMethod: (state) => {
            state.paymentMethod = null
        },
    }
})


export default signupSlice.reducer;