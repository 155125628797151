import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import { orange, green, red, yellow } from "@mui/material/colors";
import {
    Card as MuiCard,
    CardContent,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow as MuiTableRow,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";



const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 275px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;


const Yellow = styled.span`
  color: ${() => yellow[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const VehicleIssueChart = ({ theme, vehicle }) => {
    const { healthStatistics } = vehicle;
    const data = {
        labels: ["New", "In Work Order", "Resolved"],
        datasets: [
            {
                data: [healthStatistics.issues.new, healthStatistics.issues.inprogress, healthStatistics.issues.resolved],
                backgroundColor: [
                    theme.palette.secondary.main,
                    red[500],
                    orange[500],
                    theme.palette.grey[200],
                ],
                borderWidth: 5,
                borderColor: theme.palette.background.paper,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: "80%",
    };

    return (
        <Card>
            <CardHeader
                action={
                    <IconButton aria-label="settings" size="large">
                        <MoreVertical />
                    </IconButton>
                }
                title="Issues"
            />

            <CardContent>
                <ChartWrapper>
                    <DoughnutInner>
                        <Typography variant="h4">{healthStatistics.activeIssues}</Typography>
                        <Typography variant="caption">Active Issue(s)</Typography>
                    </DoughnutInner>
                    <Chart type="doughnut" data={data} options={options} />

                
                </ChartWrapper>

                <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell align="right">Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    New
                                </TableCell>
                                <TableCell align="right">
                                    <RedText>{healthStatistics.issues.new}</RedText>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    In Work Order
                                </TableCell>
                                <TableCell align="right">
                                    <Yellow>{healthStatistics.issues.inprogress}</Yellow>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Resolved
                                </TableCell>
                                <TableCell align="right">
                                    <GreenText>{healthStatistics.issues.resolved}</GreenText></TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
            </CardContent>
        </Card>
    );
};

export default withTheme(VehicleIssueChart);
