import { Alert, Button, Divider, FormControl, Grid, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { Field, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";

import StripeInput from "../../components/StripeInput";
import useAuth from "../../hooks/useAuth";
import SelectForFormik from "../../components/selectForFormik";
import * as Yup from "yup";
import { updatePaymentMethod } from "../../functions/subscription";


export default function UpdatePaymentMethodForm({ handleCancel, profile }) {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false); 

    const { user } = useAuth();
    const { plans } = useSelector((state) => state.plans);

    const availableCountries = [{ "code": "IE", "label": "Ireland", "phone": "353" }, { "code": "GB", "label": "United Kingdom", "phone": "44" }]

    const initialValues = {
        nameOnCard: '',
        ccnumber: '',
        ccexp: '',
        cvv: '',
        billingAddress: "",
        billingCity: "",
        billingCountry: "",
        billingZipCode: "",
    }

    const validationSchema = Yup.object().shape({
        nameOnCard: Yup.string().max(20).required("Name on the card is required"),
        billingAddress: Yup.string().max(30).required("Invalid Address or length more than 30"),
        billingCountry: Yup.string().max(30).required("Please select country"),
    })



    const createaAndUpdatePaymentMethod = async (pmObj) => {

        if (!stripe || !elements) {
            return 'Stripe is not connected. please contact support team';
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod(pmObj);
        if (error) {
            return error.message;
        }

        if (paymentMethod) {
            return await updatePaymentMethod(paymentMethod.id, user?.id);
        } else {
            return 'error';
        }
    }

    const stripeDataObjectConverter = ({ nameonCard, address, city, country_code, postal_code }, cardElement) => ({
        payment_method: {
            type: 'card',
            card: cardElement,
            billing_details: {
                address: {
                    city,
                    country: country_code,
                    line1: address,
                    postal_code,
                    state: null
                },
                name: nameonCard,
                email: user?.email,
                phone: null
            },
        },
    })


    return (
        <>
            <Grid container justifyContent='space-between'>
                <Grid item>
                    <Typography variant="h6" >
                    Update Payment Method
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="text" onClick={handleCancel}>Cancel</Button>
                </Grid>
            </Grid>

 
            <Grid >

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                        try {
                            const cardElement = elements.getElement(CardCvcElement);
                            const stripePmValues = stripeDataObjectConverter({
                                nameonCard: values.nameOnCard,
                                address: values.billingAddress, city: values.billingCity, country_code: values.billingCountry, postal_code: values.billingZipCode
                            }, cardElement);

                            let outcome = await createaAndUpdatePaymentMethod(stripePmValues.payment_method);
               
                            if (outcome.data === 'success') {
                                setSuccess(true);
                                resetForm();
                                handleCancel();
                            } else {
                                setErrors({ submit: outcome })
                                setSuccess(false);
                            }

                            setSubmitting(false);

                        } catch (error) {
                            const message = error.message || "Something went wrong";

                            setErrors({ submit: message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                        touched,
                        values,
                        status
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container mt={4} mb={4}> 
                                {errors.submit && (
                                    <Alert mt={4} mb={4} severity="error">
                                        Error updating payment method
                                    </Alert>
                                )}

                                {success === true &&
                                    <Alert mt={4} mb={4} severity="success" >
                                        Payment method updated successfully.
                                    </Alert>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Grid container spacing={2} >

                                        <Grid item xs={6} >
                                            <TextField
                                                type="text"
                                                name="nameOnCard"
                                                label="Name on Card"
                                                required
                                                value={values.nameOnCard}
                                                error={Boolean(touched.nameOnCard && errors.nameOnCard)}
                                                fullWidth
                                                helperText={touched.nameOnCard && errors.nameOnCard}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                my={3}
                                            /></Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container spacing={2} >
                                        <Grid item xs={6} >
                                            <TextField
                                                label="Credit Card Number"
                                                name="ccnumber"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={values.ccnumber}
                                                helperText={touched.ccnumber && errors.ccnumber}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: StripeInput,
                                                    inputProps: {
                                                        component: CardNumberElement
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} >
                                            <TextField
                                                label="Expiration Date"
                                                name="ccexp"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={values.ccexp}
                                                helperText={touched.ccexp && errors.ccexp}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: StripeInput,
                                                    inputProps: {
                                                        component: CardExpiryElement
                                                    },
                                                }}

                                            />
                                        </Grid>
                                        <Grid item xs={3} >
                                            <TextField
                                                label="CVV"
                                                name="cvv"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={values.cvv}
                                                helperText={touched.cvv && errors.cvv}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    inputComponent: StripeInput,
                                                    inputProps: {
                                                        component: CardCvcElement
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container spacing={2} >
                                        <Grid item xs={6} ><TextField
                                            type="text"
                                            name="billingAddress"
                                            label="Billing Address"
                                            value={values.billingAddress}
                                            error={Boolean(touched.billingAddress && errors.billingAddress)}
                                            fullWidth
                                            helperText={touched.billingAddress && errors.billingAddress}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            my={3}
                                        /></Grid>
                                        <Grid item xs={6} > <TextField
                                            type="text"
                                            name="billingCity"
                                            label="City"
                                            value={values.billingCity}
                                            error={Boolean(touched.billingCity && errors.billingCity)}
                                            fullWidth
                                            helperText={touched.billingCity && errors.billingCity}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            my={3}
                                        /></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container spacing={2} >
                                        <Grid item xs={6} ><FormControl sx={{ mt: 0, minWidth: 120 }} fullWidth>
                                            <InputLabel htmlFor="countryLabel">Country</InputLabel>

                                            <Field name="billingCountry" component={SelectForFormik}>
                                                {availableCountries.map((country) => (
                                                    <MenuItem value={country.code} key={country.code}>{country.label}</MenuItem>
                                                ))}
                                            </Field>

                                        </FormControl></Grid>
                                        <Grid item xs={6} > <TextField
                                            type="text"
                                            name="billingZipCode"
                                            label="Zip Code"
                                            value={values.billingZipCode}
                                            error={Boolean(touched.billingZipCode && errors.billingZipCode)}
                                            fullWidth
                                            helperText={touched.billingZipCode && errors.billingZipCode}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            my={3}
                                        /></Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} >
                                    {(errors.ccnumber || errors.ccexp || errors.cvv) &&
                                        <Alert mt={2} mb={1} severity="error">
                                            {errors.ccnumber}
                                            <br />
                                            {errors.ccexp}
                                            <br />
                                            {errors.cvv}
                                        </Alert>}
                                </Grid>

                                <Grid item xs={12} >
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} >
                                            <LoadingButton
                                                variant="contained"
                                                type="submit"
                                                disabled={isSubmitting}
                                                loading={isSubmitting}
                                                sx={{ ml: 2, mt: 1, mr: 1 }}
                                            >
                                                Update
                                            </LoadingButton>

                                        
                                        </Grid>


                                    </Grid>

                                </Grid>
                            </Grid>



                        </form>
                    )}
                </Formik>

            </Grid>

        </>
    )
}