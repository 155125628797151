
import { Card, CardMedia, Grid, } from "@mui/material";
import React, { useState } from "react"
 

export default function IssueImages({ issue}) {
    return (
        <Grid container spacing={2} mt={2}>
            {
               typeof issue.photos !== 'undefined' && issue?.photos.map((item) => (
                    <Grid item xs={12} md={6} xl={4} key={`1`}>
                        <Card  >
                            <CardMedia
                                component="img"
                                height="200"
                                image={item}
                            />
                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    )
}