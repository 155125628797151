
import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { listenToIssueRecordedActions } from "../../../fireStore/vehiclesCollection";
import { useDispatch, useSelector } from "react-redux";
import { issuesSlice } from "../../../redux/slices/issues";
import RecordedActionMenu from "../../common/recordedActionMenu";
import useAuth from "../../../hooks/useAuth";
import { LoadingButton } from "@mui/lab";
import { updateIssueStatus } from "../../../functions/issue";
import { resolved, ignored } from "../../../functions/constants";

export default function IssueDetailToolBar({ issueId, vehicleId, selectedIssue }) {
    const { recordedActions } = useSelector(state => state.issue);
    const dispatch = useDispatch();

    const { user } = useAuth();
    const [resolving, setResolving] = useState(false);
    const [rejecting, setRejecting] = useState(false);

    const query = () => listenToIssueRecordedActions(issueId, vehicleId);
    const dispatchAction = (actions) => {
        dispatch(issuesSlice.actions.setRecordedActions({ actions: actions }));
    }

    const handleResolve = async () => {
        setResolving(true)
        await updateIssueStatus(selectedIssue.id, vehicleId, user.ud, resolved, '', user.clientId)
        setResolving(false)
    }

    const handleReject = async () => {
        setRejecting(true)
        await updateIssueStatus(selectedIssue.id, vehicleId, user.ud, ignored, '', user.clientId)
        setRejecting(false)
    }

    return (
        <Grid container spacing={2} >

            <Grid item>
                <LoadingButton
                    variant='contained'
                    color="success"
                    onClick={handleResolve}
                    loading={resolving}
                    disabled={selectedIssue.status !== 'new'}
                >Resolve</LoadingButton>
            </Grid>

            <Grid item>
                <LoadingButton
                    variant='contained'
                    color="error"
                    onClick={handleReject}
                    disabled={selectedIssue.status !== 'new'}
                    loading={rejecting}
                >Reject</LoadingButton>
            </Grid>

            <Grid item>
                <LoadingButton variant='contained' color="info" >Link</LoadingButton>
            </Grid>

            <Grid item>
                <Button variant='outlined' color="primary" component={NavLink} to='/maintanance/issues/create'>Create New</Button>
            </Grid>

            <Grid item>
                <RecordedActionMenu query={query} dispatchAction={dispatchAction} dispatch={dispatch} recordedActions={recordedActions} />
            </Grid>

        </Grid>
    )
}