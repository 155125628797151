import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import WorkOrderIssueTable from './workOrderIssueTable';

export default function WorkOrderIssues({ vehicle }) {
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Select Issues
                </Typography>

                <Grid container spacing={4} >
                    <Grid item xs={12}>
                        <WorkOrderIssueTable vehicle={vehicle} />
                    </Grid>
                </Grid>
            </CardContent>
 
        </Card>
    )
}