import firebase from "../fireStore/config";

const CloudFunctions = firebase.app().functions('europe-west2');

export async function createLabel(label, clientId) {
    const cloudFunctionName = 'createNewLabel'; 
    const newLabelFunc = CloudFunctions.httpsCallable(cloudFunctionName);

    return await newLabelFunc({
        label: label, 
        clientId: clientId
    }).then(response => {
        return response.data; 
    }).catch(err => {
        console.log(err);
        return 'error'; 
    })
}
