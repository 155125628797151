import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { createWorkOrderReportGeneral } from '../../functions/workorder_report'

export const fetchGeneralReportAsync = createAsyncThunk(
    "plans/fetchGeneralReport",
    async ({ clientId, predicate }) => {
        return await createWorkOrderReportGeneral(clientId, predicate);
    }
);

const initialState = {
    generalReport: null,
    error: null,
    isLoading: false,
    status: '',
    filters: {
        currentMonth: format(new Date(), 'MMM yyyy')
    }
};


export const workorderReportSlice = createSlice({
    name: "woreport",
    initialState,
    reducers: {
        setSelectedMonth: (state, action) => {
            state.filters.currentMonth = action.payload.currentMonth;
        }
    },
    extraReducers: {
        [fetchGeneralReportAsync.pending]: (state) => {
            state.status = "loading";
            state.isLoading = true;
        },
        [fetchGeneralReportAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.generalReport = action.payload;
            state.isLoading = false;
        },
        [fetchGeneralReportAsync.rejected]: (state, action) => {
            state.status = "error";
            state.error = action.payload;
            state.generalReport = null;
            state.isLoading = false;
        }
    }
})


export default workorderReportSlice.reducer;