import { createContext, useEffect, useReducer, useState } from "react";
import firebase from "../fireStore/config";

const INITIALIZE = "INITIALIZE";
const SET_PROFILE = "SET_PROFILE";
const SET_SUBSTATUS = "SET_SUBSTATUS";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  subStatus: 'inactive'
};

const authReducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  } else if (action.type === SET_PROFILE) {
    const { profileData } = action.payload;
    return {
      ...state,
      profileData
    };
  }
  else if (action.type === SET_SUBSTATUS) {
    const { subStatus } = action.payload;
    return {
      ...state,
      subStatus
    };
  }

  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [profile, setProfile] = useState();
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {

          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user },
          });

          const docRef = firebase.firestore().collection("users").doc(user.uid);
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                const profileData = doc.data()
                setProfile(profileData);

                dispatch({
                  type: SET_PROFILE,
                  payload: { profileData },
                });
              }
            }) 
            .catch((error) => {
              console.error(error);
            });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const signIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signInWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signInWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const signUp = (email, password, firstName, lastName, country, clientId , phone ) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection("users")
          .doc(res.user?.uid)
          .set({
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
            clientId: clientId,
            phone,
            roles: ['FleetManager'],
            country: country,
            rootUser: true,
            status: 'active'
          });
      });

  const signOut = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: auth.uid,
          email: auth.email,
          avatar: auth.avatar || profile?.avatar,
          displayName: auth.displayName || profile?.displayName,
          role: "user",
          clientId: profile?.clientId,
        },
        signIn,
        signUp,
        signInWithGoogle,
        signInWithFaceBook,
        signInWithTwitter,
        signOut,
        resetPassword 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { authReducer, AuthContext, AuthProvider };
