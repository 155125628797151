import { format, parse } from "date-fns";
import firebase from "../fireStore/config";

const CloudFunctions = firebase.app().functions('europe-west2');

export async function addVehicleReminder(values, reg, vehicleId, clientId, userId) {
    const {title, expDate, remindBefore} = values; 
    const code = title.substring(0, 3); 
    const expDateString = format(expDate, 'yyyy-MM-dd');

    const cloudFunctionName = 'addVehicleReminder';
    const addVehicleReminder = CloudFunctions.httpsCallable(cloudFunctionName);
    
    const data = {
        code: code,
        title: title,
        expDate: expDateString,
        vehicleId: vehicleId,
        reminderGroup: ['FleetManager'],
        remindMeBeforeXdays: remindBefore,
        emailReminder: false,
        clientId: clientId,
        reg: reg,
        userId
    }

    return await addVehicleReminder(data).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}


export async function removeVehicleReminder(vehicleId, reminderId) {
    const cloudFunctionName = 'deleteVehicleReminder';
    const deleteVehicleReminder = CloudFunctions.httpsCallable(cloudFunctionName);
    
    const data = {
         id: reminderId, 
         vehicleId: vehicleId
    }

    return await deleteVehicleReminder(data).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}
