import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import CreateVehicleForm from "./createVehicleForm";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function CreateVehicle() {
  return (
    <React.Fragment>
      <Helmet title="Add Vehicle" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Vehicle
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/dashboard">
          Dashboard
        </Link>
        <Link component={NavLink} to="/Vehicles">
          Vehicles
        </Link>
        <Typography>Add Vehicle</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <CreateVehicleForm />
    </React.Fragment>
  );
}

export default CreateVehicle;
