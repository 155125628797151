import firebase from './config'


export const vehicleCollection = 'vehicles';
export const reminderCollection = 'reminders';
export const clientsCollection = 'clients';
export const usersCollection = 'users';
export const notificationCollection = 'notifications';
export const recordedActionsCollection = 'recorded_actions';
export const userEventsCollection = 'user_events';
export const warningsCollection = 'warnings';
export const issuesCollection = 'issues';
export const commentsCollection = 'comments'
export const contactsCollection = 'contacts';
export const labelsCollection = 'labels';
export const workOrderCollection = 'workorders';
export const statsEventsCollection = 'stats_events';
export const customerQueriesCollection = 'customer_queries';

export const attachmentsCollection = 'attachments';
export const inspectionsCollection = 'inspections';

export const idempotencyCollection = 'idempotency';
export const statisticsCollection = 'statistics';
export const serviceTasksCollection = 'service_tasks';
export const userInvitesCollection = 'user_invites';
export const mailCollection = 'mail';
export const partsCollection = 'parts';
export const reportsCollection = 'reports'

export const currencySymbol = '$'

export const getFirebaseTime = () => {
    return firebase.firestore.Timestamp.now();
}