import firebase from './config'
import generator from 'generate-password'
import { userInvitesCollection } from './collections';

const db = firebase.firestore();

export function setUserProfile(user) {
    return db.collection('users').doc(user.uid).set({
        displayName: user.firstName,
        email: user.email,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })
}

export function getUserProfile(id) {
    return db.collection('users').doc(id).get();
}

export function getClientUserProfile(id) {
    return db.collection('users').doc(id);
}


export function listenToUserNotifications(userId) {
    return db.collection('users').doc(userId).collection('notifications').orderBy('created', 'desc').limit(10);
}

export function sendUserInvite(invite, clientId) {
    return db.collection('user_invites').where('email', '==', invite.email).limit(1).get().then((snap) => {
        if (snap.docs.length > 0) {
            return 'email-in-use';
        }
        invite.inviteCode = generateUserPassword();
        return db.collection('user_invites').add({
            ...invite,
            created: firebase.firestore.Timestamp.now(),
            clientId: clientId
        }).then(() => {
            return 'success';
        })
    }).then(response => {
        return response;
    })
}

export function listenUsersFromLookup(clientId) {
    return db.collection("users").where("clientRef", "==", clientId);
}

export async function updateUserProfilePhoto(downloadUrl) {
    const user = firebase.auth().currentUser;
    return db.collection("users").doc(user.uid).update({
        photoURL: downloadUrl,
    }).then(() => {
        return user.updateProfile({ photoURL: downloadUrl });
    }).then(() => {
        return 'success';
    }).catch(error => {
        console.log(error);
        return 'error';
    })
}

function generateUserPassword() {
    return generator.generate({
        length: 8,
        numbers: true
    });

}


export function updateUserPassword(creds) {
    const user = firebase.auth().currentUser;
    return user.updatePassword(creds.newPassword1);
}


export async function deleteUserInvite(userinviteId) {
    return db.collection(userInvitesCollection).doc(userinviteId).update({ status: 'deleted' });
}