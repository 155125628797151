import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/Signup/SignUpWithPaymentMethod";
import Join from "./pages/auth/Signup/SignUpWithCode";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Blank from "./pages/pages/Blank";
//import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Chat from "./pages/pages/Chat";

 
// Landing
import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import InspectionsPage from "./pages/inspections/inspectionsPage";
import InspectionDetailsPage from "./pages/inspections/details/inspectionDetailsPage";
import VehiclesPage from "./pages/vehicles/vehiclesPage";
import VehicleDetailPage from "./pages/vehicles/details/vehicleDetailPage";
import CreateVehicle from "./pages/vehicles/create/createVehicle";
import ClientsPage from "./pages/clients/clientsPage";
import CreateClient from "./pages/clients/create/createClient";
import AccountPage from "./pages/account/accountPage";
import NewWorkOrder from "./pages/workorder/_workOrderNew";
import WorkOrders from "./pages/workorder/_workOrders";

import WorkOrderView from "./pages/workorder/_workOrderView";
import IssuesView from "./pages/issues/_issuesView";
import NewIssue from "./pages/issues/create/_createNewIssue";
import IssuesDetails from "./pages/issues/_issueDetails";

import Reports from "./pages/reports/reports";
import { Report } from "@mui/icons-material";
import WorkOrderReports from "./pages/reports/workorder/workorder_report";
import ReminderCalendar from "./pages/reminder/reminder_calender";
import ServiceTasksView from "./pages/servicetask/servicetasksView";
import PartsView from "./pages/parts/partsView";
import CompleteSubscription from "./pages/auth/Signup/completeSubscription";
import Contact from "./pages/help/contact";
// Dashboard components
const SaaS = async(() => import("./pages/dashboards/SaaS"));
const Profile = async(() => import("./pages/auth/Profile/ProfilePage"));
 
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));



const routes = [
  {
    path: "/",
    element: <AuthGuard><DashboardLayout ></DashboardLayout></AuthGuard>,
    children: [
      {
        path: "",
        element: <SaaS />,
      }
    ],
  },
  {
    path: "dashboard",
    element: <AuthGuard><DashboardLayout ><SaaS /></DashboardLayout></AuthGuard>,
  },
  {
    path: "/",
    element: <AuthGuard><DashboardLayout /></AuthGuard>,
    children: [
      {
        path: "vehicles",
        element: <VehiclesPage />,
      },
      {
        path: "vehicles/:id",
        element: <VehicleDetailPage />,
      },
      {
        path: "vehicles/create",
        element: <CreateVehicle />,
      }
    ],
  },
  {
    path: "service",
    element: <AuthGuard><DashboardLayout /></AuthGuard>,
    children: [
      {
        path: "workorders/:vid/:wid",
        element: <WorkOrderView />,
      },
      {
        path: "workorders",
        element: <WorkOrders />,
      },
      {
        path: "workorders/create",
        element: <NewWorkOrder />,
      },
      {
        path: "reminders",
        element: <ReminderCalendar />,
      },
      {
        path: "tasks",
        element: <ServiceTasksView />,
      },
      {
        path: "parts",
        element: <PartsView />,
      }
    ],
  },
  {
    path: "maintanance",
    element: <AuthGuard><DashboardLayout /></AuthGuard>,
    children: [
      {
        path: "issues",
        element: <IssuesView />,
      },
      {
        path: "issues/:vid/:iid",
        element: <IssuesDetails />,
      },
      {
        path: "issues/create",
        element: <NewIssue />,
      },
      {
        path: "inspections",
        element: <InspectionsPage />,
      },
      {
        path: "inspections/:vid/:iid",
        element: <InspectionDetailsPage />,
      }
    ],
  },
  {
    path: "jobs",
    element: <AuthGuard><DashboardLayout ><Blank /></DashboardLayout></AuthGuard>,
  },
  {
    path: "tracking",
    element: <AuthGuard><DashboardLayout ><Blank /></DashboardLayout></AuthGuard>,
  },
  {
    path: "chat",
    element: <AuthGuard><DashboardLayout ><Chat /></DashboardLayout></AuthGuard>,
  },
  {
    path: "/",
    element: <AuthGuard><DashboardLayout /></AuthGuard>,
    children: [
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "reports/workorder",
        element: <WorkOrderReports />,
      } 
    ],
  },
  {
    path: "admin",
    element: <AuthGuard><DashboardLayout /></AuthGuard>,
    children: [
      {
        path: "clients",
        element: <ClientsPage />,
      },
      {
        path: "clients/create",
        element: <CreateClient />,
      },
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "profile",
        element: <Profile />,
      }
    ],
  },
  {
    path: "help",
    element: <AuthGuard><DashboardLayout /></AuthGuard>,
    children: [
      {
        path: "contact",
        element: <Contact />,
      } 
    ],
  },
  {
    path: "tasks",
    element: <AuthGuard><DashboardLayout /></AuthGuard>,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "calendar",
    element: <AuthGuard><DashboardLayout /></AuthGuard>,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "join",
        element: <Join />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      }
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
