import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from 'axios'
import fileDownload from 'js-file-download'

import {
    Grid,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    IconButton,
    Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import InspectionDetailHeader from "./inspectionDetailHeader";
import { inspectionSlice } from "../../../redux/slices/inspections";
import { useDispatch, useSelector } from "react-redux";
import InspectionDetailSectionsList from "./inspectionDetailSectionsList";
import useFireStoreDoc from "../../../hooks/useFireStoreDoc";
import { listenToInspectionDoc } from "../../../fireStore/inspectionsCollection";
import InspectionImages from "./inspectionImages";
import { Download,PictureAsPdf  } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";

import { getInspectionPdfDownloadUrl } from "../../../functions/inspection";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";

// const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function InspectionDetailsPage() {

    const dispatch = useDispatch();
    const { vid, iid } = useParams();
    const { selectedInspection } = useSelector(state => state.inspection)
    const [downloading, setDownloading] = useState(false);
    const { user } = useAuth();

    useFireStoreDoc({
        shouldExecute: !!iid && !!vid,
        query: () => listenToInspectionDoc(vid, iid),
        data: (inspection) => {
            dispatch(inspectionSlice.actions.setSelectedInspection({ inspection: inspection }))
            dispatch(inspectionSlice.actions.updateInspectionLookUp({ inspection: inspection }))
        },
        deps: [iid, vid, dispatch],
        refFields: [],
    });

    const handleGenerate = async () => {
        setDownloading(true);
        await getInspectionPdfDownloadUrl(vid, iid, user?.clientId).then(() => {
            setDownloading(false);
        });
    }

    const handleDownload = () => {
        setDownloading(true);

        axios.get(selectedInspection.reportUrl, {
            responseType: 'blob',
        }).then((res) => {
            fileDownload(res.data, "Inspection-" + iid + ".pdf")
            setDownloading(false);
        }).catch(err => {
            setDownloading(false);
        })
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    if (selectedInspection === null) return <></>

    return (
        <React.Fragment>
            <Helmet title="Inspection" />
            <Typography variant="h3" gutterBottom display="inline">
                Inspection #{selectedInspection?.inspectionId}
            </Typography>


            <Grid container spacing={2} justifyContent='space-between' >
                <Grid item>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to="/">
                            Dashboard
                        </Link>
                        <Link component={NavLink} to="/maintanance/inspections">
                            Inspections
                        </Link>
                        <Typography>Inspection</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} >
                        <Grid item>
                            {selectedInspection.reportUrl ?
                                <LoadingButton loading={downloading} variant='contained' color="primary" startIcon={<Download />}
                                    onClick={handleDownload}>Download Report</LoadingButton>

                                : <LoadingButton loading={downloading} variant='contained' color="primary" startIcon={<PictureAsPdf />}
                                    onClick={handleGenerate}>Export Pdf</LoadingButton>}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} xl={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InspectionDetailHeader inspection={selectedInspection} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InspectionImages inspection={selectedInspection} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} xl={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InspectionDetailSectionsList inspection={selectedInspection} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}

export default InspectionDetailsPage;
