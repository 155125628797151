import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    notifications: []
};


export const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        addNotifications: (state, action) => {
            state.notifications = [...action.payload.notifications]; 
        } 
    }
})


export default notificationSlice.reducer;