import { hydrate } from "../utils/hydrateHelper";
import firebase from "firebase/app";

export function dataFromSnapshot(snapshot) {
    if (!snapshot.exists) return undefined;
    const data = snapshot.data();
  
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (data[prop] instanceof firebase.firestore.Timestamp) {
          data[prop] = data[prop].toDate();
        }
      }
    }
  
    return {
      ...data,
      id: snapshot.id,
    };
  }
  
  export async function dataFromSnapshotAsync(snapshot, refFields) {
    if (!snapshot.exists) return undefined;
    const data = snapshot.data();
  
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (data[prop] instanceof firebase.firestore.Timestamp) {
          data[prop] = data[prop].toDate();
        }
      }
    }
  
    if (refFields) await hydrate(data, refFields);

    return {
      ...data,
      id: snapshot.id,
    };
  }


  