
import { Autocomplete, Button, Divider, Grid, IconButton, InputAdornment, Paper, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import BuildIcon from '@mui/icons-material/Build';
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import useFireStoreCollection from "../../../hooks/useFireStoreCollection";
import { addToPartsCollection, listenPartsFromLookup } from "../../../fireStore/workorder";
import { LoadingButton } from "@mui/lab";
import { partsSlice } from "../../../redux/slices/parts";
import { CurrencyFormatCustom, NumberFormatCustom } from "../../../components/custom";

export default function AddPartsView({ addParts, closeParts }) {
    const dispatch = useDispatch();
    const textRef = useRef();
    const [adding, setAdding] = useState(false);
    const [itemAdded, newItemAdded] = useState(false);

    const { parts } = useSelector(state => state.parts);

    const { user } = useAuth();
    let clientId = user?.clientId;


    useFireStoreCollection({
        query: () => listenPartsFromLookup(clientId),
        data: (partDocs) => transformToLookUp(partDocs),
        deps: [user, itemAdded],
        refFields: [],
    });

    function transformToLookUp(partDocs) {

        let partsLookUp = [];
        partDocs.forEach(doc => {
            partsLookUp = [...partsLookUp, ...doc.parts]
        });

        dispatch(partsSlice.actions.fetchParts({ parts: partsLookUp.sort((a, b) => a.t.localeCompare(b.t)) }));
    }

    function sleep(ms, resolve) {
        return new Promise(() => setTimeout(() => { return setAdding(false) }, ms));
    }

    const handleAddNewItemToParts = async (partName) => {
        if (partName?.replace(/\s/g, '') === '' || typeof clientId === 'undefined') return;

        setAdding(true);
        partName = partName.slice(0, 20);
        await addToPartsCollection(partName, clientId)

        await sleep(4000);
        setAdding(false);
    }

    const initialValues = {
        selectedPart: null,
        qty: '',
        cost: '',
        amount: ''
    }

    const validationSchema = Yup.object().shape({
        selectedPart: Yup.object().shape({
            t: Yup.string().required()
        }),
        qty: Yup.number()
            .positive('Must be > 0')
            .max(1000, "Limit is 1000")
            .required('Required'),
        cost: Yup.number()
            .positive('Must be > 0')
            .max(1000000, "Limit is 1000000")
            .required('Required'),
    })


    return (

        <Box padding={3}>
            <Typography variant="h6" >Add Part</Typography>
            <Divider sx={{ pt: 3 }} />
            <Box sx={{ pt: 3 }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                        isValid,
                        setFieldValue
                    }) => (

                        <form noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    <Autocomplete
                                        clearOnEscape
                                        name='selectedPart'
                                        onChange={(event, value) => {
                                            setFieldValue("selectedPart", value);
                                        }}
                                        options={parts}
                                        getOptionLabel={(option) => option.t}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <BuildIcon fontSize="small" sx={{ mr: 2 }} />
                                                {option.t}
                                            </Box>
                                        )}

                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Part"
                                                variant="standard"
                                                inputRef={textRef}
                                                name={'txtredderinput'}
                                            />}

                                        noOptionsText={<LoadingButton
                                            variant="text"
                                            loading={adding}
                                            onClick={() => { handleAddNewItemToParts(textRef.current.value) }}>
                                            Add to Parts List
                                        </LoadingButton>}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    > </Autocomplete>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField
                                        name="qty"
                                        label="Quantity"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        variant="standard"
                                        required
                                        value={values.qty}
                                        error={Boolean(touched.qty && errors.qty)}
                                        fullWidth
                                        helperText={touched.qty && errors.qty}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    />

                                </Grid>
                                <Grid item xs={3} >
                                    <TextField
                                        name="cost"
                                        label="Cost"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputComponent: CurrencyFormatCustom,
                                        }}
                                        variant="standard"
                                        required
                                        value={values.cost}
                                        error={Boolean(touched.cost && errors.cost)}
                                        fullWidth
                                        helperText={touched.cost && errors.cost}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    />

                                </Grid>
                                <Grid item xs={12}  >
                                    <Grid container justifyContent="right" alignItems="center" >
                                        <Grid item >
                                            <Button type="button"
                                                disabled={isSubmitting || values.selectedPart === null || !isValid}
                                                onClick={() => {
                                                    let amount = values.qty * values.cost;
                                                    values.amount = amount.toFixed(2);
                                                    addParts(values);
                                                    closeParts();
                                                }}
                                                variant="contained" >Add</Button></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}