import { Alert, FormControl, Grid, InputLabel, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import useAuth from "../../../hooks/useAuth";
import { Field, Formik } from "formik";
import SelectForFormik from "../../../components/selectForFormik";
import { LoadingButton } from "@mui/lab";
import StripeInput from "../../../components/StripeInput";
import { signupSlice } from "../../../redux/slices/signup";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlansAsync } from "../../../redux/slices/plans";


export default function SignUpPayment({ handleNext, handleBack }) {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const [country, setCountry] = useState("");// eslint-disable-line
    const [errorMessage, setErrorMessage] = useState(null);// eslint-disable-line
    const [processing, setProcessing] = useState(false);// eslint-disable-line
    const [cardError, setCardError] = useState(null);// eslint-disable-line
    const { user } = useAuth();
    const { plans } = useSelector((state) => state.plans);

    const availableCountries = [{ "code": "IE", "label": "Ireland", "phone": "353" }, { "code": "GB", "label": "United Kingdom", "phone": "44" }]

    const initialValues = {
        subscription: '',
        nameOnCard: '',
        ccnumber: '',
        ccexp: '',
        cvv: '',
        billingAddress: "",
        billingCity: "",
        billingCountry: "",
        billingZipCode: "",
    }

    const createPaymentMethod = async (pmObj) => {
        setCountry('IE');
        setProcessing(true);
        setErrorMessage(null);

        console.log("creating sub");

        if (!stripe || !elements) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod(pmObj);

        if (error) {
            setCardError(error.message);
            console.log(error.message);
        }

        return paymentMethod;
    }

    const stripeDataObjectConverter = ({ nameonCard, address, city, country_code, postal_code }, cardElement) => ({
        payment_method: {
            type: 'card',
            card: cardElement,
            billing_details: {
                address: {
                    city,
                    country: country_code,
                    line1: address,
                    postal_code,
                    state: null
                },
                name: nameonCard,
                email: user?.email,
                phone: null
            },
        },
    })

    const handleSubscriptionChange = (subscription) => {
        if (subscription !== null && subscription !== '') {
            let selectedPlan = plans.filter((plan) => plan.id === subscription)[0];
            dispatch(signupSlice.actions.setSubscription({ subscription: selectedPlan }))
        }
    };

    const limit = 20;

  useEffect(() => {
    dispatch(fetchPlansAsync());
  }, [dispatch]);

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        const cardElement = elements.getElement(CardCvcElement);
                        const stripePmValues = stripeDataObjectConverter({
                            nameonCard: values.nameOnCard,
                            address: values.billingAddress, city: values.billingCity, country_code: values.billingCountry, postal_code: values.billingZipCode
                        }, cardElement);

                        var paymentMethod = await createPaymentMethod(stripePmValues.payment_method);
                        dispatch(signupSlice.actions.setPaymentMethod({ paymentMethod }));
                        handleNext();
                    } catch (error) {
                        const message = error.message || "Something went wrong";

                        setStatus({ success: false });
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        {errors.submit && (
                            <Alert mt={2} mb={1} severity="warning">
                                {errors.submit}
                            </Alert>
                        )}

                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={6} > 
                                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                                        <InputLabel htmlFor="subscriptionPlan">Subscription</InputLabel>

                                        <Field name="subscription" component={SelectForFormik} handleChange={handleSubscriptionChange} >
                                            {plans.length > 0 && plans.map((plan) => (
                                                <MenuItem value={plan.id} key={plan.id}>{plan.name} (&euro;{plan.price}/{plan.paymentCycle})</MenuItem>
                                            ))}
                                        </Field>

                                    </FormControl>

                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <TextField
                                            type="text"
                                            name="nameOnCard"
                                            label="Name on Card"
                                            required
                                            value={values.nameOnCard}
                                            error={Boolean(touched.nameOnCard && errors.nameOnCard)}
                                            fullWidth
                                            helperText={touched.nameOnCard && errors.nameOnCard}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            my={3}
                                        /></Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={6} >
                                        <TextField
                                            label="Credit Card Number"
                                            name="ccnumber"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={values.ccnumber}
                                            helperText={touched.ccnumber && errors.ccnumber}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                inputComponent: StripeInput,
                                                inputProps: {
                                                    component: CardNumberElement
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} >
                                        <TextField
                                            label="Expiration Date"
                                            name="ccexp"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={values.ccexp}
                                            helperText={touched.ccexp && errors.ccexp}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                inputComponent: StripeInput,
                                                inputProps: {
                                                    component: CardExpiryElement
                                                },
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} >
                                        <TextField
                                            label="CVV"
                                            name="cvv"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={values.cvv}
                                            helperText={touched.cvv && errors.cvv}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                inputComponent: StripeInput,
                                                inputProps: {
                                                    component: CardCvcElement
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={6} ><TextField
                                        type="text"
                                        name="billingAddress"
                                        label="Billing Address"
                                        value={values.billingAddress}
                                        error={Boolean(touched.billingAddress && errors.billingAddress)}
                                        fullWidth
                                        helperText={touched.billingAddress && errors.billingAddress}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    /></Grid>
                                    <Grid item xs={12} sm={6}> <TextField
                                        type="text"
                                        name="billingCity"
                                        label="City"
                                        value={values.billingCity}
                                        error={Boolean(touched.billingCity && errors.billingCity)}
                                        fullWidth
                                        helperText={touched.billingCity && errors.billingCity}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    /></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={6} ><FormControl sx={{ mt: 0, minWidth: 120 }} fullWidth>
                                        <InputLabel htmlFor="countryLabel">Country</InputLabel>

                                        <Field name="billingCountry" component={SelectForFormik}>
                                            {availableCountries.map((country) => (
                                                <MenuItem value={country.code} key={country.code}>{country.label}</MenuItem>
                                            ))}
                                        </Field>

                                    </FormControl></Grid>
                                    <Grid item xs={12} sm={6} > <TextField
                                        type="text"
                                        name="billingZipCode"
                                        label="Zip Code"
                                        value={values.billingZipCode}
                                        error={Boolean(touched.billingZipCode && errors.billingZipCode)}
                                        fullWidth
                                        helperText={touched.billingZipCode && errors.billingZipCode}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    /></Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={6} >
                                        <LoadingButton
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                            sx={{ ml: 2, mt: 1, mr: 1 }}
                                        >
                                            Review
                                        </LoadingButton></Grid>
                                </Grid>
                            </Grid>
                        </Grid>



                    </form>
                )}
            </Formik>


        </>
    )
}