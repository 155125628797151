import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import calendarStyle from "./Calendar.style";
import useFireStoreCollection from "../../hooks/useFireStoreCollection";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { fetchVehicleReminders } from "../../fireStore/vehiclesCollection";
import { reminderSlice } from "../../redux/slices/reminders";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function ReminderCalendar() {

    const navigate = useNavigate();
    const { user } = useAuth();
    let clientId = user?.clientId;
    const dispatch = useDispatch();

    const { calenderItems } = useSelector(state => state.reminders);

    useFireStoreCollection({
        query: () => fetchVehicleReminders(clientId),
        data: (reminders) => dispatch(reminderSlice.actions.fetchReminders({ items: reminders })),
        deps: [clientId],
        refFields: [],
        shouldExecute: typeof clientId !== 'undefined'
    });


    return (
        <React.Fragment>
            <Helmet title="Reminders" />
            <Typography variant="h3" gutterBottom display="inline">
                Reminders
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Link component={NavLink} to="/">
                    Service
                </Link>
                <Typography>Reminders</Typography>
            </Breadcrumbs>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card mb={6}>
                        <CardContent p={6}>
                            <FullCalendarWrapper>
                                <FullCalendar
                                    initialView="dayGridMonth"
                                    initialDate={format(new Date(), "yyyy-MM-dd")}
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    events={calenderItems}
                                    editable={true}
                                    headerToolbar={{
                                        left: "prev,next",
                                        center: "title",
                                        right: "dayGridDay,dayGridWeek,dayGridMonth",
                                    }}
                                    eventClick={(info) => {
                                        const vehicleId = info.event.id.split(';')[0]; 
                                        navigate(`/vehicles/${vehicleId}`)
                                    }}
                                />
                            </FullCalendarWrapper>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ReminderCalendar;
