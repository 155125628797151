import React, { useEffect, useState } from "react";
import { Card, CardContent, Divider, List, Typography } from "@mui/material";
import IssueCommentItem from "./issueCommentItem";
import IssueCommentForm from "./issueComentForm";
import styled from "styled-components/macro";
import useFireStoreCollection from "../../../hooks/useFireStoreCollection";
import { listenToIssueComments } from "../../../fireStore/issueCollection";
import { issuesSlice } from "../../../redux/slices/issues";
import { useDispatch, useSelector } from "react-redux";

const TableWrapper = styled.div`
  height: 470px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


export default function IssueDetailCommentList({ selectedIssue }) {

    const { vehicleId, id } = selectedIssue;
    const dispatch = useDispatch();

    const { comments } = useSelector(state => state.issue);

    useFireStoreCollection({
        query: () => listenToIssueComments(vehicleId, id),
        data: (comments) => parseCommentsCollection(comments),
        deps: [selectedIssue, dispatch],
        refFields: [],
        shouldExecute: selectedIssue !== null
    });

    const parseCommentsCollection = (commentDocs) => {
        let comments = [];
        commentDocs.forEach(commentDoc => {
            comments = [...comments, ...commentDoc.items.filter(x => x.deleted !== true)]
        });

        dispatch(issuesSlice.actions.setComments({ comments: comments.sort((o1, o2) => { return o2.created.toDate() - o1.created.toDate() }) }))
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Comments
                </Typography>

                <IssueCommentForm selectedIssue={selectedIssue} />

                <TableWrapper>
                    <List sx={{ bgcolor: 'background.paper' }}>

                        {comments.map(comment => (

                            <div key={comment.id}>
                                <IssueCommentItem comment={comment} />
                                <Divider variant="inset" component="li" />
                            </div>
                        ))}


                    </List>
                </TableWrapper>

            </CardContent>
        </Card>
    )
}