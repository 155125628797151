import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ContactDetails from "./contactdetails";
import ContactForm from "./contactform";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

 
function Contact() {
  return (
    <React.Fragment>
      <Helmet title="Support" />
      <Typography variant="h3" gutterBottom display="inline">
        Support
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/dashboard">
          Dashboard
        </Link>
        <Typography>Support</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ContactDetails />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Contact;
