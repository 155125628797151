import { Card, CardContent, Grid, Typography } from "@mui/material"
import React from "react"
import WorkItemsReadOnlyMiniFooter from "../items/workItemsReadOnlyMiniFooter"
import WorkOrderItemTable from "../items/workOrderItemTable"

export default function WorkOrderDetailsTab({ selectedWorkOrder }) {

    return (
        <Grid container mt={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Service Tasks
                        </Typography>
                        <Grid>
                            <WorkOrderItemTable lineItems={selectedWorkOrder?.lineItems} readonly={true} />
                            <WorkItemsReadOnlyMiniFooter summary={selectedWorkOrder?.summary} />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}