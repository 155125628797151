import { Box, Divider, Fade, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import {
    MoreVert,

} from "@mui/icons-material";
import { Add, Check, Block } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { ignored, resolved } from "../../functions/constants";
import useAuth from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { updateWorkOrderAsCompleted } from "../../functions/workorder";

export default function WorkOrderActions({ id, vehicleId, currentStatus, updatingStatus }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateNew = () => {
        navigate('/service/workorders/create')
    }

   
    return (
        <Box mr={2}>
            <IconButton aria-label="share" size="large"
                aria-owns={'wo-menu'}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>

            <Menu
                id="wo-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleCreateNew}>
                    <ListItemIcon>
                        <Add fontSize="small" />
                    </ListItemIcon>
                    New Work Order
                </MenuItem>
          
            </Menu>

        </Box>
    )
}