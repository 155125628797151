import { LoadingButton } from "@mui/lab";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import * as React from 'react';
import { cancelSubscriptionCloudFunction } from "../../functions/subscription";
import useAuth from "../../hooks/useAuth";


export default function CancelSubscription({ handleCancel, profile }) {

    const [open, setOpen] = React.useState(false);
    const { user } = useAuth();
    const [error, setError] = React.useState('');
    const [reason, setReason] = React.useState('');

    const [saving, setSaving] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const unsubscribeStripe = async () => {
        setSaving(true);
        const outcome = await cancelSubscriptionCloudFunction(user?.id, reason);
        if (outcome?.data === 'success') {
            handleClose();
            handleCancel();
        } else {
            setError('Error while unsubscribe, please contact support.')
        }
        setSaving(false)
    }

    return (
        <div>
            <Button size="small" color="error" variant="text" onClick={handleClickOpen}
                disabled={profile?.subscription?.userCanceled}
            >Cancel Subscription</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Cancel Subscription</DialogTitle>
                <DialogContent>
                    {error !== '' &&
                        <Alert mt={2} mb={1} severity="error">
                            {error}
                        </Alert>
                    }

                    <DialogContentText>
                        We are sorry to see you leaving, Can you please optionally specify reason why you decided to leave ? This would help us improving our product. The product will be active till end your subscription day this month.

                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="reason"
                        label="Reason for leaving"
                        type="text"
                        fullWidth
                        rows={4}
                        multiline
                        variant="standard"
                        onChange={(event) => {
                            setReason(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={saving} onClick={unsubscribeStripe}>Unsubscribe</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}