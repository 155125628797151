import React from "react";

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

 

const headCells = [
    { id: "title", alignment: "left", label: "Reminder" },
    { id: "expDate", alignment: "right", label: "Due Date" },
    { id: "expIndays", alignment: "right", label: "Expire In" },
    { id: "remindmebefore", alignment: "right", label: "Remind Me Before" },
    { id: "actions", alignment: "left", label: "" },
   ];
  
 
export const VehicleReminderTableHead = (props) => {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
 
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignment}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  