import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    parts: []
};


export const partsSlice = createSlice({
    name: "parts",
    initialState,
    reducers: {
        fetchParts: (state, action) => {
            state.parts = [...action.payload.parts]; 
        } 
    }
})


export default partsSlice.reducer;