import React from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Field, Formik } from "formik";

import {
  Alert as MuiAlert,

  Card,

  CardContent,

  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import SelectForFormik from "../../../components/selectForFormik";
import { sendUserInvite } from "../../../fireStore/userService";
import useAuth from '../../../hooks/useAuth'

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const roles = [{
  "code": "FleetManager",
  "label": "Fleet Manager",
  "helperText": 'Admin users with full access to the dashboard such as adding Vehicle, Issues, WorkOrder etc..'

},
{
  "code": "StandardUser",
  "label": "Standard User",
  "helperText": 'All other users like mechanic, drivers who has limited access to the dashboard but full access to mobile app.'
}]

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  role: '',
  inviteCode: '',
  clientId: '',
  userId: ''
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(20).required("First name is required"),
  lastName: Yup.string().max(20).required("Last name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(50)
    .required("Email is required"),
  role: Yup.string().required()
})

function UserInviteForm({ newItemAdded }) {

  const { user } = useAuth();
  const clientId = user?.clientId;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" >Invite App User</Typography>
        <Typography variant="caption">Please enter the details of the user you wish to add, app will notify the user with an email with instructions how to join.</Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
              values.userId = user.id;
              await sendUserInvite(values, clientId).then((response) => {
 
                if (response === 'success') {
                  resetForm();
                  setStatus({ success: true });
                  newItemAdded(state => !state);
                } else if (response === 'email-in-use') {
                  const message = 'Email already in use!!'
                  setStatus({ success: false });
                  setErrors({ submit: message });
                }
                setSubmitting(false);
              })
            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            isValid
          }) => (

            <form onSubmit={handleSubmit} noValidate>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}

              {status && status.success && (
                <Alert severity="success" my={3}>
                  Invitation to join Fleops has successfully sent.
                </Alert>
              )}

              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12} >
                    <Grid container spacing={1} >
                      <Grid item xs={6} >
                        <TextField
                          type="text"
                          name="firstName"
                          label="First Name"
                          value={values.firstName}
                          error={Boolean(touched.firstName && errors.firstName)}
                          fullWidth
                          helperText={touched.firstName && errors.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          my={3}
                        />
                      </Grid>
                      <Grid item xs={6} >
                        <TextField
                          type="text"
                          name="lastName"
                          label="Last Name"
                          value={values.lastName}
                          error={Boolean(touched.lastName && errors.lastName)}
                          fullWidth
                          helperText={touched.lastName && errors.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          my={3}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} >
                    <Grid container spacing={2} >

                      <Grid item xs={6} >
                        <TextField
                          type="text"
                          name="email"
                          label="Email"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          my={3}
                        />
                      </Grid>

                      <Grid item xs={6} >
                        <FormControl sx={{ mt: 3, minWidth: 120 }} fullWidth>
                          <InputLabel htmlFor="roleLabel" >Role</InputLabel>

                          <Field name="role" component={SelectForFormik} >
                            {roles.map((role) => (
                              <MenuItem value={role.code} key={role.code}  >
                                <Box flexDirection='row' >
                                  <Typography variant="subtitle2">{role.label}</Typography>
                                  <Typography variant="caption">{role.helperText}</Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>

                      </Grid>

                    </Grid>
                  </Grid>



                  <Grid container spacing={2} justifyContent='end'>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      disabled={!isValid || isSubmitting || (isValid && values.email === '')}
                      loading={isSubmitting}
                      sx={{ ml: 2, mt: 1, mr: 1 }}
                    >
                      Send Invite
                    </LoadingButton>
                  </Grid>

                </Grid>)}

            </form>
          )}
        </Formik>
      </CardContent>
    </Card>

  );
}

export default UserInviteForm;
