import { FormControl, Grid, TableCell, MenuItem, Select, Stack, Table, TableBody, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { currencySymbol } from "../../../fireStore/collections";
import CountryCurrency from "../../common/countryCurrency";


export default function WorkItemsReadOnlyMiniFooter({ summary }) {
    return (

        <Grid container justifyContent='right' mt={3} >
            <Grid item xs={6} lg={4} >
                <Table >
                    <TableBody>
                        <TableRow>
                            <TableCell key='labour6' align='right'>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="baseline"
                                    spacing={2}
                                >
                                    <Typography>Discount</Typography>
                                    <Select
                                        value={summary.discount.type}
                                        label=""
                                        autoWidth
                                        size="small"
                                        readOnly
                                    >
                                        <MenuItem value={'percent'}>%</MenuItem>
                                        <MenuItem value={'money'}>€</MenuItem>
                                    </Select>

                                    <FormControl >
                                        <TextField
                                            size="small"
                                            label={summary.discount.type === 'percent' ? 'Percentage' : 'Amount'}
                                            style={{ width: 100 }}
                                            value={summary.discount.value}
                                            readOnly
                                        />
                                    </FormControl>
                                </Stack>
                            </TableCell>
                            <TableCell key='222' align='left' >
                                <CountryCurrency value={summary.discountAmt} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell key='labour6' align='right' >
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="baseline"
                                    spacing={2}
                                >
                                    <Typography>Tax</Typography>
                                    <Select
                                        value={summary.tax.type}
                                        autoWidth
                                        size="small"
                                        readOnly
                                    >
                                        <MenuItem value={'percent'}>%</MenuItem>
                                        <MenuItem value={'money'}>€</MenuItem>

                                    </Select>

                                    <FormControl >
                                        <TextField
                                            size="small"
                                            label={summary.tax.type === 'percent' ? 'Percentage' : 'Amount'}
                                            style={{ width: 100 }}
                                            value={summary.tax.value}
                                        />
                                    </FormControl>
                                </Stack>
                            </TableCell>
                            <TableCell key='222' align='left' >
                                <CountryCurrency value={summary.taxAmt}/> 
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid >
    )
}