import { useEffect } from "react";
import { dataFromSnapshotAsync } from "../fireStore/frestoreService";

export default function useFireStoreDoc({ query, data, deps, refFields, shouldExecute = true }) {
     useEffect(() => {
        if (!shouldExecute) return;
 
        const unsubscribe = query().onSnapshot(
            async snapshot => {
                if (!snapshot.exists) {
                    return;
                }
                
                data(await Promise.resolve(dataFromSnapshotAsync(snapshot, refFields)));
  
            },
            error => console.log(error)
        );

        return () => {
            unsubscribe();
        }
    }, deps) // eslint-disable-line
}