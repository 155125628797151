import { vehicleLookupPartitionSize } from '../functions/partitionSizes';
import { attachmentsCollection, issuesCollection, recordedActionsCollection, reminderCollection, vehicleCollection } from './collections';
import firebase from './config'


const db = firebase.firestore();

export function fetchVehiclesFromFireStore(
  predicate,
  limit,
  client,
  lastDocSnapshot = null
) {
  let vehiclesRef = db
    .collection(vehicleCollection)
    .where("clientId", "==", client)
    .limit(limit);
  return vehiclesRef;
}

export function listenToVehicleFromFireStore(vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId);
}


export function listenToVehicleIssues(vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection('issues').orderBy('created', 'desc').limit(10);
}

export function listenToActiveVehicleIssues(vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection('issues')
    .where('status', '==', 'new').orderBy('created', 'desc').limit(10);
}

export function listenToWorkorderIssues(vehicleId, issueIds) {
  return db.collection(vehicleCollection).doc(vehicleId).collection('issues')
    .where('issueId', 'in', issueIds).orderBy('created', 'desc');
}


export function listenToVehicleReminders(vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection('reminders').where('isDeleted', '==', false).orderBy('expDate', 'asc');
}

export async function fetchVehiclesFromLookup(predicate, lastLoadedPartition) {
  const clientId = predicate.get("clientId");
  let result = { empty: true };

  if (lastLoadedPartition === 0) {
    return await db.collection(vehicleCollection)
      .where('clientRef', '==', clientId)
      .where('docType', '==', 'vLookUp')
      .orderBy('partitionIndex', "desc").limit(1).get().then((snaps) => {
        if (snaps.docs.length !== 0) {
          const { partitionIndex, items } = snaps.docs[0].data();
          result.maxPartition = partitionIndex;
          result.items = [...items];
          result.length = ((partitionIndex - 1) * vehicleLookupPartitionSize) + items.length;
          result.lastLoadedPartition = partitionIndex;
        }
        result.empty = false;
        return result;
      })
  } else {
    const nextPartition = lastLoadedPartition - 1;
    if (nextPartition >= 1) {
      return await db.collection(vehicleCollection)
        .where('clientRef', '==', clientId)
        .where('docType', '==', 'vLookUp')
        .where('partitionIndex', '==', nextPartition).limit(1).get().then(snaps => {
          if (snaps.docs.length !== 0) {
            const { partitionIndex, items } = snaps.docs[0].data();
            result.items = [...items];
            result.lastLoadedPartition = partitionIndex;
          }
          result.empty = false;
          return result;
        })
    } else {
      result.empty = false;
      result.items = [];
      result.lastLoadedPartition = 1;
      return result;
    }
  }
}


export async function fetchVehiclesForDropDown(clientId) {
  let vehicleItems = [];
  await db.collection(vehicleCollection).where('clientRef', '==', clientId)
    .where('docType', '==', 'vLookUp').get().then(snaps => {
      snaps.docs.forEach(snap => {
        const { items } = snap.data();
        vehicleItems = [...vehicleItems, ...items.map(item => { return { id: item.id, label: item.reg } })]
      })
    })

  return vehicleItems;
}

export function listenToVehicleRecordedActions(vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection(recordedActionsCollection);
}


export function listenToVehicleAttachments(vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection(attachmentsCollection);
}

export function addVehicleAttachment(vehicleId, url, name, size, clientContext) {
  const data = {
    vehicleId,
    url,
    name,
    size,
    userId: clientContext.userId,
    clientContext,
    created: firebase.firestore.Timestamp.now()
  }
  return db.collection(vehicleCollection).doc(vehicleId).collection(attachmentsCollection).add(data);
}

export function deleteVehicleAttachment(vehicleId, attachmentId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection(attachmentsCollection).doc(attachmentId).delete();
}


export function listenToIssueRecordedActions(issueId, vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId)
    .collection(issuesCollection).doc(issueId).collection(recordedActionsCollection);
}


export function listenToIssue(issueId, vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId)
    .collection(issuesCollection).doc(issueId);
}


export function fetchVehicleReminders(clientId) {
  return db.collection(reminderCollection).where("clientRef", "==", clientId);
}