
import {
    Box,
    Button,
    Divider,
    Grid,
    Popover as MuiPopover,
    TextField,
    Typography,
} from "@mui/material";
import styled from "styled-components";
import { useRef, useState } from "react";
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import { updateWorkOrderAsRejected } from "../../../functions/workorder";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const initialValues = {
    comment: '',
}

const validationSchema = Yup.object().shape({
})


export default function WorkOrderRejectMenu({ workorderId, vehicleId, disabled }) {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);

    const { user } = useAuth();
    let clientId = user?.clientId;

    const handleMarkRejected = async (values) => {
        setSaving(true);
        await updateWorkOrderAsRejected(values.comment, user.id, clientId, workorderId, vehicleId);
        

        setOpen(false);
        setSaving(false);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Button variant='contained' color="error" disabled={disabled}
                onClick={() => setOpen(true)} ref={ref}>Reject</Button>

            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
            >
                <React.Fragment>
                    <Box padding={3}>
                        <Typography variant="h6" >Mark as Rejected</Typography>
                        <Divider sx={{ pt: 3 }} />
                        <Box sx={{ pt: 3 }}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    status,
                                    isValid,
                                    setFieldValue
                                }) => (

                                    <form noValidate>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} mt={3} >
                                                <TextField
                                                    name="comment"
                                                    label="Comment"
                                                    type="text"
                                                    multiline
                                                    rows={3}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                    value={values.comment}
                                                    error={Boolean(touched.comment && errors.comment)}
                                                    fullWidth
                                                    helperText={touched.comment && errors.comment}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    my={3}
                                                />

                                            </Grid>

                                            <Grid item xs={12}  >
                                                <Grid container justifyContent="right" alignItems="center" >
                                                    <Grid item>
                                                        <Button variant="outlined" onClick={handleClose} >
                                                            Cancel
                                                        </Button>
                                                    </Grid>

                                                    <Grid item >
                                                        <LoadingButton
                                                            variant="contained"
                                                            type="button"
                                                            color="primary"
                                                            disabled={isSubmitting || values.selectedUser === null || !isValid}
                                                            loading={saving}
                                                            sx={{ ml: 2, mt: 1, mr: 1 }}
                                                            onClick={() => handleMarkRejected(values)}
                                                        >
                                                            Reject
                                                        </LoadingButton>

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </React.Fragment>
            </Popover>
        </>
    )
}