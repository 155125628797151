
import { Grid, List, ListItem, ListItemText, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";


export default function SignUpOrderSummary() {

    const { subscription } = useSelector(state => state.signup);

    if (subscription === null) return <Typography variant="body2"><i>No Subscription selected</i></Typography>
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} >
                <Table>
                    <TableBody>
                        <TableRow key='header'>
                            <TableCell component="th" scope="row" align="left">
                                Plan
                            </TableCell>
                            <TableCell align="right" sx={{ fontWeight: 500 }}>
                                Price
                            </TableCell>
                        </TableRow>

                        <TableRow key='plan'>
                            <TableCell scope="row" align="left">
                                {subscription?.name}
                            </TableCell>
                            <TableCell align="right" sx={{ fontWeight: 500 }}>
                                {subscription?.price} / {subscription?.paymentCycle}
                            </TableCell>
                        </TableRow>

                        <TableRow key='whats included' >
                            <TableCell scope="row" align="left" colSpan={2}>
                                <Typography variant="subtitle2">Whats included</Typography>

                                <List dense={true}>
                                    {subscription?.features.map((feature) => (
                                        <ListItem key={feature}>
                                            <ListItemText
                                                primary={feature}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </TableCell>
                        </TableRow>

                     
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}