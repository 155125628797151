import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    IconButton,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow
} from "@mui/material";
import {
    MoreVert
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { VehiclesTableHead } from "./vehiclesTableHead";
import { VehiclesTableToolbar } from "./vehiclesTableToolbar";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { fetchVehiclesFromLookup } from "../../fireStore/vehiclesCollection";
import { vehicleSlice } from "../../redux/slices/vehicles";
import { format } from "date-fns";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
        el,
        index,
    }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
}

const Paper = styled(MuiPaper)(spacing);

export default function VehiclesTable() {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("customer");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const { items, lastLoadedPartition } = useSelector(state => state.vehicles.vehicles);

    const { user } = useAuth();
    let clientId = user?.clientId;

    const [predicate, setPredicate] = useState(
        new Map([
            ["clientId", clientId]
        ])
    );

    async function fetchVehicles() {
        let result = await fetchVehiclesFromLookup(predicate, lastLoadedPartition);
        if (result.empty) return;

        if (typeof result.maxPartition !== 'undefined') {
            const { maxPartition, length } = result;
            if (typeof maxPartition !== 'undefined' && typeof length !== 'undefined') {
                dispatch(vehicleSlice.actions.initVehicles({ ...result }));
            }
        }
        else {
            const { items, lastLoadedPartition } = result;
            dispatch(vehicleSlice.actions.setVehicles({ items, lastLoadedPartition }));
        }
    }

    useEffect(() => {
        if (typeof clientId === 'undefined') return;
        predicate.set('clientId', clientId);

        fetchVehicles();
    }, [dispatch, predicate, clientId]);

    useEffect(() => {
        if (items.length > 0 && items.length < rowsPerPage) {
            loadMore(page);
        }

    }, [items])


    const loadMore = (newPage) => {
        const islastPage = (items.length - (newPage * rowsPerPage)) < rowsPerPage;
        if (islastPage) {
            loadNextPageIfAvailable();
        }
    }

    const loadMoreWhenRowsPerPageChanges = (rowpp) => {
        const islastPage = (items.length - (page * rowpp)) < rowpp;
        if (islastPage) {
            loadNextPageIfAvailable();
        }
    }

    const loadNextPageIfAvailable = async () => {
        await fetchVehicles();
    }



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = items.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        loadMore(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        loadMoreWhenRowsPerPageChanges(event.target.value);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);


    return (
        <div>
            <Paper>
                <VehiclesTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                    >
                        <VehiclesTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={items.length}
                        />
                        <TableBody>
                            {stableSort(items, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`${row.id}-${index}`}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                    onClick={(event) => handleClick(event, row.id)}
                                                />
                                            </TableCell>

                                            <TableCell align="left">
                                                <Button component={Link} to={`/vehicles/${row.id}`}>
                                                    {row.reg}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.mk} {row.md}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.health ?
                                                    <Chip
                                                        label='Healthy'
                                                        color="success"
                                                        size="small"
                                                        mr={1}
                                                        mb={1} />
                                                    : <Chip
                                                        label='Unhealthy'
                                                        color="error"
                                                        size="small"
                                                        mr={1}
                                                        mb={1}
                                                    />}
                                            </TableCell>
                                            <TableCell>
                                                {row.fuel}
                                            </TableCell>
                                            <TableCell>
                                                {row.tr === 'A' ? 'Automatic' : 'Manual'}
                                            </TableCell>
                                            <TableCell align="left">{typeof row?.mlg === 'undefined' ? '--' : row?.mlg}</TableCell>
                                            <TableCell align="left">{typeof row?.mlgd === 'undefined' ? '--' : format(row?.mlgd.toDate(), 'dd/MM/yyyy hh:mm')}</TableCell>
                                            <TableCell padding="none" align="right">
                                                <IconButton aria-label="share" size="large">
                                                    <MoreVert />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}