
import React from "react";
import { Button, ButtonGroup, Grid, IconButton, Typography } from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import ReportActions from "./reportActions";


export default function ReportToolBar({ title, handleSetPredicate }) {
    return (
        <Grid container spacing={2} justifyContent='space-between' paddingLeft={3} >

            <Grid item>
                <Typography variant="h6" >{title}</Typography>
            </Grid>
            <Grid item>
                <ReportActions handleSetPredicate={handleSetPredicate} />
            </Grid>
        </Grid>
    )
}