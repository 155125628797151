import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { fetchVehiclesForDropDown } from "../../fireStore/vehiclesCollection";
import useAuth from "../../hooks/useAuth";

export default function VehicleLookUp({ handleSelect, setFieldValue, error, helperText, ...props }) {

    const { user } = useAuth();
    let clientId = user?.clientId;
    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        if (typeof clientId === 'undefined' || vehicles.length !== 0) return;
        async function fetchData() {
            let items = await fetchVehiclesForDropDown(clientId);
            setVehicles(items);
        }
        fetchData();
    }, [clientId])

    return <>
        <Autocomplete
            {...props}
            onChange={(event, value) => {
                if (typeof setFieldValue !== 'undefined') setFieldValue(props.name, value);
                if (typeof handleSelect !== 'undefined') handleSelect(value);
            }}
            options={vehicles}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src="/static/img/avatars/truck.jpg"
                        alt=""
                    />
                    {option.label}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Vehicle" error={error} helperText={helperText} my={3}/>}
        > </Autocomplete>
    </>
}
