import { LoadingButton } from "@mui/lab";
import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listenLabelsFromLookup } from "../../fireStore/workorder";
import { createLabel } from "../../functions/labels";
import useAuth from "../../hooks/useAuth";
import useFireStoreCollection from "../../hooks/useFireStoreCollection";
import { labelsSlice } from "../../redux/slices/labels";


export default function LabelLookUp({ handleSelect, setFieldValue, ...props }) {

    const dispatch = useDispatch();
    const { user } = useAuth();
    const labelRef = useRef();

    let clientId = user?.clientId;

    const [adding, setAdding] = useState(false);
    const [itemAdded, newItemAdded] = useState(false);

    const { labels } = useSelector(state => state.label);

    useFireStoreCollection({
        query: () => listenLabelsFromLookup(clientId),
        data: (labels) => transformToLookUp(labels),
        deps: [user, itemAdded],
        refFields: [],
        shouldExecute: typeof clientId !== 'undefined'
    });

    function transformToLookUp(labels) {
        let lookup = [];
        labels.forEach(doc => {
            lookup = [...lookup, ...doc.items]
        });

        dispatch(labelsSlice.actions.fetchLabels({ labels: lookup.sort((a, b) => a.localeCompare(b)) }));
    }

    const handleAddNewLabel = async (label) => {
        if (label?.replace(/\s/g, '') === '' || typeof clientId === 'undefined') return;
        setAdding(true);

        label = label.slice(0, 20);
        await createLabel(label, clientId);

        setAdding(false);
        newItemAdded(state => !state);
    }

    return <>
        <Autocomplete
            {...props}
            multiple
            limitTags={6}
            onChange={(event, value) => {
                if (typeof setFieldValue !== 'undefined') setFieldValue(props.name, value);
                if (typeof handleSelect !== 'undefined') handleSelect(value);
            }}
            options={labels}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option}
                </Box>
            )}

            renderInput={(params) =>
                <TextField
                    {...params}
                    label='Choose labels'
                    inputRef={labelRef}
                    name={'txtreddesdrinput'}
                    helperText={'If the label is not in the list, please type the new label and click on the Add To Label List option. please expect 3-4 sec delay'}
                />}

            noOptionsText={
                <LoadingButton
                    variant="text"
                    loading={adding}
                    onClick={() => { handleAddNewLabel(labelRef.current.value) }}>
                    Add to Label List
                </LoadingButton>}
            isOptionEqualToValue={(option, value) => option === value}
        > </Autocomplete>
    </>
}