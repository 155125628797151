import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    IconButton,
    Paper as MuiPaper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
} from "@mui/material";
import {
    MoreVert,

} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { VehicleIssueTableHead } from "./vehicleIssueTableHead";
import { VehicleIssueTableToolBar } from "./vehicleIssueTableToolBar";
import useFireStoreCollection from "../../../../hooks/useFireStoreCollection";
import { listenToVehicleIssues } from "../../../../fireStore/vehiclesCollection";
import { vehicleSlice } from "../../../../redux/slices/vehicles";
import { useDispatch, useSelector } from "react-redux";
import IssueStatus from "../../../common/issueStatus";


const TableWrapper = styled.div`
  height: 450px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
        el,
        index,
    }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
}

const Paper = styled(MuiPaper)(spacing);

export default function VehicleIssueTable({ vehicle }) {

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("customer");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const dispatch = useDispatch();
    const { selectedVehicleIssues } = useSelector(x => x.vehicles);

    useFireStoreCollection({
        query: () => listenToVehicleIssues(vehicle?.id),
        data: (issues) => dispatch(vehicleSlice.actions.setSelectedVehicleIssues({ issues: issues })),
        deps: [dispatch, vehicle],
        refFields: [],
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = selectedVehicleIssues.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    if (selectedVehicleIssues === null) return <></>

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, selectedVehicleIssues.length - page * rowsPerPage);

    return (
        <Paper padding={3}>
            <VehicleIssueTableToolBar numSelected={selected.length} />
            <TableWrapper>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                    >
                        <VehicleIssueTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={selectedVehicleIssues.length}
                        />
                        <TableBody>
                            {stableSort(selectedVehicleIssues, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`${row.id}-${index}`}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                    onClick={(event) => handleClick(event, row.id)}
                                                />
                                            </TableCell>

                                            <TableCell align="left"  >
                                                <Button mr={2} component={Link} to={`/maintanance/issues/${vehicle.id}/${row.id}`}>
                                                    {`#${row.issueId}`}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="left">{row.issue}</TableCell>
                                            <TableCell align="left">
                                                <IssueStatus status={row.status} />
                                            </TableCell>
                                            <TableCell align="left">{row.createdBy}</TableCell>
                                            <TableCell align="left">{row.created.toLocaleString()}</TableCell>

                                            <TableCell padding="none" align="right">
                                                <Box mr={2}>
                                                    <IconButton aria-label="share" size="large">
                                                        <MoreVert />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                           
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableWrapper>
        </Paper>
    );
}