import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    contacts: []
};


export const contactsSlice = createSlice({
    name: "contacts",
    initialState,
    reducers: {
        fetchContacts: (state, action) => {
            state.contacts = [...action.payload.contacts]; 
        } 
    }
})


export default contactsSlice.reducer;