import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Field, Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import SelectForFormik from "../../../components/selectForFormik";
import { LoadingButton } from "@mui/lab";
import { addClient } from "../../../fireStore/clientsCollection";
import PrivacyLabel from "../../common/privacyPolicyLabel";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  companyName: '',
  country: '',
  phone: ''
}

const availableCountries = [{ "code": "IE", "label": "Ireland", "phone": "353" }, { "code": "GB", "label": "United Kingdom", "phone": "44" }]

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(20).required("First name is required"),
  lastName: Yup.string().max(20).required("Last name is required"),
  phone: Yup.number().optional(),
  country: Yup.string().required("Please select country"),
  companyName: Yup.string().max(20).required("Please enter company name"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(50)
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Must be at least 12 characters")
    .max(50)
    .required("Required"),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
})

function SignUpAccount() {
  const { signUp, signIn } = useAuth();
  const [terms, setTermsConditions] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          var client = await addClient(values);
          await signUp(values.email, values.password, values.firstName, values.lastName, values.country, client.id, values.phone);
          await signIn(values.email, values.password);
          setSubmitting(false);
        } catch (error) {
          const message = error.message || "Something went wrong";
          console.log(error);
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Grid container spacing={2} >

                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="email"
                    label="Email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="phone"
                    label="Phone"
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} >
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} >
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} >
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="companyName"
                    label="Company Name"
                    value={values.companyName}
                    error={Boolean(touched.companyName && errors.companyName)}
                    fullWidth
                    helperText={touched.companyName && errors.companyName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <FormControl sx={{ mt: 3, minWidth: 120 }} fullWidth>
                    <InputLabel htmlFor="countryLabel">Country</InputLabel>

                    <Field name="country" component={SelectForFormik}>
                      {availableCountries.map((country) => (
                        <MenuItem value={country.code} key={country.code}>{country.label}</MenuItem>
                      ))}
                    </Field>
                    <FormHelperText>*Currently available only above selected countries.</FormHelperText>
                    
                  </FormControl>

                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} >
              <Divider />
            </Grid>

            <Grid item xs={12} >
              <FormControlLabel control={<Checkbox value={terms} onChange={(e) => {
                setTermsConditions(e.target.checked);
              }} />} label={<PrivacyLabel />} />
            </Grid>



            <Grid container spacing={2}>
              <Grid item xs={6} >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !terms}
                  loading={isSubmitting}
                  sx={{ ml: 2, mt: 1, mr: 1 }}
                >
                  Continue
                </LoadingButton>
              </Grid>
            </Grid>

          </Grid>



        </form>
      )}
    </Formik>
  );
}

export default SignUpAccount;
