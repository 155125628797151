import { Chip, Stack } from "@mui/material";
import React from "react";
import { criticalSeverity, majorSeverity, minorSeverity } from "../../functions/constants";


export default function IssueSeverity({ severity }) {
    return (
        <Stack direction={{ xs: "row", sm: "row" }}>
            {severity === criticalSeverity && <Chip label='Critical' color="error" variant="filled" size="small" sx={{ mr: 1 }} />}
            {severity === majorSeverity && <Chip label='Major' color='warning' size="small" variant="filled" sx={{ mr: 1 }} />}
            {severity === minorSeverity && <Chip label='Minor' color='primary' size="small" variant="filled" sx={{ mr: 1 }} />}
        </Stack>
    )
}