import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { checklistQuestions } from '../questions/fleetLogQuestions'
import InspectionDetailSection from "./inspectionDetailSection";

const groupTitle = {
    hgvincab: "In Cab Checks",
    hgvexternal: "External Vehicle Checks",
    hgvpriortoleaving: "Prior to Leaving Depot",
    hgvonroad: "On the Road",
    vanincab: "In Cab Checks",
    vanexternal: "External Vehicle Checks",
    vanleavingdepot: "Prior to Leaving Depot",
    vanontheroad: "On the Road",
};

export default function InspectionDetailSectionsList({ inspection }) {

    const groups = ["In Cab Checks", "External Vehicle Checks", "Prior to Leaving Depot", "On the Road"]
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Inspection Checklist
                </Typography>
                {
                    groups.map((group => (
                        <InspectionDetailSection
                            key={group}
                            header={group}
                            checkListItems={inspection?.inspectionData.filter((x) => x.grp === group)}
                            vehicleId={inspection.vehicleId}
                        />
                    )))
                }

            </CardContent></Card>
    )
}