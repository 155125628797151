import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
    Button,
    CardActions,
    CardContent,
    Grid,
    Card as MuiCard,
    CardHeader as MuiCardHeader,
    Typography,
    Avatar,
    Alert
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlansAsync } from "../../redux/slices/plans";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { changeSubscriptionPlan } from "../../functions/subscription";
import useAuth from "../../hooks/useAuth";
import { LoadingButton } from "@mui/lab";

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const SubscriptionWrapper = styled.div`
  height: 300px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


export default function ChangeSubscriptionPlan({ profile, handleCancel }) {
    const dispatch = useDispatch();
    const { plans } = useSelector((state) => state.plans);
    const { subscription } = profile;
    const [savingPlanId, setSaving] = useState('');

    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);

    const { user } = useAuth();

    useEffect(() => {
        dispatch(fetchPlansAsync());
    }, [dispatch]);

    const handleSubscriptionChange = async (plan) => {
        setSaving(plan.id);
        const subscriptionId = subscription.stripeActiveSubscriptionID;
        var outcome = await changeSubscriptionPlan(plan, subscriptionId, user.id);
        if (outcome?.data === 'success') {
            setStatus(outcome?.data)
        } else {
            setError('There is an error when switching subscription, please contact app support.')
        }
        setSaving('');
    }

    return (
        <>

            <Grid container justifyContent='space-between'>
                <Grid item>
                    <Typography variant="h6" gutterBottom>

                        Change Subscription Plan
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="text" onClick={handleCancel}>Cancel</Button>
                </Grid>
            </Grid>


            <Grid container justifyContent="center">
                <Grid item xs={12} lg={12}>
                    {status === 'success' &&
                        <Alert mt={2} mb={1} severity="success" sx={{ mt: 3 }}>
                            {'Your subscription plan switched successfully.'}
                        </Alert>}

                    {error !== null &&
                        <Alert mt={2} mb={1} severity="error">
                            {error}
                        </Alert>}
                    <Grid container spacing={6}>
                        {plans.map(plan => (<Grid item xs={12} md={3} key={plan?.id}>

                            <Card p={5} >
                                <CardHeader
                                    title={plan?.name}
                                    titleTypographyProps={{ align: "center" }}
                                    pb={0}
                                    pt={2}
                                />
                                <CardContent>


                                    <Price>
                                        <Typography
                                            component="h2"
                                            variant="h1"
                                            color="textPrimary"
                                            align="center"
                                            display="inline"
                                        >
                                            €{plan?.price}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary"
                                            align="center"
                                            display="inline"
                                        >
                                            /mo
                                        </Typography>
                                    </Price>
                                    <SubscriptionWrapper>
                                        {subscription?.currentPlan.id === plan?.id &&
                                            <Typography align="center" >
                                                <CheckCircleIcon color="success" />
                                            </Typography>
                                        }
                                        {plan?.features && plan?.features.map(feature => (
                                            <div key={feature}>
                                                <Typography variant="subtitle1" align="center" key={feature}>
                                                    {feature}
                                                </Typography>
                                                <br />
                                            </div>
                                        ))}
                                    </SubscriptionWrapper>

                                </CardContent>
                                <CardActions>
                                    <LoadingButton fullWidth variant="contained" color="primary"
                                        disabled={subscription?.currentPlan.id === plan?.id}
                                        onClick={() => handleSubscriptionChange(plan)}
                                        loading={savingPlanId === plan?.id}
                                    >
                                        {`Switch to ${plan?.name} License`}
                                    </LoadingButton>
                                </CardActions>
                            </Card>



                        </Grid>))}

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}