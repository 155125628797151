import { Avatar, Grid, Icon, Link, Typography } from "@mui/material";
import { AttachFile } from '@mui/icons-material';

export default function AttachmentTitle({ title, url }) {

    return (
        <Grid container justify="flex-end" spacing={1}>
            <Grid item>
                <AttachFile />
            </Grid>
            <Grid item>

                <Link href={url} target='_blank' underline="none">
                    {title}
                </Link>
            </Grid>
        </Grid>
    )

}