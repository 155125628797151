import { Button, Card, CardActions, CardContent, Chip, Grid, IconButton, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { unixToDate } from "../../common/util";


export default function AccountSubscriptionDetails({ profile }) {

    return (
        <>
            <Grid container justifyContent='space-between' spacing={2} p={3}>
                <Typography variant="h6" gutterBottom>
                    Subscription Details
                </Typography>
            </Grid>

            <Table>
                <TableBody>
                    <TableRow key='vehicleID'>
                        <TableCell component="th" scope="row">
                            Plan
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 500 }}>

                            <Chip label={profile?.subscription?.currentPlan.name} color='primary' ></Chip>
                        </TableCell>
                    </TableRow>

                    <TableRow key='odeometerreading'>
                        <TableCell component="th" scope="row">
                            Start
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 500 }}>
                            {profile?.subscription?.subscriptionCurrentPeriodStart && unixToDate(profile?.subscription?.subscriptionCurrentPeriodStart)}
                        </TableCell>
                    </TableRow>

                    <TableRow key='sdsdsds'>
                        <TableCell component="th" scope="row">
                            End
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 500 }}>
                            {profile?.subscription?.subscriptionCurrentPeriodEnd && unixToDate(profile?.subscription?.subscriptionCurrentPeriodEnd)}
                        </TableCell>
                    </TableRow>
                    <TableRow key='sdsdsdsdsdsds'>
                        <TableCell component="th" scope="row">
                            Status
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 500 }}>

                            {profile?.subscription?.subscriptionStatus === 'trialing' && profile?.subscription?.userCanceled === false && <Chip label='In Trial' color="warning" />}
                            {profile?.subscription?.subscriptionStatus === 'active' && profile?.subscription?.userCanceled === false && <Chip label='Active' color="success" />}

                            {profile?.subscription?.subscriptionStatus !== 'trialing'
                                && profile?.subscription?.subscriptionStatus !== 'active' && profile?.subscription?.userCanceled === false && 
                                <Chip label={profile?.subscription?.subscriptionStatus} color="error" />
                            }
                            {profile?.subscription?.userCanceled === true && <Chip label='Cancelled' color="error" />}
                        </TableCell>
                    </TableRow>

                    <TableRow key='dateLogged'>
                        <TableCell component="th" scope="row">
                            Payment Method
                        </TableCell>
                        <TableCell align="left" sx={{ fontWeight: 500 }}>
                            **** **** **** {profile?.subscription?.paymentMethod.last4} {profile?.subscription?.paymentMethod.exp_month}/{profile?.subscription?.paymentMethod?.exp_year}
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </>
    );
}
