import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dataFromSnapshotAsync } from "../../fireStore/frestoreService";
import { fetchPlansFromFireStore } from "../../fireStore/plansCollection";
 

export const fetchPlansAsync = createAsyncThunk(
    "plans/fetchPlans",
    async () => {
        let response = await fetchPlansFromFireStore().get();
        var plans = await Promise.all(response.docs.map(async doc => dataFromSnapshotAsync(doc, [])));
        return [...plans];
    }
);

const initialState = {
    plans: [],
    selectedPlan: null,
    status: null,
    error: null,
    isLoading: false
};


export const planSlice = createSlice({
    name: "plans",
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchPlansAsync.pending]: (state) => {
            state.status = "loading";
            state.isLoading = true;
        },
        [fetchPlansAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.plans = action.payload;
            state.isLoading = false;
        },
        [fetchPlansAsync.rejected]: (state, action) => {
            state.status = "error";
            state.error = action.payload;
            state.plans = [];
            state.isLoading = false;
        }
    }
})

export default planSlice.reducer;