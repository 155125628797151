import firebase from './config'

export function uploadDataUrlToFirebaseStorage(urlString, fileName) {
    const user = firebase.auth().currentUser;
    const storageRef = firebase.storage().ref();
    return storageRef.child(`${user.uid}/profile/${fileName}`).putString(urlString, 'data_url')
}

export function uploadToFirebaseStorage(storagePath, file, fileName) {
    const storageRef = firebase.storage().ref();
    return storageRef.child(`${storagePath}/${fileName}`).put(file);
}

export function deleteAttachment(attachmentUrl) {
    const storageRef = firebase.storage().refFromURL(attachmentUrl); 
    return storageRef.delete();
}