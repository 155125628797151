import { Button, Card, CardActions, CardContent, Divider } from "@mui/material";
import React, { useState } from "react";
import AccountSubscriptionDetails from "./accountSubscriptionDetails";
import CancelSubscription from "./cancelSubscription";
import ChangeSubscriptionPlan from "./changeSubscriptionPlan";
import UpdatePaymentMethodForm from "./updatePaymentMethodForm";

export default function BillingPage({ profile }) {
    const [view, setView] = useState('details');

    const handleCancel = () => {
        setView('details')
    }

    return (
        <Card mb={6}>
            <CardContent>
                {view === 'details' &&
                    <AccountSubscriptionDetails profile={profile} />
                }

                {view === 'cpm' &&
                    <UpdatePaymentMethodForm handleCancel={handleCancel} profile={profile} />
                }

                {view === 'csp' &&
                    <ChangeSubscriptionPlan profile={profile} handleCancel={handleCancel} />
                }

            </CardContent>
            <CardActions>
                <Divider my={6} sx={{ marginTop: 3, marginBottom: 3 }} />
                <Button size="small" color="primary" variant="text" onClick={() => setView('csp')}  >Change Subscription Plan</Button>
                <Button size="small" color="primary" variant="text" onClick={() => setView('cpm')}>Update Payment Method</Button>
                <CancelSubscription profile={profile} handleCancel={handleCancel}
                />
            </CardActions>
        </Card>

    )
}