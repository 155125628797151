import { Autocomplete, Avatar, Paper, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { listenUsersFromLookup } from "../../fireStore/userService";
import useAuth from "../../hooks/useAuth";
import useFireStoreCollection from "../../hooks/useFireStoreCollection";
import { accountSlice } from "../../redux/slices/account";
import { styled } from '@mui/material/styles';
import AvatarChip from "./avatarChip";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function UserLookUp({ label, variant, setFieldValue, error, helperText, ...props }) {

    const dispatch = useDispatch();
    const { usersLookUp } = useSelector(state => state.account);

    const { user } = useAuth();
    let clientId = user?.clientId;

    useFireStoreCollection({
        query: () => listenUsersFromLookup(clientId),
        data: (userDocs) => transformToLookUp(userDocs),
        deps: [clientId],
        refFields: [],
        shouldExecute: typeof clientId !== 'undefined'
    });

    function transformToLookUp(userDocs) {
        let usersLookUp = [];
        userDocs.forEach(doc => {
            usersLookUp = [...usersLookUp, ...doc.users.filter(u => typeof u.userId !== 'undefined')]
        });

        dispatch(accountSlice.actions.fetchUsersForLookup({ usersLookUp: usersLookUp }));
    }

    return <>
        <Autocomplete
            {...props}
            onChange={(event, value) => {
                if (typeof setFieldValue !== 'undefined') setFieldValue(props.name, value);
            }}
            options={usersLookUp}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.userId === value.userId}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <AvatarChip name={option?.name} srcObj={option} />
                </Box>
            )}
            renderInput={(params) => <TextField {...params}
                label={label}
                variant={variant}
                error={error}
                helperText={helperText}

            />}
        > </Autocomplete>
    </>
}

