import { Box, Card, CardContent, Grid, LinearProgress, Typography } from "@mui/material"
import React from "react"
import WorkOrderGeneralView from "./workOrderGeneralView"
import {
    Activity,
} from "react-feather";
import WorkOrderVendorView from "./workOrderVendor";
import WorkOrderLabels from "./workOrderLabels";
import WorkorderSummaryChart from "./workorderSummaryChart";
import styled from "styled-components/macro";
import { currencySymbol } from "../../../fireStore/collections";
import CountryCurrency from "../../common/countryCurrency";

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

function Earnings({ title, amount }) {
    return (
        <Box position="relative">
            <Card mb={2} pt={2}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        <Box fontWeight="fontWeightRegular"><CountryCurrency value={amount} />  </Box>
                    </Typography>
                    <Typography variant="body2" gutterBottom mt={3} mb={0}>
                        {title}
                    </Typography>

                    <StatsIcon>
                        <Activity />
                    </StatsIcon>
                    <LinearProgress
                        variant="determinate"
                        value={75}
                        color="secondary"
                        mt={4}
                    />
                </CardContent>
            </Card>
        </Box>
    );
}

export default function WorkOrderSummaryTab({ selectedWorkOrder }) {

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12} lg={4} >
                <WorkOrderGeneralView workorder={selectedWorkOrder} />
            </Grid>
            <Grid item xs={12} lg={8} >

                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <Earnings title='Total' amount={`${selectedWorkOrder?.summary.grandTotal}`} />
                    </Grid>

                    <Grid item xs={6} >
                        <Earnings title='Total Labour Cost' amount={`${selectedWorkOrder?.summary.subTotalLabour}`} />
                    </Grid>
                    <Grid item xs={6} >
                        <Earnings title='Total Parts Cost' amount={`${selectedWorkOrder?.summary.subTotalParts}`} />
                    </Grid>
                    <Grid item xs={6} >
                        <Earnings title='Total Tax' amount={`${selectedWorkOrder?.summary.taxAmt}`} />
                    </Grid>
                    <Grid item xs={12} lg={6} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <WorkOrderVendorView workorder={selectedWorkOrder} />
                            </Grid>
                            <Grid item xs={12} >
                                <WorkOrderLabels workorder={selectedWorkOrder} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6} >
                        <WorkorderSummaryChart summary={selectedWorkOrder?.summary} />
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}