import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Checkbox,
    Chip,
    Paper as MuiPaper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { WorkOrderIssueTableHeader } from "./workOrderIssueTableHeader";
import useFireStoreCollection from "../../../hooks/useFireStoreCollection";
import { listenToActiveVehicleIssues } from "../../../fireStore/vehiclesCollection";
import { vehicleSlice } from "../../../redux/slices/vehicles";
import { workorderSlice } from "../../../redux/slices/workorder";
import IssueStatus from "../../common/issueStatus";



export default function WorkOrderIssueTable({ vehicle }) {

    const { issues } = useSelector(state => state.workorder);
    const dispatch = useDispatch();
    const { selectedVehicleIssues } = useSelector(x => x.vehicles);

    useFireStoreCollection({
        query: () => listenToActiveVehicleIssues(vehicle?.id),
        data: (issues) => dispatch(vehicleSlice.actions.setSelectedVehicleIssues({ issues: issues })),
        deps: [dispatch, vehicle],
        refFields: [],
    });

    if (selectedVehicleIssues === null) return <></>

    const handleClick = (event, row) => {
        const selectedIndex = issues.indexOf(row);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(issues, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(issues.slice(1));
        } else if (selectedIndex === issues.length - 1) {
            newSelected = newSelected.concat(issues.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                issues.slice(0, selectedIndex),
                issues.slice(selectedIndex + 1)
            );
        }

        //setSelected(newSelected);
        dispatch(workorderSlice.actions.setWorkOrderIssues({ issues: newSelected }));
    };

    const isSelected = (row) => issues.indexOf(row) !== -1;

    if (selectedVehicleIssues?.length === 0) return <><i>No issues to select</i></>
    return (
        <TableContainer>
            <Table
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
            >
                <WorkOrderIssueTableHeader readonly={false}/>
                <TableBody>
                    {selectedVehicleIssues.map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={`${row.id}-${index}`}
                                selected={isItemSelected}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{ "aria-labelledby": labelId }}
                                        onClick={(event) => handleClick(event, row)}
                                    />
                                </TableCell>

                                <TableCell align="left"  >
                                    <Button mr={2} component={Link} to={`/issues/${row.id}`}>
                                        {`#${row.issueId}`}
                                    </Button>
                                </TableCell>
                                <TableCell align="left">{row.issue}</TableCell>
                                <TableCell align="left">
                                    <Stack direction={{ xs: "row", sm: "row" }}>
                                        <IssueStatus status={row.status} />
                                    </Stack>
                                </TableCell>

                                <TableCell align="left">{row.createdBy}</TableCell>
                                <TableCell align="left">{row.created.toLocaleString()}</TableCell>
                            </TableRow>
                        );
                    })}

                </TableBody>
            </Table>
        </TableContainer>
    );
}