import firebase from "../fireStore/config";

const CloudFunctions = firebase.app().functions('europe-west2');


export async function createWorkOrderReportGeneral(clientId, predicate) {
    const currentMonth = predicate.get('currentMonth');
    const getWorkOrderReportFunc = CloudFunctions.httpsCallable('getWorkOrderReport');

    const data = {
        clientId: clientId,
        currentMonth: currentMonth,
        filters: []
    }

    return await getWorkOrderReportFunc(data).then(response => {
        return response.data;
    }).catch(err => {
        return err;
    })
}



export async function createWorkOrderReportSummary(clientId) {
    const getReportYearlySummaryWorkOrderNumericsFunc = CloudFunctions.httpsCallable('getReportYearlySummaryWorkOrderNumerics');

    const data = {
        clientId: clientId, year: new Date().getFullYear(), refresh: true
    }

    return await getReportYearlySummaryWorkOrderNumericsFunc(data).then(response => {
        console.log(`Report ${response}`)
        return response.data;
    }).catch(err => {
        return err;
    })
}

