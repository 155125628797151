import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import WorkOrderItems from "./items/_workOrderItems";
import WorkOrderGeneral from "./general/_workOrderGeneral";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import WorkOrderIssues from "./issues/_workOrderIssues";
import { useDispatch, useSelector } from "react-redux";
import { workorderSlice } from "../../redux/slices/workorder";
import { createNewWorkOrder } from "../../functions/workorder";
import { forEach } from "lodash";


const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function NewWorkOrder() {

  const { isInitialized, user } = useAuth();
  const [saving, setSaving] = useState(false);
  const [outcome, setOutcome] = useState('');

  const { selectedVehicle, issues, lineItems, summary, comment } = useSelector(state => state.workorder);
  let clientId = user?.clientId;

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleCreateWorkOrder = async (values) => {
    setSaving(true);
    const result = await createNewWorkOrder(values, clientId, user?.id,
      selectedVehicle, issues, lineItems, summary, comment);

    setOutcome(result === 'error' ? result : 'success');
    setSaving(false);

    if (result !== 'error') {
      dispatch(workorderSlice.actions.addNewWorkOrder({ workorder: result }));
      navigate("/service/workorders");
    }
  }

  const isValid = (setErrors) => {
    if (lineItems.length === 0) {
      setErrors({ lineItems: 'You must create at least 1 line item with sub task with values.' })
      return false;
    } else if (lineItems.length > 0 && lineItems.some(x => x.subtasks.length === 0)) {
      setErrors({ lineItems: 'You cannot have empty line item, please add sub task or delete the line item.' })
      return false;
    }else {
      setErrors({})
      return true
    }
  }

  const formik = useFormik({
    initialValues: {
      gn: {
        selectedVehicle: null,
        status: 'Open',
        assignedTo: null,
        issuedBy: null,
        issuedDate: null,
        startDate: null,
        completionDate: null,
        vendor: null,
        invoiceNumber: "",
        poNumber: "",
        labels: [],
      }
    },
    validationSchema: Yup.object().shape({
      gn: Yup.object().shape({
        selectedVehicle: Yup.object().shape({
          id: Yup.string().required('Please select vehicle')
        }),
        assignedTo: Yup.object().shape({
          email: Yup.string().required('Please select assigned to')
        }),
        issuedBy: Yup.object().shape({
          email: Yup.string().required('Please select issued by')
        }),
        status: Yup.string().required('Please select status'),
        startDate: Yup.string().required('Please select start date'),
        issuedDate: Yup.string().required('Please select issue date'),
      })
    }),
    onSubmit: (values, { setErrors }) => {
      if (isValid(setErrors)) {
        handleCreateWorkOrder(values); 
      }
    }
  });

  useEffect(() => {
    if (selectedVehicle !== null || typeof selectedVehicle !== 'undefined') {
      formik.setFieldValue('gn.selectedVehicle', selectedVehicle);
    }
  }, [])

  const handleVehicleSelected = (value) => {
    dispatch(workorderSlice.actions.setSelectedVehicle({ vehicle: value }));
  }

  const handleGeneralDetailsChange = () => {
    dispatch(workorderSlice.actions.setGeneralDetails({ general: formik.values }));
  }

  if (!isInitialized) return <></>

  return (
    <React.Fragment>
      <Helmet title="Work Order" />
      <Typography variant="h3" gutterBottom display="inline">
        New Work Order
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/dashboard">
          Dashboard
        </Link>
        <Link component={NavLink} to="/service/workorders">
          Work Orders
        </Link>
        <Typography>New</Typography>

      </Breadcrumbs>

      <Divider my={6} />
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"

        >
          <Grid item xs={12} lg={12}>

            <WorkOrderGeneral
              formik={formik}
              handleVehicleSelected={handleVehicleSelected}
              handleGeneralDetailsChange={handleGeneralDetailsChange} />
          </Grid>

          <Grid item xs={12} lg={12}>
            <WorkOrderIssues vehicle={selectedVehicle} />
          </Grid>

          <Grid item xs={12} lg={12}>
            <WorkOrderItems formik={formik} />
          </Grid>

          <Grid item xs={12} lg={12}>
            {outcome === 'success' && (
              <Alert mt={2} mb={1} severity="success">
                Work Order created successfully
              </Alert>
            )}
            {outcome === 'error' && (
              <Alert mt={2} mb={1} severity="error">
                Failed to create Work Order, please try again. if doesn't resolve, please raise a support ticket.
              </Alert>
            )}
          </Grid>

          <Grid item xs={12} lg={12}>
            <Grid container spacing={2} justifyContent='right' alignItems='right'>
              <Grid item>
                <Button variant='outlined' onClick={() => {
                  console.log({
                    formik
                  })
                }}  >Cancel</Button>
              </Grid>

              <Grid item>

                <LoadingButton
                  type="submit"
                  variant='contained'
                  loading={saving}
                >
                  Save WorkOrder</LoadingButton>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

      </form>

    </React.Fragment>
  );
}

export default NewWorkOrder;
