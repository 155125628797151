import firebase from "../fireStore/config";

const CloudFunctions = firebase.app().functions('europe-west2');

export async function signupWithJoinCode(data) {
    const {email, password, firstName, lastName, phone, joincode} = data; 
    const cloudFunctionName = 'signupUsingJoinCode'; 
    const signup = CloudFunctions.httpsCallable(cloudFunctionName);

    return await signup({
        email: email, 
        firstName: firstName, 
        lastName: lastName, 
        phone: phone, 
        password: password, 
        joincode: joincode
    }).then(response => {
        return response.data; 
    }).catch(err => {
        console.log(err);
        return 'error'; 
    })
}
