import React, { useState } from "react";
import {
    Grid,
    IconButton,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";

import BuildIcon from '@mui/icons-material/Build';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { WorkOrderItemTableHeader } from "./workOrderItemTableHeader";
import WorkOrderItemTableToolBar from "./workOrderItemTableToolBar";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/system";
import { v4 as uuidv4 } from 'uuid';
import TaskIcon from '@mui/icons-material/Task';
import { useDispatch, useSelector } from "react-redux";
import { workorderSlice } from "../../../redux/slices/workorder";
import { currencySymbol } from "../../../fireStore/collections";
import CountryCurrency from "../../common/countryCurrency";


export default function WorkOrderItemTable({ lineItems, readonly }) {

    const [selectedRow, setSelectedRow] = useState({ parent: '', child: '' });

    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    const rowsPerPage = 10;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, lineItems.length - 1 * rowsPerPage);
    const emptyRow = {
        rowNum: 0,
        task: null,
        subtasks: [],
        qty: '-',
        labor: '-',
        parts: '-',
        amount: '-',
    }

    const addLineItem = (lineItem) => {
        dispatch(workorderSlice.actions.addLineItem({ lineItem: lineItem }));
    }

    const deleteLineItem = (rowNum) => {
        dispatch(workorderSlice.actions.deleteLineItem({ rowNum: rowNum }));
    }

    const addLineItemSubTask = (subTask) => {
        dispatch(workorderSlice.actions.addSubTask({ subTask: subTask, selectedRow: selectedRow }));
    }

    const deleteSubTask = (parent, rowNum) => {
        dispatch(workorderSlice.actions.deleteSubTask({ rowNum: rowNum, parent: parent }));
    }

    const handleAddServiceTask = (task) => {
        let newRow = { ...emptyRow }
        newRow.rowNum = uuidv4();
        newRow.task = task;
        newRow.subtasks = []
        addLineItem(newRow);
    }

    const handleLabourAdd = (labour) => {
        if (selectedRow.parent === '') return;
        const subTask = {
            type: 'labour',
            rowNum: uuidv4(),
            task: { id: labour.selectedUser.userId, label: labour.selectedUser.name },
            qty: labour.hours,
            labor: labour.cost,
            parts: '-',
            amount: labour.amount,
            parent: selectedRow.parent
        };

        addLineItemSubTask(subTask);
    }

    const handlePartAdd = (part) => {
        if (selectedRow.parent === '') return;
        const subTask = {
            type: 'part',
            rowNum: uuidv4(),
            task: { id: part.selectedPart.id, label: part.selectedPart.t },
            qty: part.qty,
            labor: '-',
            parts: part.cost,
            amount: part.amount,
            parent: selectedRow.parent
        };

        addLineItemSubTask(subTask)
    }

    const handleDeleteServiceTask = (event, rowNum) => {
        deleteLineItem(rowNum);
        setSelectedRow({ parent: '', child: '' });
        event.stopPropagation();
    }

    const handleRowClick = (event, parent, child) => {
        setSelectedRow({ parent: parent, child: child });
    }

    const handleDeleteSubTask = (event, parent, rowNum) => {
        deleteSubTask(parent, rowNum);
        event.stopPropagation();
    }

    return (

        <Grid container spacing={4} >
            <Grid item xs={12}>
                {!readonly && <WorkOrderItemTableToolBar
                    addServiceTask={handleAddServiceTask}
                    addLabour={handleLabourAdd}
                    addParts={handlePartAdd}
                    rowSelected={selectedRow.parent !== ''} />}
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"

                    >
                        <WorkOrderItemTableHeader readonly={readonly} />
                        <TableBody>
                            {lineItems.length > 0 && lineItems.map(lineItem => {
                                return (
                                    <React.Fragment key={lineItem.rowNum}>
                                        <TableRow
                                            key={lineItem.rowNum}
                                            selected={lineItem.rowNum === selectedRow.parent && selectedRow.child === ''}
                                            onClick={(event) => handleRowClick(event, lineItem.rowNum, '')}
                                        >
                                            <TableCell align="left">
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => setOpen(!open)}
                                                >
                                                    {open > 0 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    <TaskIcon fontSize="small" />

                                                    <Typography marginLeft={2} >{lineItem.task.t}</Typography>
                                                </IconButton>


                                            </TableCell>
                                            <TableCell align="right">{lineItem.qty}</TableCell>
                                            <TableCell align="right">{lineItem.labor !== '-' ? <CountryCurrency value={lineItem.labor} /> : '-'} </TableCell>
                                            <TableCell align="right">{lineItem.parts !== '-' ? <CountryCurrency value={lineItem.parts} /> : '-'} </TableCell>
                                            <TableCell align="right">{lineItem.amount !== '-' ? <CountryCurrency value={lineItem.amount} /> : '-'} </TableCell>

                                            <TableCell padding="none" align="right">
                                                <Box mr={2}>
                                                    <IconButton
                                                        aria-label="share"
                                                        size="large"
                                                        onClick={(event) => handleDeleteServiceTask(event, lineItem.rowNum)}
                                                        disabled={readonly}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key={`${lineItem.rowNum}-1`}>
                                            <TableCell style={{ padding: 0 }} colSpan={6}>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                    <Table
                                                        aria-label="purchases"
                                                        size={"small"}
                                                    >
                                                        <TableBody>
                                                            {lineItem.subtasks.map(subtask => (
                                                                <TableRow
                                                                    key={subtask.rowNum}
                                                                    selected={subtask.rowNum === selectedRow.child}
                                                                    onClick={(event) => handleRowClick(event, subtask.parent, subtask.rowNum)}
                                                                >
                                                                    <TableCell align="left" width={'45%'} sx={{ paddingLeft: 20 }}>
                                                                        <IconButton
                                                                            aria-label="expand row"
                                                                            size="small"
                                                                        >
                                                                            {subtask.type === 'part' ? <BuildIcon fontSize="small" /> : <PersonIcon fontSize="small" />}

                                                                            <Typography marginLeft={2} >{subtask.task.label}</Typography>
                                                                        </IconButton>


                                                                    </TableCell>
                                                                    <TableCell align="right" width={'10%'}>
                                                                        {subtask.qty}
                                                                    </TableCell>
                                                                    <TableCell align="right" width={'10%'}>{subtask.labor !== '-' ? <CountryCurrency value={subtask.labor} /> : '-'}</TableCell>
                                                                    <TableCell align="right" width={'10%'}>{subtask.parts !== '-' ? <CountryCurrency value={subtask.parts} /> : '-'}</TableCell>
                                                                    <TableCell align="right" width={'10%'}>{subtask.amount !== '-' ? <CountryCurrency value={subtask.amount} /> : '-'} </TableCell>
                                                                    <TableCell padding="none" align="right" width={'10%'}>
                                                                        <Box mr={2}>
                                                                            <IconButton
                                                                                disabled={readonly}
                                                                                aria-label="share" size="large" onClick={(event) => handleDeleteSubTask(event, subtask.parent, subtask.rowNum)} >
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}


                            {!readonly && emptyRows > 0 && (
                                <TableRow style={{ height: 5 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>


    );
}