import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
    CardContent,
    Grid,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
    Stack,
    Paper,
    Button,
    CardActions,
    Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import useFireStoreCollection from "../../hooks/useFireStoreCollection";
import { listenServiceTasksFromLookup } from "../../fireStore/workorder";
import { serviceTasksSlice } from "../../redux/slices/servicetasks";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function ServiceTasksView() {

    const dispatch = useDispatch();

    const { tasks } = useSelector(state => state.serviceTasks);

    const { user } = useAuth();
    let clientId = user?.clientId;

    useFireStoreCollection({
        query: () => listenServiceTasksFromLookup(clientId),
        data: (taskDocuments) => transformToLookUp(taskDocuments),
        deps: [clientId],
        refFields: [],
    });

    function transformToLookUp(taskDocuments) {
        let taskLookUp = [];
        taskDocuments.forEach(doc => {
            taskLookUp = [...taskLookUp, ...doc.tasks]
        });

        dispatch(serviceTasksSlice.actions.fetchServiceTasks({ tasks: taskLookUp.sort((a, b) => a.t.localeCompare(b.t)) }));
    }


    return (
        <React.Fragment>
            <Helmet title="Service Tasks" />
            <Typography variant="h3" gutterBottom display="inline">
                Service Tasks
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/dashboard">
                    Dashboard
                </Link>
                <Link>
                    Service
                </Link>
                <Typography>Service Tasks</Typography>
            </Breadcrumbs>

            <Divider my={6} />

            <Grid container spacing={3}>
                {tasks.map(x => (
                    <Grid item xs={12} md={6} lg={2} key={x.id}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {x.t}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" >
                                    <i>No description</i>
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Tooltip title='** Apologies, Please wait till v2 release'>
                                    <Button size="small" color="primary">Edit</Button>
                                </Tooltip>

                                <Tooltip title='** Apologies, Please wait till v2 release'>
                                    <Button size="small" color="error">Delete</Button>
                                </Tooltip>

                            </CardActions>
                        </Card>
                    </Grid>
                ))}

            </Grid>
        </React.Fragment>
    );
}

export default ServiceTasksView;
