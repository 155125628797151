import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    users: [],
    usersLookUp: [],
    currentPlan: null
};


export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchUsers: (state, action) => {
            state.users = [...action.payload.users]; 
        },
        fetchUsersForLookup: (state, action) => {
            state.usersLookUp = [...action.payload.usersLookUp]; 
        },
        setSelectedPlan: (state, action) => {
            state.currentPlan = action.payload.currentPlan
        }
    }
})


export default accountSlice.reducer;