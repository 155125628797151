import { Card, CardContent, Grid, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import React from "react";
import Stats from "../../dashboards/Analytics/Stats";

export default function WorkOrderReportSummary({ summary }) {
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Summary
                </Typography>

                <Grid container spacing={11}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stats
                            title="Total"
                            amount={summary?.grandTotal.toFixed(2)}
                            chip="Monthly"
                            percentagetext={`${summary?.growth_total}%`}
                            percentagecolor={summary?.growth_total < 0 ? red[500] : green[500]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stats
                            title="Labour"
                            amount={summary?.subTotalLabour.toFixed(2)}
                            chip="Monthly"
                            percentagetext={`${summary?.growth_labour}%`}
                            percentagecolor={summary?.growth_labour < 0 ? red[500] : green[500]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stats
                            title="Parts"
                            amount={summary?.subTotalParts.toFixed(2)}
                            chip="Monthly"
                            percentagetext={`${summary?.growth_parts}%`}
                            percentagecolor={summary?.growth_parts < 0 ? red[500] : green[500]}
                            
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Stats
                            title="Tax"
                            amount={summary?.totalTax}
                            chip="Monthly"
                            percentagecolor={summary?.growth_parts < 0 ? red[500] : green[500]}
                            
                        />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
}