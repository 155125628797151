import React from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Checkbox,
    Chip,
    ListItem,
    Paper as MuiPaper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";

import { WorkOrderIssueTableHeader } from "./workOrderIssueTableHeader";
import IssueStatus from "../../common/issueStatus";
import useFireStoreCollection from "../../../hooks/useFireStoreCollection";
import { listenToWorkorderIssues } from "../../../fireStore/vehiclesCollection";
import { workorderSlice } from "../../../redux/slices/workorder";
import { useDispatch, useSelector } from "react-redux";


export default function WorkOrderIssueTableView({ selectedWorkOrder }) {

    const { linkedIssues } = useSelector(state => state.workorder);
    const dispatch = useDispatch();

    
    useFireStoreCollection({
        query: () => listenToWorkorderIssues(selectedWorkOrder.vehicleId, getIssueIds()),
        data: (issues) => dispatch(workorderSlice.actions.setLinkedIssues({ items: issues })),
        deps: [selectedWorkOrder?.issues],
        refFields: [],
        shouldExecute: selectedWorkOrder?.issues.length > 0
    });

    const getIssueIds = () => {
        var issueIds = [];
        selectedWorkOrder?.issues.forEach(item => {
            issueIds.push(item.issueId);
        });

        return issueIds;
    }

    return (
        <TableContainer>
            <Table
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
            >
                <WorkOrderIssueTableHeader readonly={true} />
                <TableBody>
                    {linkedIssues && linkedIssues.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={`${row.id}-${index}`}
                            >
                                <TableCell align="left"  >
                                    <Button mr={2} component={Link} to={`/maintanance/issues/${row.vehicleId}/${row.id}`}>
                                        {`#${row.issueId}`}
                                    </Button>
                                </TableCell>
                                <TableCell align="left">{row.issue}</TableCell>
                                <TableCell align="left">
                                    <IssueStatus status={row.status} />
                                </TableCell>
                                <TableCell align="left">{row.createdBy}</TableCell>
                                <TableCell align="left">{row.created.toLocaleString()}</TableCell>
                            </TableRow>
                        );
                    })}


                </TableBody>
            </Table>
        </TableContainer>
    );
}