import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
    Button,
    ButtonGroup,
    Popover as MuiPopover,
} from "@mui/material";
import BuildIcon from '@mui/icons-material/Build';
import ServiceTaskList from "./serviceTasksList";
import AddLabourView from "./addLabourView";
import TaskIcon from '@mui/icons-material/Task';
import PersonIcon from '@mui/icons-material/Person';
import AddPartsView from "./addPartsView";

const PopoverSt = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const PopoverLb = styled(MuiPopover)`
  .MuiPaper-root {
    width: 500px;
    height: 160px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const PopoverPrt = styled(MuiPopover)`
  .MuiPaper-root {
    width: 500px;
    height: 160px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

export default function WorkOrderItemTableToolBar({ addServiceTask, addLabour, addParts, rowSelected }) {
    const refSt = useRef(null);
    const refLb = useRef(null);
    const refPrt = useRef(null);
    const [isOpenSt, setOpenSt] = useState(false);
    const [isOpenLb, setOpenLb] = useState(false);
    const [isOpenPrt, setOpenPrt] = useState(false);

    const closeSt = () => {
        setOpenSt(false);
    }

    const closeLabour = () => {
        setOpenLb(false);
    }

    const closeParts = () => {
        setOpenPrt(false);
    }

    return (
        <>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button onClick={() => setOpenSt(true)} ref={refSt} startIcon={<TaskIcon />}>Add Service Task</Button>
                <Button onClick={() => setOpenLb(true)} ref={refLb} startIcon={<PersonIcon />} disabled={!rowSelected}>Add Labour</Button>
                <Button onClick={() => setOpenPrt(true)} ref={refPrt} startIcon={<BuildIcon />} disabled={!rowSelected}>Add Parts</Button>
            </ButtonGroup>
            <PopoverSt
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={refSt.current}
                onClose={() => setOpenSt(false)}
                open={isOpenSt}
            >
                <React.Fragment>
                    <ServiceTaskList addServiceTask={addServiceTask} closeServiceTask={closeSt} />
                </React.Fragment>
            </PopoverSt>

            <PopoverLb
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={refLb.current}
                onClose={() => setOpenLb(false)}
                open={isOpenLb}
            >
                <React.Fragment>
                    <AddLabourView addLabour={addLabour} closeLabour={closeLabour} />
                </React.Fragment>
            </PopoverLb>

            <PopoverPrt
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={refLb.current}
                onClose={() => setOpenPrt(false)}
                open={isOpenPrt}
            >
                <React.Fragment>
                    <AddPartsView addParts={addParts} closeParts={closeParts} />
                </React.Fragment>
            </PopoverPrt>


        </>
    );
};