import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  CardActions,
  Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);



function Reports() {
  return (
    <React.Fragment>
      <Helmet title="Reports" />
      <Typography variant="h3" gutterBottom display="inline">
        Reports
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/dashboard">
          Dashboard
        </Link>
        <Typography>Reports</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={6} lg={3} key='1'>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Vehicle Report
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Summary vehicle expenses.
              </Typography>
            </CardContent>
            <CardActions>
              <Tooltip title='Feature will be available soon!!, Appreciate your patience '>
                <Button size="medium" color="primary" component={NavLink} to='/reports/vehicle' disabled>View</Button>
              </Tooltip>

            </CardActions>
          </Card>
        </Grid> */}

        <Grid item xs={12} md={6} lg={3} key='2'>
          <Card  >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Work Order Summary Report
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Summary of work orders created and completed by month.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="medium" color="primary" component={NavLink} to='/reports/workorder'>View</Button>
            </CardActions>
          </Card>
        </Grid>
{/* 
        <Grid item xs={12} md={6} lg={3} key='3'>
          <Card  >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Service Tasks Cost Summary
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Summary Of service tasks expenses by month.
              </Typography>
            </CardContent>
            <CardActions>
              <Tooltip title='Feature will be available soon!!, Appreciate your patience '>
                <Button size="medium" color="primary" component={NavLink} to='/reports/workorder' disabled>View</Button>
              </Tooltip>
            </CardActions>
          </Card>
        </Grid> */}

        {/* <Grid item xs={12} md={6} lg={3} key='4'>
          <Card  >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Vehicle Issue Summary Report
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Summary of issues raised and completed by month and vehicle. 
              </Typography>
            </CardContent>
            <CardActions>
            <Tooltip title='Feature will be available soon!!, Appreciate your patience '>
            <Button size="medium" color="primary" component={NavLink} to='/reports/workorder' disabled>View</Button>
              </Tooltip>
             
            </CardActions>
          </Card>
        </Grid> */}
{/* 
        <Grid item xs={12} md={6} lg={3} key='5'>
          <Card  >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Labour cost summary
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Summary of labour costs per month asasasas asas sas as sa saa sa sas
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="medium" color="primary" component={NavLink} to='/reports/workorder'>View</Button>
            </CardActions>
          </Card>
        </Grid> */}
{/* 
        <Grid item xs={12} md={6} lg={3} key='6'>
          <Card  >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Parts cost summary
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Summary of parts costs per month asasasas asas sas as sa saa sa sas
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="medium" color="primary" component={NavLink} to='/reports/workorder'>View</Button>
            </CardActions>
          </Card>
        </Grid> */}

        {/* <Grid item xs={12} md={6} lg={3} key='7'>
          <Card  >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Inspections Summary
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Summary of inspections created per month sd ad fdasfsdfs
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="medium" color="primary" component={NavLink} to='/reports/workorder'>View</Button>
            </CardActions>
          </Card>
        </Grid> */}

        {/* <Grid item xs={12} md={6} lg={3} key='8'>
          <Card  >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Fuel Expense Summaryf
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Fuel Expense summary per month on vehicles asasaf fdsf sfadf dd sd fdsfsd
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="medium" color="primary" component={NavLink} to='/reports/workorder'>View</Button>
            </CardActions>
          </Card>
        </Grid> */}

      </Grid>

    </React.Fragment>
  );
}

export default Reports;
