
import styled, { withTheme } from "styled-components/macro";
import { Button, Card, CardContent, Typography, Avatar as MuiAvatar, Table, TableBody, TableRow, TableCell, Chip, Grid } from "@mui/material";
import { Box, spacing } from "@mui/system";
import React from "react";
import VehicleWarningMenu from "./vehicleWarningMenu";

const Spacer = styled.div(spacing);

const TableWrapper = styled.div`
  height: 245px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;


export default function VehicleGeneral({ vehicle }) {
    const { reg, make, model, healthStatistics } = vehicle;

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    General
                </Typography>

                <Spacer mb={4} />

                <Centered>
                    <Avatar alt="{reg}" variant="circular" src="/static/img/avatars/truck.jpg" />

                    <Typography variant="body2" component="div" gutterBottom>
                        <Box fontWeight="fontWeightMedium">{reg}</Box>
                        <Box fontWeight="fontWeightRegular">{make} {model}</Box>
                    </Typography>

                    <Button mr={2} variant="text" color="primary" size="small">
                        Change Image
                    </Button>

                </Centered>

                <TableWrapper>
                    <Table size={"medium"}>
                        <TableBody>
                            <TableRow key='healthy'>
                                <TableCell component="th" scope="row">
                                    Issues
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {healthStatistics.healthy ? <Chip label='Healthy' color="success" sx={{ mr: 1 }} />
                                        : <Chip label={`${healthStatistics.activeIssues} Issues`} color="error" sx={{ mr: 1 }} />}

                                </TableCell>
                            </TableRow>

                            <TableRow key='warnings'>
                                <TableCell component="th" scope="row">
                                    Warnings
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    <Chip label={`${healthStatistics.activeWarnings} Warnings`} color="warning" sx={{ mr: 1 }} />
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>

                </TableWrapper>
            </CardContent>
        </Card>
    )
}