
import React from "react";
import { Button, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listenToVehicleRecordedActions } from "../../../fireStore/vehiclesCollection";
import { vehicleSlice } from "../../../redux/slices/vehicles";
import RecordedActionMenu from "../../common/recordedActionMenu";
import { workorderSlice } from "../../../redux/slices/workorder";
import { useNavigate } from "react-router-dom";

export default function VehicleViewToolBar({ selectedVehicle }) {

    const { recordedActions } = useSelector(state => state.vehicles);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = () => listenToVehicleRecordedActions(selectedVehicle.id);
    const dispatchAction = (actions) => {
        dispatch(vehicleSlice.actions.setRecordedActions({ actions: actions }));
    }

    const handleCreateWorkOrder = () => {
        dispatch(workorderSlice.actions.setSelectedVehicle({ vehicle: { id: selectedVehicle.id, label: selectedVehicle.reg } }))
        navigate('/service/workorders/create'); 
    }

    return (
        <Grid container spacing={2} >

            <Grid item>
                <Button variant='contained' color="primary" component={NavLink} to='/vehicles/create'>New Vehicle</Button>
            </Grid>
            <Grid item>
                <Button variant='contained' color="secondary" onClick={handleCreateWorkOrder}>New Work Order</Button>
            </Grid>
            <Grid item>
                <Button variant='contained' color="secondary" component={NavLink} to='/maintanance/issues/create'>New Issue</Button>
            </Grid>
            <Grid item>
                <RecordedActionMenu query={query} dispatchAction={dispatchAction} dispatch={dispatch} recordedActions={recordedActions} />
            </Grid>
        </Grid>
    )
}