import { configureStore } from "@reduxjs/toolkit";
import inspectionsReducer from "./slices/inspections";
import vehiclesReducer from "./slices/vehicles";
import clientsReducer from "./slices/clients";
import plansReducer from "./slices/plans";
import signupReducer from "./slices/signup";
import dashboardReducer from "./slices/dashboard";
import notificationsReducer from "./slices/notifications";
import serviceTaskReducer from "./slices/servicetasks";
import accountReducer from "./slices/account";
import partsReducer from "./slices/parts";
import workorderReducer from "./slices/workorder";
import contactsReducer from "./slices/contacts";
import labelsReducer from "./slices/labels";
import issuesReducer from "./slices/issues";
import workorderreportReducer from "./slices/report_wo";
import remindersReducer from "./slices/reminders";


export const store = configureStore({
  reducer: {
    inspection: inspectionsReducer,
    vehicles: vehiclesReducer,
    clients: clientsReducer,
    plans: plansReducer,
    signup: signupReducer,
    dashboard: dashboardReducer,
    notifications: notificationsReducer,
    serviceTasks: serviceTaskReducer,
    account: accountReducer,
    parts: partsReducer,
    workorder: workorderReducer,
    contact: contactsReducer,
    label: labelsReducer,
    issue: issuesReducer,
    reportWo: workorderreportReducer,
    reminders: remindersReducer
  },
  devTools: process.env.REACT_APP_ENV !== 'prod'
  ,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
