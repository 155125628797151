import { Box, Divider, Fade, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import {
    MoreVert,

} from "@mui/icons-material";
import { Add, Check, Block } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { updateIssueStatus } from "../../functions/issue";
import { issuesSlice } from "../../redux/slices/issues";
import { ignored, resolved } from "../../functions/constants";
import useAuth from "../../hooks/useAuth";
import { useDispatch } from "react-redux";

export default function IssueActions({ id, vehicleId, currentStatus, updatingStatus }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateNew = () => {
        navigate('/maintanance/issues/create')
    }

    const handleResolve = () => {
        updatingStatus(id)
        updateIssueStatus(id, vehicleId, user.id, resolved, '', user.clientId).then(() => {
            dispatch(issuesSlice.actions.updateIssueLookUp({
                issue: {
                    id: id,
                    status: resolved
                }
            }));
        })
        updatingStatus('')
        handleClose();
    }


    const handleReject = () => {
        updatingStatus(id)
        updateIssueStatus(id, vehicleId, user.ud, ignored, '', user.clientId).then(() => {
            dispatch(issuesSlice.actions.updateIssueLookUp({
                issue: {
                    id: id,
                    status: ignored
                }
            }));
        });
        updatingStatus('')
        handleClose();
    }


    return (
        <Box mr={2}>
            <IconButton aria-label="share" size="large"
                aria-owns={'issue-menu'}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>

            <Menu
                id="issue-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleCreateNew}>
                    <ListItemIcon>
                        <Add fontSize="small" />
                    </ListItemIcon>
                    New Issue
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleResolve}>
                    <ListItemIcon>
                        <Check fontSize="small" />
                    </ListItemIcon>
                    Resolve
                </MenuItem>
                <MenuItem onClick={handleReject}>
                    <ListItemIcon>
                        <Block fontSize="small" />
                    </ListItemIcon>
                    Reject
                </MenuItem>
            </Menu>

        </Box>
    )
}