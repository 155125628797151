
import { Chip, Stack } from "@mui/material";
import React from "react";
import { ignored, inprogress, newStatus, resolved } from "../../functions/constants";

export default function InspectionStats({ inspection }) {
    return (
        <Stack direction={{ xs: "row", sm: "row" }}>
            <Chip label={`${inspection.oks} Ok`} color='success' size="small" variant="filled" sx={{ mr: 1 }} />
            <Chip label={`${inspection.defects} Defect`} color='error' size="small" variant="filled" sx={{ mr: 1 }} />
        </Stack>
    )
}