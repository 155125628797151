import { useEffect } from "react";
import { dataFromSnapshotAsync } from "../fireStore/frestoreService";


export default function useFireStoreCollection({ query, data, deps, refFields, shouldExecute = true }) {
    useEffect(() => {
        if (!shouldExecute) {
            console.log('Skipping execution')
            return;
        }

        const unsubscribe = query().onSnapshot(
            async snapshot => {
                const docs = await Promise.all(snapshot.docs.map(async doc => dataFromSnapshotAsync(doc, refFields)));
                data(docs);
            },
            error => console.log(error)
        );

        return () => {
            unsubscribe();
        }
    }, deps) // eslint-disable-line
}