
import { Card, CardContent, Typography, Table, TableBody, TableRow, TableCell, Chip, IconButton, Button, Link, Grid, Avatar, Box, Stack, Divider } from "@mui/material";
import React from "react";
import AvatarChip from "../../common/avatarChip";
import { format } from "date-fns";
import styled from "styled-components/macro";

const TableWrapper = styled.div`
  height: 530px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


export default function WorkOrderGeneralView({ workorder }) {
    const {
        vehicle,
        status,
        assignedTo,
        issuedBy,
        issueDate,
        startDate,
        completionDate,
        labels,
        created,
        createdBy
    } = workorder;


    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    General
                </Typography>

                <TableWrapper>
                    <Table size={"medium"}>
                        <TableBody>
                            <TableRow key='vehicle'>
                                <TableCell component="th" scope="row">
                                    Vehicle
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    <Button mr={2} component={Link} to={`/vehicles/${vehicle.id}`}>
                                        {vehicle.label}
                                    </Button>
                                </TableCell>
                            </TableRow>

                            <TableRow key='status'>
                                <TableCell component="th" scope="row">
                                    Status
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {status === 'Open' && <Chip label='Open' color="warning" sx={{ mr: 1 }} />}
                                    {status === 'Completed' && <Chip label='Completed' color="success" sx={{ mr: 1 }} />}
                                    {status === 'Rejected' && <Chip label='Rejected' color="error" sx={{ mr: 1 }} />}
                                </TableCell>
                            </TableRow>


                            <TableRow key='created'>
                                <TableCell component="th" scope="row">
                                    Created On
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {format(created, 'MM/dd/yyyy hh:mm:ss')}
                                </TableCell>
                            </TableRow>


                            <TableRow key='assignedTo'>
                                <TableCell component="th" scope="row">
                                    Assigned to
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    <AvatarChip name={assignedTo.name} srcObj={assignedTo} />
                                </TableCell>
                            </TableRow>

                            <TableRow key='issuedby'>
                                <TableCell component="th" scope="row">
                                    Issued By
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    <AvatarChip name={issuedBy.name} srcObj={issuedBy} />
                                </TableCell>
                            </TableRow>

                            <TableRow key='issueDate'>
                                <TableCell component="th" scope="row">
                                    Issued Date
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {issueDate !== '' && format(issueDate, 'MM/dd/yyyy')}

                                </TableCell>
                            </TableRow>

                            <TableRow key='startDate'>
                                <TableCell component="th" scope="row">
                                    Start Date
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {startDate !== '' && format(startDate, 'MM/dd/yyyy')}

                                </TableCell>
                            </TableRow>

                            <TableRow key='completionDate'>
                                <TableCell component="th" scope="row">
                                    Completed Date
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {completionDate !== '' && format(completionDate, 'MM/dd/yyyy')}

                                </TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>

                </TableWrapper>
            </CardContent>
        </Card>
    )
}