import React, { useState } from "react"
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { Alert, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components/macro";
import SelectForFormik from "../../components/selectForFormik";
import useAuth from "../../hooks/useAuth";
import { createCustomerQuery } from "../../fireStore/support";

const Centered = styled.div`
  text-align: center;
`;

const reasons = ['Report an Issue', 'Request a feature', 'Query on feature', 'General'];

export default function ContactForm() {
    const [saving, setSaving] = useState(false);
    const [outcome, setOutcome] = useState(null);

    const { user } = useAuth();

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom sx={{ mb: 10 }}>
                    Write to us
                </Typography>



                <Formik
                    key="passwordReset"
                    initialValues={{ whatabout: '', title: '', details: '' }}
                    validationSchema={Yup.object({
                        whatabout: Yup.string().required("Please select"),
                        title: Yup.string().required("Title is required"),
                    })}
                    onSubmit={async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
                        setSaving(true);
                        values.userId = user.id;
                        values.clientId = user.clientId;
                        values.userName = user.displayName;

                        await createCustomerQuery(values).then(() => {
                            setOutcome('success');
                            resetForm();
                        }).catch(err => {
                            setOutcome('error');
                        })
                        setSaving(false);
                    }}
                >
                    {({ errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        status,
                        isValid,
                        touched,
                        values, }) => (
                        <form noValidate onSubmit={handleSubmit}>


                            <Grid container spacing={2} justifyContent='right' alignItems='right'>
                                <Grid item xs={12} ml={10} mr={10} >
                                    {outcome === 'error' && (
                                        <Alert mt={2} mb={3} severity="error" onClose={() => { setOutcome(''); }}>
                                            Please try again. If issue persist, please send an email to help@follis.ie
                                        </Alert>
                                    )}

                                    {outcome === 'success' && (
                                        <Alert mt={2} mb={3} severity="success" onClose={() => { setOutcome(''); }}>
                                            Thanks for your time and valuable feedback, we will get back to you as soon we can.
                                        </Alert>
                                    )}
                                </Grid>
                                <Grid item xs={12} ml={10} mr={10} >
                                    <FormControl sx={{ mt: 3, minWidth: 120 }} fullWidth>
                                        <InputLabel htmlFor="whataboutLabel" >What is it about</InputLabel>

                                        <Field name="whatabout" component={SelectForFormik} >
                                            {reasons.map((whatabout) => (
                                                <MenuItem value={whatabout} key={whatabout}  >
                                                    <Typography variant="subtitle2">{whatabout}</Typography>
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} ml={10} mr={10} >
                                    <TextField
                                        type="text"
                                        name="title"
                                        label="Title"
                                        value={values.title}
                                        error={Boolean(touched.title && errors.title)}
                                        fullWidth
                                        helperText={touched.title && errors.title}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    />
                                </Grid>

                                <Grid item xs={12} ml={10} mr={10} >
                                    <TextField
                                        type="text"
                                        name="details"
                                        label="Details"
                                        value={values.details}
                                        error={Boolean(touched.details && errors.details)}
                                        fullWidth
                                        helperText={touched.details && errors.details}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        rows={5}
                                        my={3}
                                    />
                                </Grid>

                                <Grid item xs={12} ml={10} mr={10} >
                                    <Typography variant="subtitle2" >If you wish to share us any files, please send that to help@follis.ie</Typography>
                                </Grid>
                                <Grid item xs={12} ml={10} mr={10}  >
                                    <LoadingButton
                                        loading={saving} variant="contained" type="submit"
                                        disabled={!isValid || values.title === ''}
                                    >Submit</LoadingButton>
                                </Grid>
                            </Grid>


                        </form>
                    )}
                </Formik>

            </CardContent>
        </Card>
    )
}