import firebase from "../fireStore/config";
const CloudFunctions = firebase.app().functions('europe-west2');
 

export async function getInspectionPdfDownloadUrl(vehicleId, inspectionId, clientId) {
    const cloudFunctionName = 'generateInspectionPdf';
    const generateInspectionPdf = CloudFunctions.httpsCallable(cloudFunctionName);

    const data = {
        vehicleId: vehicleId, 
        inspectionId: inspectionId,
        clientId: clientId
    }

    return await generateInspectionPdf(data).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}