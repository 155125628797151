import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell, Briefcase, AlertCircle } from "react-feather";
import { listenToUserNotifications } from "../../fireStore/userService";
import { useDispatch, useSelector } from "react-redux";
import { notificationSlice } from "../../redux/slices/notifications";
import useAuth from "../../hooks/useAuth";
import useFireStoreCollection from "../../hooks/useFireStoreCollection";
import { acknowledgeNotification } from "../../functions/users";


const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, description, readStatus, Icon, navLink, handleClick }) {
  return (
    <ListItem divider component={Link} to={navLink} onClick={handleClick} >
      {readStatus === false && <>
        <ListItemAvatar>
          <Avatar>
            <SvgIcon fontSize="small">
              <Icon />
            </SvgIcon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            variant: "subtitle2",
            color: "textPrimary",
          }}
          secondary={description}
          secondaryTypographyProps={{
            color: "textSecondary",
          }}
        />
      </>}

      {readStatus === true && <>
        <ListItemAvatar>
          <Avatar>
            <SvgIcon fontSize="small">
              <Icon />
            </SvgIcon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            variant: "subtitle2",
            color: "gray",
          }}
          secondary={description}
        />
      </>}


    </ListItem>
  );
}


function NavbarNotificationsDropdown() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth();

  const { notifications } = useSelector(state => state.notifications);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotificationOpen = (notification) => {
    acknowledgeNotification(notification);
    setOpen(false);
  }

  useFireStoreCollection({
    query: () => listenToUserNotifications(user.id),
    data: (notifications) => dispatch(notificationSlice.actions.addNotifications({ notifications: notifications })),
    deps: [user, dispatch],
    refFields: [],
  });

  const getIcon = (iconValue) => {
    switch (iconValue) {
      case 'expired':
        return Bell;
        break;
      case 'reminder':
        return AlertCircle;
        break;
      case 'workorder':
        return Briefcase;
        break;
      default:
        return Bell;
        break;
    }
  }

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={notifications.filter(x => !x.read).length}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {notifications.filter(x => !x.read).length} New Notification(s)
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {notifications.map(notification =>

              <Notification
                key={notification.id}
                title={notification.title}
                description={notification.header}
                Icon={getIcon(notification.icon)}
                navLink={`/vehicles/${notification.context?.vehicleId}`}
                handleClick={() => handleNotificationOpen(notification)}
                readStatus={notification.read}
              />
            )}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="#">
              Show all notifications
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
