import firebase from "../fireStore/config";
const CloudFunctions = firebase.app().functions('europe-west2');

export const changeSubscriptionPlan = async (plan, subscriptionId, userId) => {
    const changeSubscriptionPlan = CloudFunctions.httpsCallable('changeSubscriptionPlan');
    const data = {
        subscriptionPlanId: subscriptionId,
        stripePriceId: plan.stripePriceId,
        price: plan.price,
        planId: plan.id,
        userId: userId
    }

    return await changeSubscriptionPlan(data);
}

export const updatePaymentMethod = async (paymentMethodId, userId) => {
    const updatePaymentMethod = CloudFunctions.httpsCallable('updatePaymentMethod');
    const data = {
        paymentMethodId,
        userId
    }

    return await updatePaymentMethod(data);
}

export const cancelSubscriptionCloudFunction = async (userId, reason) => {
    const cancelSubscriptionFunc = CloudFunctions.httpsCallable('cancelAppSubscription');
    const data = {
        userId, 
        reason
    }
    
    return await cancelSubscriptionFunc(data);
}