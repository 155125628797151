import { Box, Divider, Fade, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import {
    MoreVert,

} from "@mui/icons-material";
import { Add, NoAccounts, Block } from '@mui/icons-material';
import { deleteUserInvite } from "../../../fireStore/userService";


export default function UserAccountActions({ userItem }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteUser = async (inviteId) => {
        await deleteUserInvite(inviteId).then(() => {
            handleClose()
        });
    }

    return (
        <Box mr={2}>
            <IconButton aria-label="share" size="small"
                aria-owns={'issue-menu'}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>

            <Menu
                id="issue-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem disabled={typeof userItem?.userInviteId === 'undefined'} onClick={() => deleteUser(userItem?.userInviteId)} >
                    <ListItemIcon>
                        <Block fontSize="small" />
                    </ListItemIcon>
                    Delete Account
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <NoAccounts fontSize="small" />
                    </ListItemIcon>
                    Disable Account
                </MenuItem>
            </Menu>

        </Box>
    )
}