import React, { useState } from "react";
import styled from "styled-components/macro";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button as MuiButton, Menu, MenuItem, TextField } from "@mui/material";
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { workorderReportSlice } from "../../redux/slices/report_wo";

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function ReportActions({ handleSetPredicate }) {
  const { filters } = useSelector(state => state.reportWo)
  const dispatch = useDispatch();

  const handleMonthChange = (value) => {
    const monthValue = format(value, 'MMM yyyy');
    dispatch(workorderReportSlice.actions.setSelectedMonth({ currentMonth: monthValue }))
    handleSetPredicate({ currentMonth: monthValue })
  }

  return (
    <React.Fragment>
      <SmallButton size="small" mr={2}>
        <LoopIcon />
      </SmallButton>

      
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year', 'month']}
          label="Year and Month"
          minDate={new Date('2022-05-01')}
          maxDate={new Date()}
          value={filters.currentMonth}
          disableFuture
          onChange={(newValue) => {
            handleMonthChange(newValue)
          }}
          renderInput={(params) => <TextField {...params} size='small' helperText={null} />}
        />
      </LocalizationProvider>

    </React.Fragment>
  );
}

export default ReportActions;
