
import { Card, CardContent, Chip, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import InspectionStats from "../inspection_status";


export default function InspectionDetailHeader({ inspection }) {
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Inspection Details
                </Typography>

                <Table>
                    <TableBody>
                        <TableRow key='vehicleID'>
                            <TableCell component="th" scope="row">
                                Vehicle
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {inspection?.vehicleRegNo}
                            </TableCell>
                        </TableRow>

                        <TableRow key='odeometerreading'>
                            <TableCell component="th" scope="row">
                                Mileage Reading
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {inspection?.mileage} Miles
                            </TableCell>
                        </TableRow>

                        <TableRow key='dateLogged'>
                            <TableCell component="th" scope="row">
                                Date Logged
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {format(inspection?.createdAt, 'dd/MM/yyyy hh:mm')}
                            </TableCell>
                        </TableRow>

                        <TableRow key='loggedBy'>
                            <TableCell component="th" scope="row">
                                Logged By
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                {inspection?.userDisplayName}
                            </TableCell>
                        </TableRow>

                        <TableRow key='stats'>
                            <TableCell component="th" scope="row">
                                Summary
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 500 }}>
                                <InspectionStats inspection={inspection.stats} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
