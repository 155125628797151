import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { postIssueComment } from "../../../functions/issue";
import useAuth from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { issuesSlice } from "../../../redux/slices/issues";
import { getFirebaseTime } from "../../../fireStore/collections";

const initialValues = {
    comment: '',
}

const validationSchema = Yup.object().shape({
})


export default function IssueCommentForm({ selectedIssue }) {
    const [saving, setSaving] = useState(false);
    const { user } = useAuth();
    const { vehicleId, id } = selectedIssue;
    const dispatch = useDispatch(); 
    const { comments } = useSelector(state => state.issue);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}

        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
                isValid,
                setFieldValue,
                resetForm
            }) => (

                <form noValidate>
                    <Grid container spacing={3} padding={4} >
                        <Grid item xs={8} >

                            <TextField
                                name="comment"
                                label="Comment"
                                type="text"
                                multiline
                                rows={2}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                required
                                value={values.comment}
                                error={Boolean(touched.comment && errors.comment)}
                                fullWidth
                                helperText={touched.comment && errors.comment}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                my={3}
                            />

                        </Grid>

                        <Grid item xs={2}  >
                            <Grid container justifyContent="left" alignItems="left" mt={2} >
                                <Grid item >
                                    <LoadingButton
                                        variant="contained"
                                        type="button"
                                        color="primary"
                                        disabled={isSubmitting || values.comment === ''}
                                        loading={saving}
                                        sx={{ ml: 2, mt: 1, mr: 1 }}
                                        onClick={() => {
                                            setSaving(true);
                                            dispatch(issuesSlice.actions.addComment({comment: {
                                                vId: vehicleId, 
                                                iid: id, 
                                                user: {
                                                    userName: user?.displayName, 
                                                    userId: user?.id, 
                                                    avatar: Array.from(user?.displayName)[0].toUpperCase()
                                                },
                                                comment: values.comment,
                                                created:getFirebaseTime(),
                                                id: comments?.length,
                                                dummy: true
                                            }}))
                                            postIssueComment(values.comment, vehicleId, id, user.id);
                                            setSaving(false);
                                            resetForm();
                                        }}
                                    >
                                        Post
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    )
}

