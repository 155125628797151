import React from "react";

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";


const headCells = [
  { id: "item", alignment: "left", label: "Service Task", width: '45%' },
  { id: "qtyhrs", alignment: "right", label: "Qty/Hrs", width: '10%' },
  { id: "labour", alignment: "right", label: "Labour", width: '10%' },
  { id: "parts", alignment: "right", label: "Parts", width: '10%' },
  { id: "amount", alignment: "right", label: "Amount", width: '10%' },
  { id: "actions", alignment: "right", label: "", width: '10%' },
];


export const WorkOrderItemTableHeader = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    readonly
  } = props;
  const createSortHandler = (property) => (event) => {
    if (!readonly)
      onRequestSort(event, property);
  };

  return (
    <TableHead  >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              // onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
