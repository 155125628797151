import * as React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import useAuth from '../hooks/useAuth';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values?.value === '' ? 0 : values.value,
                    },
                });
            }}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale={2}
            isNumericString
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


const CurrencyFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    const { profileData } = useAuth();
    let country = profileData?.country === 'GB' ? '£' : '€';

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale={2}
            isNumericString
            prefix={country}
        />
    );
});

CurrencyFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};



export {
    CurrencyFormatCustom,
    NumberFormatCustom
}
