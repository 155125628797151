import firebase from './config'
import { attachmentsCollection, clientsCollection, recordedActionsCollection, reportsCollection, vehicleCollection, workOrderCollection } from './collections'
import { workorderLookupPartitionSize } from '../functions/partitionSizes'

const db = firebase.firestore();


export function addServiceTask(title, clientId) {
  return db.collection("service_tasks").add({
    title: title,
    clientId: clientId
  })
}

export function addToPartsCollection(title, clientId) {
  return db.collection("parts").add({
    title: title,
    clientId: clientId
  })
}


export function listenServiceTasksFromLookup(clientId) {
  return db.collection("service_tasks").where("clientRef", "==", clientId);
}


export function listenPartsFromLookup(clientId) {
  return db.collection("parts").where("clientRef", "==", clientId);
}


export function listenContactsFromLookup(clientId) {
  return db.collection("contacts").where("clientRef", "==", clientId);
}

export function listenLabelsFromLookup(clientId) {
  return db.collection("labels").where("clientRef", "==", clientId);
}

export async function fetchWorkOrdersFromLookup(predicate, lastLoadedPartition) {
  const clientId = predicate.get("clientId");
  const docType = 'woLookup';

  let result = { empty: true };

  if (lastLoadedPartition === 0) {
    return await db.collection(workOrderCollection)
      .where('clientRef', '==', clientId)
      .where('docType', '==', docType)
      .orderBy('partitionIndex', "desc").limit(1).get().then((snaps) => {
        if (snaps.docs.length !== 0) {
          const { partitionIndex, items } = snaps.docs[0].data();
          result.maxPartition = partitionIndex;
          result.items = [...items];
          result.length = ((partitionIndex - 1) * workorderLookupPartitionSize) + items.length;
          result.lastLoadedPartition = partitionIndex;
        }
        result.empty = false;
        return result;
      })
  } else {
    const nextPartition = lastLoadedPartition - 1;
    if (nextPartition >= 1) {
      return await db.collection(workOrderCollection)
        .where('clientRef', '==', clientId)
        .where('docType', '==', docType)
        .where('partitionIndex', '==', nextPartition).limit(1).get().then(snaps => {
          if (snaps.docs.length !== 0) {
            const { partitionIndex, items } = snaps.docs[0].data();
            result.items = [...items];
            result.lastLoadedPartition = partitionIndex;
          }
          result.empty = false;
          return result;
        })
    } else {
      result.empty = false;
      result.items = [];
      result.lastLoadedPartition = 1;
      return result;
    }
  }
}


export function listenToWorkOrder(workorderId, vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection(workOrderCollection).doc(workorderId);
}

export function listenToWorkOrderRecordedActions(workorderId, vehicleId) {
  return db.collection(vehicleCollection).doc(vehicleId)
    .collection(workOrderCollection).doc(workorderId).collection(recordedActionsCollection);
}


export function listenToWorkOrderSummaryReport(clientId) {
  const year = new Date().getFullYear();
  const type = 'wo_summary'
  const docName = `${type}-${year}`
  return db.collection(clientsCollection).doc(clientId).collection(reportsCollection).doc(docName);
}

export function deleteWorkOrderAttachment(vehicleId, workorderId, attachmentId) {
  return db.collection(vehicleCollection).doc(vehicleId)
    .collection(workOrderCollection).doc(workorderId)
    .collection(attachmentsCollection).doc(attachmentId).delete();
}




export function listenToWorkOrderAttachments(vehicleId, workOrderId) {
  return db.collection(vehicleCollection).doc(vehicleId).collection(workOrderCollection).doc(workOrderId).collection(attachmentsCollection);
}

export function addWorkOrderAttachment(vid, wid, url, name, size, userId, userName) {
  const data = {
    vehicleId: vid,
    workorderId: wid,
    url,
    name,
    size,
    userId,
    userName,
    created: firebase.firestore.Timestamp.now()
  }
  return db.collection(vehicleCollection).doc(vid).collection(workOrderCollection).doc(wid).collection(attachmentsCollection).add(data);
}
