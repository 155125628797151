import { format } from "date-fns";


function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  export {
    isNumeric
  }

  export function unixToDate(value) {
    return format(new Date(value * 1000), 'dd/MM/yyyy');
  }

  export function unixToDateTime(value) {
    return format(new Date(value * 1000), 'dd/MM/yyyy hh:mm');
  }

  export function getClientContext (user) {
    var context = {
        userId: user.uid, 
        userName: user.displayName, 
        clientId: user.clientId, 
        avatar: user.photoURL 
    }

    return context; 
  }