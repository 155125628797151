import { issueLookupPartitionSize } from "../functions/partitionSizes";
import { commentsCollection, issuesCollection, vehicleCollection } from "./collections";
import firebase from './config'

const db = firebase.firestore();

export async function fetchIssuesFromLookup(predicate, lastLoadedPartition) {
  const clientId = predicate.get("clientId");
  const docType = 'issueLookup';

  let result = { empty: true };

  if (lastLoadedPartition === 0) {
    return await db.collection(issuesCollection)
      .where('clientRef', '==', clientId)
      .where('docType', '==', docType)
      .orderBy('partitionIndex', "desc").limit(1).get().then((snaps) => {
        if (snaps.docs.length !== 0) {
          const { partitionIndex, items } = snaps.docs[0].data();
          result.maxPartition = partitionIndex;
          result.items = [...items];
          result.length = ((partitionIndex - 1) * issueLookupPartitionSize) + items.length;
          result.lastLoadedPartition = partitionIndex;
        }
        result.empty = false;
        return result;
      })
  } else {
    const nextPartition = lastLoadedPartition - 1;
    if (nextPartition >= 1) {
      return await db.collection(issuesCollection)
        .where('clientRef', '==', clientId)
        .where('docType', '==', docType)
        .where('partitionIndex', '==', nextPartition).limit(1).get().then(snaps => {
          if (snaps.docs.length !== 0) {
            const { partitionIndex, items } = snaps.docs[0].data();
            result.items = [...items];
            result.lastLoadedPartition = partitionIndex;
          }
          result.empty = false;
          return result;
        })
    } else {
      result.empty = false;
      result.items = [];
      result.lastLoadedPartition = 1;
      return result;
    }
  }
}


export function listenToIssueComments(vehicleId, issueId) {
  return db.collection(vehicleCollection).doc(vehicleId)
    .collection(issuesCollection).doc(issueId).collection(commentsCollection);
}
