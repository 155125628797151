import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";


import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password);

          navigate("/dashboard");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign in
          </Button>
          <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"

          >
            Forgot password
          </Button>

          <Divider my={6} sx={{ marginTop: 3, marginBottom: 3 }} />

          <Grid container spacing={6} justifyContent='space-between' >

            <Grid item xs={12} md={6}>
              <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Start New Subscription
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    If you do not have an active subsciption account, you may click on below link to
                    setup a new subscription account for your company.
                    You will be asked to choose a subscription plan and payment method(Debit/Credit Card).
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="medium" variant="outlined" color="primary" component={Link} to={`/auth/sign-up`}>New Subscription</Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Join with an Invite Code
                  </Typography>
                  <Typography variant="body2" color="text.secondary">

                    If you are invited to join the GeoFleet account of your company by your fleet manager, please click below
                    link to sign up using the Invite Code that you received by your email.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="medium" color="primary" variant="outlined" component={Link} to={`/auth/join`} >Join using Invite Code</Button>
                </CardActions>
              </Card>
            </Grid>

          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
