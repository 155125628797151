
import { Button, Card, CardActions, CardContent, CardMedia, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Link, Paper, Tooltip, Typography, } from "@mui/material";
import React, { useState } from "react"
import AvatarChip from "../avatarChip";
import { LoadingButton } from "@mui/lab";

export default function AttachmentBoxView({ attachments, handleDelete }) {

    const [delAttachmentId, setDelAttachment] = useState('');
    const handleAttachmentDelete = async (attachment) => {
        setDelAttachment(attachment.id);
        await handleDelete(attachment).then(() => {
            setDelAttachment('');
        });
    }

    return (
        <Grid container spacing={2} mt={2}>
            {
                attachments.map((item) => (
                    <Grid item xs={6} md={4} xl={2} key={item.id}>
                        <Card  >
                            <CardMedia
                                component="img"
                                height="140"
                                image={item.url}
                                alt={item.name}

                            />
                            <CardContent>
                                <Typography gutterBottom variant="subtitle2" component="div">
                                    {item.name}
                                </Typography>
                                <AvatarChip name={item.clientContext?.userName} srcObj={item.clientContext} />
                            </CardContent>
                            <CardActions>
                                <Button >
                                    <Link href={item.url} target='_blank' underline="none">
                                         Download
                                    </Link>
                                </Button>
                                <LoadingButton
                                    onClick={() => handleAttachmentDelete(item)}
                                    loading={item.id === delAttachmentId}
                                >Delete</LoadingButton>
                            </CardActions>
                        </Card>
                    </Grid>

                ))
            }
        </Grid>
    )
}