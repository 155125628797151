
import React from "react";
import { Button, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { listenToWorkOrderRecordedActions } from "../../../fireStore/workorder";
import { useDispatch, useSelector } from "react-redux";
import { workorderSlice } from "../../../redux/slices/workorder";
import RecordedActionMenu from "../../common/recordedActionMenu";
import WorkOrderCompleteMenu from "./workOrderCompleteMenu";
import WorkOrderRejectMenu from "./workOrderRejectMenu";

export default function WorkOrderToolBar({ workorderId, vehicleId, status }) {
    const { recordedActions } = useSelector(state => state.workorder);
    const dispatch = useDispatch();

    const query = () => listenToWorkOrderRecordedActions(workorderId, vehicleId);
    const dispatchAction = (actions) => {
        dispatch(workorderSlice.actions.setRecordedActions({ actions: actions }));
    }

    return (
        <Grid container spacing={2} >
          
            <Grid item>
                <WorkOrderCompleteMenu workorderId={workorderId} vehicleId={vehicleId} disabled={status === 'Completed' || status === 'Rejected'} />
            </Grid>
            <Grid item>
                <WorkOrderRejectMenu workorderId={workorderId} vehicleId={vehicleId} disabled={status === 'Completed' || status === 'Rejected'} />
            </Grid>
            <Grid item>
                <Button  variant='outlined' color="primary" component={NavLink} to='/service/workorders/create'>Create New</Button>
            </Grid>

            <Grid item>
                <RecordedActionMenu query={query} dispatchAction={dispatchAction} dispatch={dispatch} recordedActions={recordedActions} />
            </Grid>

        </Grid>
    )
}