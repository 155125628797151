import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import {
    CardContent,
    Grid,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    CircularProgress,
    Stack,
    LinearProgress,
} from "@mui/material";
import ReportToolBar from "./reportToolBar";

export default function ReportLoading() {


    return (
        <React.Fragment>
            <Helmet title="Reports" />

            <Grid container spacing={2}
                alignItems="center"
                justifyContent="center"
            >
                <Grid item xs={12} >
                    <ReportToolBar />
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        height={300}
                    >
                        <CircularProgress />
                    </Stack>

                </Grid>
                <Grid item xs={12} lg={7}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        height={300}
                    >
                        <CircularProgress />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        height={300}
                    >
                        <CircularProgress />
                    </Stack>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

