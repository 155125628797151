
import { Button, IconButton, Link, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react"
import { AttachmentTableHead } from './attachmentTableHeader';
import styled from "styled-components/macro";
import { Delete } from '@mui/icons-material';
import { format } from "date-fns";
import { Tooltip } from "@material-ui/core";
import AvatarChip from "../avatarChip";
import { LoadingButton } from "@mui/lab";
import AttachmentTitle from "./attachmentTitle";


const TableWrapper = styled.div`
  height: 400px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function AttachmentTable({ attachments, handleDelete }) {
    const [delAttachmentId, setDelAttachment] = useState('');
    const handleAttachmentDelete = async (attachment) => {
        setDelAttachment(attachment.id);
        await handleDelete(attachment).then(() => {
            setDelAttachment('');
        });
    }
    return (
        <Paper>
            <TableWrapper>
                <Table
                    aria-labelledby="tableTitle"
                    size={"small"}
                    aria-label="enhanced table"
                >
                    <AttachmentTableHead />
                    <TableBody>
                        {attachments.map((row) => {
                            return (
                                <TableRow
                                    key={`${row.id}`}>

                                    <TableCell align="left">
                                       <AttachmentTitle title={row.name} url={row.url} />
                                    </TableCell>

                                    <TableCell align="left">
                                        {row.size}
                                    </TableCell>

                                    <TableCell align="left">
                                        <AvatarChip name={row.clientContext?.userName} srcObj={row.clientContext} />
                                    </TableCell>

                                    <TableCell align="left">
                                        {format(row.created, 'dd/MM/yyyy hh:mm')}
                                    </TableCell>

                                    <TableCell align="right">
                                        <Tooltip title="Delete File">
                                            <LoadingButton
                                                color="inherit"
                                                onClick={() => handleAttachmentDelete(row)}
                                                loading={row.id === delAttachmentId}
                                            >
                                                <Delete />
                                            </LoadingButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )

                        })}
                    </TableBody>
                </Table>
            </TableWrapper>
        </Paper>
    )
}