import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Popover as MuiPopover,
    TextField,
    Typography,
} from "@mui/material";
import styled from "styled-components";
import { useRef, useState } from "react";
import React from "react";

import WarningIcon from '@mui/icons-material/Warning';

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

export default function VehicleWarningMenu({ vechileId }) {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <IconButton variant='contained' color="success" disabled={false} size='small'
                onClick={() => setOpen(true)} ref={ref}>
                <WarningIcon />
            </IconButton>

            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
            >
                <React.Fragment>
                    <div>Warnings here </div>
                </React.Fragment>
            </Popover>
        </>
    )
}