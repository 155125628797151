import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dataFromSnapshotAsync } from "../../fireStore/frestoreService";
import { fetchClientsFromFireStore } from "../../fireStore/clientsCollection";


export const fetchClientsAsync = createAsyncThunk(
    "clients/fetchClients",
    async ({ limit, predicate, lastDocSnapshot }) => {
        let response = await fetchClientsFromFireStore(predicate, limit, lastDocSnapshot).get();
        var clients = await Promise.all(response.docs.map(async doc => dataFromSnapshotAsync(doc, [])));
        return [...clients];
    }
);

const initialState = {
    clients: [],
    profile: null,
    userprofile: null,
    status: null,
    error: null,
    isLoading: false,
    currentPlan: null
};


export const clientSlice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        fetchClientProfile: (state, action) => {
            state.profile = action.payload.profile;
        },
        setUserProfile: (state, action) => {
            state.userprofile = action.payload.userprofile;
        },
        setCurrentPlan: (state, action) => {
            state.currentPlan = action.payload.plan;
        },
    },
    extraReducers: {
        [fetchClientsAsync.pending]: (state) => {
            state.status = "loading";
            state.isLoading = true;
        },
        [fetchClientsAsync.fulfilled]: (state, action) => {
            state.status = "success";
            state.clients = action.payload;
            state.isLoading = false;
        },
        [fetchClientsAsync.rejected]: (state, action) => {
            state.status = "error";
            state.error = action.payload;
            state.clients = [];
            state.isLoading = false;
        }
    }
})

export default clientSlice.reducer;