
import styled, { withTheme } from "styled-components/macro";
import { Button, Card, CardContent, Typography, Avatar as MuiAvatar, Table, TableBody, TableRow, TableCell, Chip, Grid } from "@mui/material";
import { Box, spacing } from "@mui/system";
import React from "react";


const Spacer = styled.div(spacing);

const TableWrapper = styled.div`
  height: 450px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;



const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;


export default function VehicleProfile({ vehicle }) {
    const { reg, make, model, body, chassis_no, colour, doors, reg_date, seats, version, year_of_manufacture, healthStatistics } = vehicle;

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    General
                </Typography>

                <Spacer mb={4} />


                <TableWrapper>
                    <Table size={"medium"}>
                        <TableBody>
                               <TableRow key='body'>
                                <TableCell component="th" scope="row">
                                    Body
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {body}
                                </TableCell>
                            </TableRow>

                            <TableRow key='chassis_no'>
                                <TableCell component="th" scope="row">
                                    Chassis No
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {chassis_no}
                                </TableCell>
                            </TableRow>

                            <TableRow key='colour'>
                                <TableCell component="th" scope="row">
                                    Colour
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {colour}
                                </TableCell>
                            </TableRow>


                            <TableRow key='doors'>
                                <TableCell component="th" scope="row">
                                    Doors
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {doors}
                                </TableCell>
                            </TableRow>

                            <TableRow key='seats'>
                                <TableCell component="th" scope="row">
                                    Seats
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {seats}
                                </TableCell>
                            </TableRow>

                            <TableRow key='reg_date'>
                                <TableCell component="th" scope="row">
                                    Registration Date
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {reg_date}
                                </TableCell>
                            </TableRow>


                            <TableRow key='version'>
                                <TableCell component="th" scope="row">
                                    Version
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {version}
                                </TableCell>
                            </TableRow>

                            <TableRow key='year_of_manufacture'>
                                <TableCell component="th" scope="row">
                                    Year of manufacture
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 500 }}>
                                    {year_of_manufacture}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableWrapper>
            </CardContent>
        </Card>
    )
}