import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Switch, Tooltip, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useState } from "react";
import { deleteAttachment, uploadToFirebaseStorage } from '../../fireStore/file';
import AttachmentTable from "./attachments/attachmentTable";
import AttachmentBoxView from "./attachments/attachmentBoxView";


export default function UploadAttachment({ storagePath, handleFileUpload, handleAttachmentDelete, attachments, accept }) {
    const [saving, setSaving] = useState(false);
    const [progress, setProgress] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(true);

    const handleSwitch = (event) => {
        setChecked(event.target.checked);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        const file = event.target.files[0];

        if (typeof file === 'undefined' || file?.size > 2097152) {
            setOpen(true);
            return;
        }

        setSaving(true);

        var filename = file.name.replace(/^.*[\\\/]/, '')
        const uploadTask = uploadToFirebaseStorage(storagePath, file, filename);
        let fileSize = 0;
        uploadTask.on("state_changed", (snapshot) => {
            fileSize = snapshot.totalBytes;
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is' + progress + '% done');
            setProgress(progress);
        }, error => {
            console.log(error)
            setSaving(false);
        }, async () => {
            await uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
                handleFileUpload(downloadUrl, filename, fileSize).then(() => {
                    setProgress(100);
                }).catch(error => {
                    console.log(error);
                })
            })
            setSaving(false);
        });
    }

    const handleDelete = async (attachment) => {
        await handleAttachmentDelete(attachment).then(async () => {
            return await deleteAttachment(attachment.url);
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <Card>
            <CardContent>

                <Grid container spacing={2} justifyContent='space-between' mt={1} >
                    <Grid item>
                        <Typography gutterBottom variant="h6" component="div">
                            Attachments
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}  >

                            <Grid item>
                                <Tooltip title="Image View" >
                                    <Switch
                                        checked={checked}
                                        onChange={handleSwitch}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />

                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip title="Max 3 documents; max size 2Mb each.">
                                    <label htmlFor="upload-photo">


                                        <Button color="secondary" variant="contained" component="span"
                                            disabled={saving || attachments.length === 3}

                                        >
                                            <input
                                                style={{ display: 'none' }}
                                                id="upload-photo"
                                                name="upload-photo"
                                                type="file"
                                                onChange={handleChange}
                                                accept={accept}
                                                disabled={saving || attachments.length === 3}
                                            />
                                            <CloudUploadIcon sx={{ marginRight: 2 }} />  {saving && `${progress.toFixed(2)}% complete`}
                                        </Button>

                                    </label>
                                </Tooltip>

                            </Grid>
                        </Grid>

                    </Grid>


                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {checked === true ?
                            <AttachmentBoxView attachments={attachments} handleDelete={handleDelete} /> :
                            <AttachmentTable attachments={attachments} handleDelete={handleDelete} />}
                    </Grid>
                </Grid>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        File size is too big.
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please upload file that is less than 2 MB, you may upload upto 3 files.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
        </Card>
    )
}