import { Alert, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workorderSlice } from '../../../redux/slices/workorder';
import WorkItemFooter from './workItemsFooter';
import WorkOrderItemTable from './workOrderItemTable';


export default function WorkOrderItems({ formik }) {
    const { lineItems } = useSelector(state => state.workorder);
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Line Items
                </Typography>

                <Grid container spacing={2} mt={2} mb={2}>
                    <Grid item>
                        {formik.errors?.lineItems && (
                            <Alert severity="error"  >
                                {formik.errors?.lineItems}
                            </Alert>
                        )}
                    </Grid>
                </Grid>


                <WorkOrderItemTable lineItems={lineItems} readonly={false} />

                <WorkItemFooter lineItems={lineItems} />

            </CardContent>
        </Card>
    )
}