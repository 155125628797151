
import { Card, CardContent, Chip, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import styled, { withTheme } from "styled-components/macro";

const TableWrapper = styled.div`
  height: 445px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;



export default function VehicleEngine({ vehicle }) {

    const { engine_cc, engine_no, fuel, co2_emissions, transmission, gross_vehicle_weight, unladen_weight } = vehicle;
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {"Engine & Weight"}
                </Typography>
                <Box mt={5} >
                    <TableWrapper>
                        <Table size={"medium"}>
                            <TableBody>
                                <TableRow key='engine_cc'>
                                    <TableCell component="th" scope="row">
                                        Engine cc
                                    </TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 500 }}>
                                        {engine_cc}
                                    </TableCell>
                                </TableRow>

                                <TableRow key='engine_no'>
                                    <TableCell component="th" scope="row">
                                        Engine No
                                    </TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 500 }}>
                                        {engine_no}
                                    </TableCell>
                                </TableRow>

                                <TableRow key='fuel'>
                                    <TableCell component="th" scope="row">
                                        Fuel
                                    </TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 500 }}>
                                        {fuel}
                                    </TableCell>
                                </TableRow>

                                <TableRow key='emissions'>
                                    <TableCell component="th" scope="row">
                                        Co2 Emission
                                    </TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 500 }}>
                                        {co2_emissions}
                                    </TableCell>
                                </TableRow>

                                <TableRow key='transmission'>
                                    <TableCell component="th" scope="row">
                                        Transmission
                                    </TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 500 }}>
                                        {transmission}
                                    </TableCell>
                                </TableRow>

                                <TableRow key='gross_vehicle_weight'>
                                    <TableCell component="th" scope="row">
                                        Gross Weight
                                    </TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 500 }}>
                                        {gross_vehicle_weight}
                                    </TableCell>
                                </TableRow>

                                <TableRow key='unladen_weight'>
                                    <TableCell component="th" scope="row">
                                        Unladen Weight
                                    </TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 500 }}>
                                        {unladen_weight}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </TableWrapper>
                </Box>

            </CardContent>
        </Card>
    );
}
