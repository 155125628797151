import { LoadingButton } from "@mui/lab";
import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listenContactsFromLookup } from "../../fireStore/workorder";
import { addContact } from "../../fireStore/contactsCollection";
import useAuth from "../../hooks/useAuth";
import useFireStoreCollection from "../../hooks/useFireStoreCollection";
import { contactsSlice } from "../../redux/slices/contacts";

export default function ContactLookUp({ handleSelect, setFieldValue, label, contactType, ...props }) {

    const dispatch = useDispatch();
    const { user } = useAuth();
    const contactRef = useRef();

    let clientId = user?.clientId;

    const [adding, setAdding] = useState(false);
    const [itemAdded, newItemAdded] = useState(false);

    const { contacts } = useSelector(state => state.contact);

    useFireStoreCollection({
        query: () => listenContactsFromLookup(clientId),
        data: (contacts) => transformToLookUp(contacts),
        deps: [user, itemAdded],
        refFields: [],
        shouldExecute: typeof clientId !== 'undefined'
    });

    function transformToLookUp(contacts) {
        let lookup = [];
        contacts.forEach(doc => {
            lookup = [...lookup, ...doc.items]
        });

        dispatch(contactsSlice.actions.fetchContacts({ contacts: lookup.sort((a, b) => a.t.localeCompare(b.t)) }));
    }

    function sleep(ms, resolve) {
        return new Promise(() => setTimeout(() => { return setAdding(false) }, ms));
    }


    const handleAddNewContact = async (contactName) => {
        if (contactName?.replace(/\s/g, '') === '' || typeof clientId === 'undefined') return;
        setAdding(true);

        contactName = contactName.slice(0, 20);
        const data = {
            name: contactName,
            clientId: clientId,
            type: contactType
        }

        await addContact(data);

        //wait for lookup update
        await sleep(3000);
        newItemAdded(state => !state);
    }

    return <>
        <Autocomplete
            {...props}
            onChange={(event, value) => {
                if (typeof setFieldValue !== 'undefined') setFieldValue(props.name, value);
                if (typeof handleSelect !== 'undefined') handleSelect(value);
            }}
            options={contacts}
            getOptionLabel={(option) => option.t}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.t}
                </Box>
            )}

            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                    inputRef={contactRef}
                    name={'txtredderinput'}
                    helperText="If the vendor not in the list, please type the new vendor and click on the Add To Vendor List option. please expect 3-4 sec delay"
                />}

            noOptionsText={
                <LoadingButton
                    variant="text"
                    loading={adding}
                    onClick={() => { handleAddNewContact(contactRef.current.value) }}>
                    Add to {contactType} List
                </LoadingButton>}
            isOptionEqualToValue={(option, value) => option.id === value.id}

        > </Autocomplete>
    </>
}
