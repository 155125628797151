import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    labels: []
};


export const labelsSlice = createSlice({
    name: "labels",
    initialState,
    reducers: {
        fetchLabels: (state, action) => {
            state.labels = [...action.payload.labels]; 
        } 
    }
})


export default labelsSlice.reducer;