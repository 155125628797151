import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Grid, TextField } from "@mui/material";
import { NumberFormatCustom } from "../../../../components/custom";
import { DesktopDatePicker } from '@mui/lab';
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../../hooks/useAuth";
import { addVehicleReminder } from "../../../../functions/reminder";

const initialValues = {
    title: '',
    expDate: '',
    remindBefore: 0 
}

const validationSchema = Yup.object().shape({
    title:  Yup.string().required('Required'),
    expDate:  Yup.string().required('Required'),
    remindBefore:  Yup.number().required('Required').min(1).max(30),
})

export default function VehicleReminderForm({ vehicle, setOpen }) {

    const [saving, setSaving] = useState(false);

    const { user } = useAuth();
    let clientId = user?.clientId;

    const handleCreateRemiinder = async (values) => {
        setSaving(true);
        await addVehicleReminder(values, vehicle.reg, vehicle.id, clientId, user.id);
        setOpen(false);
        setSaving(false);
    }

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                touched,
                values,
                isValid,
                setFieldValue
            }) => (

                <form noValidate>
                    <Grid container spacing={3} mt={2}>
                        <Grid item xs={12} >
                            <TextField
                                name="title"
                                label="Title"
                                type="text"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                required
                                value={values.title}
                                error={Boolean(touched.title && errors.title)}
                                fullWidth
                                helperText={touched.title && errors.title}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                my={3}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Grid container spacing={2}>
                                <Grid item xs={8} >
                                    <DesktopDatePicker
                                        name='expDate'
                                        label="Reminder Date"
                                        inputFormat="dd/MM/yyyy"
                                        disablePast
                                        value={values.expDate}
                                        onChange={(value) => {
                                            setFieldValue('expDate', value)
                                        }}
                                        renderInput={(params) => <TextField required variant='standard' {...params} fullWidth />}
                                    />
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField
                                        name="remindBefore"
                                        label="Remind Before Days"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            inputProps: {
                                                max: 30, min: 1
                                            }
                                        }}
                                        variant="standard"
                                        required
                                        value={values.remindBefore}
                                        error={Boolean(touched.remindBefore && errors.remindBefore)}
                                        fullWidth
                                        helperText={touched.remindBefore && errors.remindBefore}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt={3} >


                        </Grid>

                        <Grid item xs={12}  >
                            <Grid container justifyContent="right" alignItems="center" >
                                <Grid item>
                                    <Button variant="outlined" onClick={handleClose} >
                                        Cancel
                                    </Button>
                                </Grid>

                                <Grid item >
                                    <LoadingButton
                                        variant="contained"
                                        type="button"
                                        color="primary"
                                        sx={{ ml: 2, mt: 1, mr: 1 }}
                                        loading={saving}
                                        onClick={() => handleCreateRemiinder(values)}
                                        disabled={!isValid || values.title === ''}
                                    >
                                        Save
                                    </LoadingButton>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    )
}