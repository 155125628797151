import { createSlice } from "@reduxjs/toolkit";
 
 
const initialState = {
    inspections: {
        items: [],
        maxPartition: 0,
        lastLoadedPartition: 0,
        length: 0
    },
    selectedInspection: null
};

export const inspectionSlice = createSlice({
    name: "inspection",
    initialState,
    reducers: {
        initInspections: (state, action) => {
            const { maxPartition, length, items, lastLoadedPartition } = action.payload;
            const sortedArry = sortedArray(items)
            state.inspections.length = length;
            state.inspections.maxPartition = maxPartition;
            state.inspections.items = [...sortedArry];
            state.inspections.lastLoadedPartition = lastLoadedPartition;
        },
        setInspections: (state, action) => {
            const { items, lastLoadedPartition } = action.payload;
            if (lastLoadedPartition < state.inspections.lastLoadedPartition) {
                const sortedArry = sortedArray(items)
                state.inspections.items = [...state.inspections.items, ...sortedArry];
                state.inspections.lastLoadedPartition = lastLoadedPartition;
            }
        },
        updateInspectionLookUp: (state, action) => {
            const { id, inspectionId } = action.payload.inspection;
            if (state.inspections.items.some(x => x.id === id)) {
                var lookupItem = state.inspections.items.filter(x => x.id === id)[0];
                var lookupIndex = state.inspections.items.indexOf(lookupItem);

                if (typeof inspectionId !== 'undefined') lookupItem.ispid = inspectionId
                state.inspections.items[lookupIndex] = lookupItem;
            }
        },
        setSelectedInspection: (state, action) => {
            state.selectedInspection = action.payload.inspection;
            state.selectedInspection.stats = getInspectionStats(action.payload.inspection);
        },
    } 
})

const sortedArray = (items) => {
    return items.sort((a, b) => {
        return b.ispid - a.ispid;
    })
}

const getInspectionStats = (inspection) => {
    const { inspectionData } = inspection;
    if (typeof inspectionData === 'undefined') {
        return {
            oks: 0, defects: 0
        }
    }

    const oksCount = inspectionData.filter(x => x.ans === 'OK').length;
    const defectsCount = inspectionData.filter(x => x.ans === 'Defect').length;

    return {
        oks: oksCount, defects: defectsCount
    }
}



export default inspectionSlice.reducer;
