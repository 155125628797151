import { createSlice } from "@reduxjs/toolkit";
import { title } from "faker/lib/locales/az";
import { getFirebaseTime } from "../../fireStore/collections";

const initialState = {
    issues: {
        items: [],
        maxPartition: 0,
        lastLoadedPartition: 0,
        length: 0
    },
    selectedIssue: null,
    recordedActions: [],
    comments: []
};

export const issuesSlice = createSlice({
    name: "issues",
    initialState,
    reducers: {
        initIssues: (state, action) => {
            const { maxPartition, length, items, lastLoadedPartition } = action.payload;
            const sortedArry = sortedArray(items)
            state.issues.length = length;
            state.issues.maxPartition = maxPartition;
            state.issues.items = [...sortedArry];
            state.issues.lastLoadedPartition = lastLoadedPartition;
        },
        setIssues: (state, action) => {
            const { items, lastLoadedPartition } = action.payload;
            if (lastLoadedPartition < state.issues.lastLoadedPartition) {
                const sortedArry = sortedArray(items)
                state.issues.items = [...state.issues.items, ...sortedArry];
                state.issues.lastLoadedPartition = lastLoadedPartition;
            }
        },
        addIssueItem: (state, action) => {
            const { issue, userId, reg, status, vehicleId, id, severity } = action.payload.issue;
            const userName = action.payload.userName;
            const newLookupItem = {
                iid: 'Assigning...',
                t: issue,
                id: id,
                sv: severity,
                st: status,
                rg: reg,
                uid: userId,
                by: userName,
                vid: vehicleId,
                on: getFirebaseTime()
            }

            state.issues.items = [newLookupItem, ...state.issues.items];
            state.issues.length = state.issues.length + 1;

        },
        updateIssueLookUp: (state, action) => {
            const { id, issueId, status } = action.payload.issue;
            if (state.issues.items.some(x => x.id === id)) {
                var lookupItem = state.issues.items.filter(x => x.id === id)[0];
                var lookupIndex = state.issues.items.indexOf(lookupItem);
                if (typeof issueId !== 'undefined') lookupItem.iid = issueId
                lookupItem.st = status

                state.issues.items[lookupIndex] = lookupItem;
            }
        },
        setSelectedIssue: (state, action) => {
            state.selectedIssue = action.payload.issue;
        },
        resetIssues: (state, action) => {
            state.issues.items = [];
            state.issues.maxPartition = 0;
            state.issues.lastLoadedPartition = 0;
            state.issues.length = 0
        },
        setRecordedActions: (state, action) => {
            state.recordedActions = action.payload.actions;
        },
        setComments: (state, action) => {
            state.comments = action.payload.comments;
        },
        addComment: (state, action) => {
            state.comments = [action.payload.comment, ...state.comments]
        },
    }
})

const sortedArray = (items) => {
    return items.sort((a, b) => {
        return b.iid - a.iid;
    })
}

export default issuesSlice.reducer;