import { format, parse } from "date-fns";
import firebase from "../fireStore/config";

const CloudFunctions = firebase.app().functions('europe-west2');

export async function createNewIssue(values, userId, clientId) {
    const cloudFunctionName = 'createVehicleIssue';
    const createVehicleIssue = CloudFunctions.httpsCallable(cloudFunctionName);

    const { vehicle, issue, details, severity, labels } = values;
    const issueData = {
        issueDesc: issue,
        details: details,
        vehicleId: vehicle.id,
        clientId: clientId,
        reg: vehicle.label,
        labels: [],
        userId: userId,
        severity,
        labels
    }

    return await createVehicleIssue(issueData).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}

export async function updateIssueStatus(issueId, vehicleId, userId, status, comment, clientId) {
    const cloudFunctionName = 'updateIssueStatus';
    const updateIssueStatus = CloudFunctions.httpsCallable(cloudFunctionName);
     
    const issueData = {
        issueId, 
        vehicleId, 
        userId, 
        status, 
        comment, 
        clientId
    }
     
    return await updateIssueStatus(issueData).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}

export async function postIssueComment(comment, vehicleId, issueId, userId) {
    const cloudFunctionName = 'postIssueComment';
    const postIssueComment = CloudFunctions.httpsCallable(cloudFunctionName);

    const commentData = {
        vehicleId: vehicleId,
        issueId: issueId,
        userId: userId, 
        comment: comment
    }
    
    console.log(commentData);
 
    return await postIssueComment(commentData).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}

export async function deleteIssueComment(commentId, vehicleId, issueId) {
    const cloudFunctionName = 'deleteIssueComment';
    const deleteIssueComment = CloudFunctions.httpsCallable(cloudFunctionName);

    const commentData = {
        vehicleId: vehicleId,
        issueId: issueId,
        commentId: commentId
    }
 
    return await deleteIssueComment(commentData).then(response => {
        return response.data;
    }).catch(err => {
        console.log(err);
        return 'error';
    })
}