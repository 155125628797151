import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserAccountTable from "./userAccountTable";
import useAuth from "../../../hooks/useAuth";
import { listenUsersFromLookup } from "../../../fireStore/userService";
import { accountSlice } from "../../../redux/slices/account";
import useFireStoreCollection from "../../../hooks/useFireStoreCollection";

export default function UserAccounts() {

    const [itemAdded, newItemAdded] = useState(false);
    const dispatch = useDispatch();
    const { users } = useSelector(state => state.account);

    const { user } = useAuth();
    let clientId = user?.clientId;
    

    useFireStoreCollection({
        query: () => listenUsersFromLookup(clientId),
        data: (userDocs) => transformToLookUp(userDocs),
        deps: [user, itemAdded],
        refFields: [],
        shouldExecute: typeof clientId !== 'undefined'
    });

    function transformToLookUp(userDocs) {
        let usersLookUp = [];
        userDocs.forEach(doc => {
            usersLookUp = [...usersLookUp, ...doc.users.filter(x => x.status === 'active' || x.status === 'invited')]
        });

        dispatch(accountSlice.actions.fetchUsers({ users: usersLookUp }));
    }


    return <React.Fragment>
        <Grid container spacing={6}>
            <Grid item xs={12}>

                <UserAccountTable users={users} newItemAdded={newItemAdded} />
            </Grid>
        </Grid>
    </React.Fragment>
}