import { createSlice } from "@reduxjs/toolkit";
import { getFirebaseTime } from "../../fireStore/collections";

const initialState = {
    workorders: {
        items: [],
        maxPartition: 0,
        lastLoadedPartition: 0,
        length: 0
    },
    selectedWorkOrder: null,
    selectedWorkOrderAttachments: [], 
    recordedActions: [],
    selectedVehicle: null,
    status: 'Open',
    issuedBy: "",
    completionDate: "",
    invoiceNumber: "",
    issues: [],
    lineItems: [],
    attachments: [],
    summary: {
        subTotalLabour: Number(0).toFixed(2),
        subTotalParts: Number(0).toFixed(2),
        subTotalAmount: Number(0).toFixed(2),
        discountAmt: Number(0).toFixed(2),
        taxAmt: Number(0).toFixed(2),
        grandTotal: Number(0).toFixed(2),
        discount: { type: 'percent', value: Number(0).toFixed(2) },
        tax: { type: 'percent', value: Number(0).toFixed(2) },
    },
    comment: '',
    linkedIssues: []
};

export const workorderSlice = createSlice({
    name: "workorder",
    initialState,
    reducers: {
        setSelectedWorkOrder: (state, action) => {
            state.selectedWorkOrder = action.payload.workorder;
        },
        initWorkOrders: (state, action) => {
            const { maxPartition, length, items, lastLoadedPartition } = action.payload;
            const sortedArry = sortedArray(items)
            state.workorders.length = length;
            state.workorders.maxPartition = maxPartition;
            state.workorders.items = [...sortedArry];
            state.workorders.lastLoadedPartition = lastLoadedPartition;
        },
        setWorkOrders: (state, action) => {
            const { items, lastLoadedPartition } = action.payload;
            if (lastLoadedPartition < state.workorders.lastLoadedPartition) {
                const sortedArry = sortedArray(items)
                state.workorders.items = [...state.workorders.items, ...sortedArry];
                state.workorders.lastLoadedPartition = lastLoadedPartition;
            }
        },
        resetWorkOrders: (state, action) => {
            state.items = [];
            state.maxPartition = 0;
            state.lastLoadedPartition = 0;
            state.length = 0
        },
        setSelectedVehicle: (state, action) => {
            state.selectedVehicle = action.payload.vehicle;
            state.issues = [];
        },
        setGeneralDetails: (state, action) => {
            state.status = action.payload.general.status;
        },
        setWorkOrderIssues: (state, action) => {
            state.issues = action.payload.issues;
        },
        addLineItem: (state, action) => {
            state.lineItems = [...state.lineItems, action.payload.lineItem];
        },
        deleteLineItem: (state, action) => {
            state.lineItems = [...state.lineItems.filter(x => x.rowNum !== action.payload.rowNum)];
        },
        addSubTask: (state, action) => {
            let { subTask, selectedRow } = action.payload;
            let lineItems = state.lineItems;

            let lineItem = lineItems.filter(x => x.rowNum === selectedRow.parent)[0];
            let lineItemIndex = lineItems.indexOf(lineItem);
            lineItem.subtasks = [...lineItem.subtasks, subTask];
            let subTotal = lineItem.subtasks.filter(x => x.type === subTask.type).reduce((sum, task) => sum + parseFloat(task.amount), 0).toFixed(2);

            if (subTask.type === 'part') {
                lineItem.parts = subTotal;
            } else {
                lineItem.labor = subTotal;
            }
            const subTotalLabour = lineItem.labor !== '-' ? parseFloat(lineItem.labor) : 0;
            const subTotalParts = lineItem.parts !== '-' ? parseFloat(lineItem.parts) : 0;
            lineItem.amount = (subTotalLabour + subTotalParts).toFixed(2);

            lineItems[lineItemIndex] = lineItem;
            state.lineItems = [...lineItems];
        },
        deleteSubTask: (state, action) => {
            let { rowNum, parent } = action.payload;
            let lineItems = state.lineItems;

            let lineItem = lineItems.filter(x => x.rowNum === parent)[0];
            let lineItemIndex = lineItems.indexOf(lineItem);
            lineItem.subtasks = [...lineItem.subtasks.filter(x => x.rowNum !== rowNum)];

            let labourSub = lineItem.subtasks.filter(x => x.type === 'labour').reduce((sum, task) => sum + parseFloat(task.amount), 0).toFixed(2);
            let partSub = lineItem.subtasks.filter(x => x.type === 'part').reduce((sum, task) => sum + parseFloat(task.amount), 0).toFixed(2);

            lineItem.parts = partSub;
            lineItem.labor = labourSub;

            const subTotalLabour = lineItem.labor !== '-' ? parseFloat(lineItem.labor) : 0;
            const subTotalParts = lineItem.parts !== '-' ? parseFloat(lineItem.parts) : 0;
            lineItem.amount = (subTotalLabour + subTotalParts).toFixed(2);

            lineItems[lineItemIndex] = lineItem;
            state.lineItems = [...lineItems];
        },
        setComment: (state, action) => {
            state.comment = action.payload.comment;
        },
        recalculateWorkOrder: (state, action) => {
            const { discount, tax } = action.payload;
            const lineItems = state.lineItems;

            let subTLab = lineItems.reduce((sum, task) => sum + getValue(task.labor), 0).toFixed(2);
            let subTP = lineItems.reduce((sum, task) => sum + getValue(task.parts), 0).toFixed(2);
            let subTamt = lineItems.reduce((sum, task) => sum + getValue(task.amount), 0).toFixed(2);

            const disValue = Number(discount.value);
            const disType = discount.type;
            const taxValue = Number(tax.value);
            const taxType = tax.type;

            var discountTotal = disValue;
            if (disType === 'percent') {
                discountTotal = subTamt * disValue / 100
            }

            var discountedSubTotal = subTamt;
            var totalTax = taxValue;

            if (taxType === 'percent')
                totalTax = discountedSubTotal * taxValue / 100

            const grandTotal = Number(subTamt) + Number(totalTax) - Number(discountTotal);
            if (grandTotal < 0) grandTotal = 0;

            state.summary = {
                discountAmt: discountTotal.toFixed(2),
                taxAmt: totalTax.toFixed(2),
                grandTotal: grandTotal.toFixed(2),
                subTotalLabour: subTLab,
                subTotalParts: subTP,
                subTotalAmount: subTamt,
                discount,
                tax
            }
        },
        setRecordedActions: (state, action) => {
            state.recordedActions = action.payload.actions;
        },
        addNewWorkOrder: (state, action) => {
            const { id, vehicleId, vehicle, status, summary } = action.payload.workorder;
            const userName = action.payload.userName;

            const lookUpItem = {
                id: id,
                wid: 'Assigning...',
                vid: vehicleId,
                rg: vehicle.label,
                st: status,
                by: userName,
                on: getFirebaseTime(),
                lb: summary.subTotalLabour,
                p: summary.subTotalParts,
                gd: summary.grandTotal
            }

            state.workorders.items = [lookUpItem, ...state.workorders.items];
            state.workorders.length = state.workorders.length + 1;
        },
        updateWorkOrderLookUp: (state, action) => {
            const { id, workorderId, status } = action.payload.workorder;
            if (state.workorders.items.some(x => x.id === id)) {
                var lookupItem = state.workorders.items.filter(x => x.id === id)[0];
                var lookupIndex = state.workorders.items.indexOf(lookupItem);
                lookupItem.wid = workorderId
                lookupItem.st = status

                state.workorders.items[lookupIndex] = lookupItem;
            }
        },
        setLinkedIssues: (state, action) => {
            state.linkedIssues = [...action.payload.items]
        },
        setWorkOrderAttachments: (state, action) => {
            state.selectedWorkOrderAttachments = action.payload.attachments;
        },
    }
})


const getValue = (str) => {
    if (str === '-') return 0;
    return parseFloat(str);
}

const sortedArray = (items) => {
    return items.sort((a, b) => {
        return b.wid - a.wid;
    })
}


export default workorderSlice.reducer;