import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    IconButton,
    LinearProgress,
    Paper as MuiPaper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
} from "@mui/material";
import {
    MoreVert,

} from "@mui/icons-material";
import { spacing } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import { IssueTableHead } from "./issueTableHead";
import { IssueTableToolBar } from "./issueTableToolBar";
import useAuth from "../../../hooks/useAuth";
import { fetchIssuesFromLookup } from "../../../fireStore/issueCollection";
import { issuesSlice } from "../../../redux/slices/issues";
import { format } from "date-fns";
import IssueStatus from "../../common/issueStatus";
import IssueSeverity from "../../common/issueSeverity";
import IssueActions from "../issueActions";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
        el,
        index,
    }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
}

const Paper = styled(MuiPaper)(spacing);

export default function IssueTable() {

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("customer");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [statusChangingRowId, updatingStatusRow] = useState(null);

    const dispatch = useDispatch();

    const { items, lastLoadedPartition } = useSelector(state => state.issue.issues);

    const { user } = useAuth();
    let clientId = user?.clientId;

    let [predicate, setPredicate] = useState(
        new Map([
            ["clientId", clientId]
        ])
    );

    async function fetchIssues() {
        let result = await fetchIssuesFromLookup(predicate, lastLoadedPartition);
        if (result.empty) return;

        if (typeof result.maxPartition !== 'undefined') {
            const { maxPartition, length } = result;
            if (typeof maxPartition !== 'undefined' && typeof length !== 'undefined') {
                dispatch(issuesSlice.actions.initIssues({ ...result }));
            }
        }
        else {
            const { items, lastLoadedPartition } = result;
            dispatch(issuesSlice.actions.setIssues({ items, lastLoadedPartition }));
        }
    }

    useEffect(() => {
        if (typeof clientId === 'undefined') return;
        predicate.set('clientId', clientId);

        fetchIssues();
    }, [dispatch, predicate, clientId]);

    useEffect(() => {
        if (items.length > 0 && items.length < rowsPerPage) {
            loadMore(page);
        }

    }, [items])


    const loadMore = (newPage) => {
        const islastPage = (items.length - (newPage * rowsPerPage)) < rowsPerPage;
        if (islastPage) {
            loadNextPageIfAvailable();
        }
    }

    const loadMoreWhenRowsPerPageChanges = (rowpp) => {
        const islastPage = (items.length - (page * rowpp)) < rowpp;
        if (islastPage) {
            loadNextPageIfAvailable();
        }
    }

    const loadNextPageIfAvailable = async () => {
        await fetchIssues();
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = items.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        loadMore(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        loadMoreWhenRowsPerPageChanges(event.target.value);
    }

    const handleUpdateStatusChange = (id) => {
        updatingStatusRow(id); 
    }

    return (
        <Paper padding={3}>
            <IssueTableToolBar numSelected={selected.length} />
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    size={"small"}
                    aria-label="enhanced table"
                >
                    <IssueTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={items.length}
                    />
                    <TableBody>
                        {stableSort(items, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={`${row.id}-${index}`}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ "aria-labelledby": labelId }}
                                                onClick={(event) => handleClick(event, row.id)}
                                            />
                                        </TableCell>

                                        <TableCell align="left"  >
                                            <Button mr={2} component={Link} to={`/maintanance/issues/${row.vid}/${row.id}`}>
                                                {`#${row.iid}`}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="left"  >
                                            <Button mr={2} component={Link} to={`/vehicles/${row.vid}`}>
                                                {row.rg}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="left">{row.t}</TableCell>
                                        <TableCell align="left">
                                            
                                            {statusChangingRowId === row.id ? <LinearProgress /> : <IssueStatus status={row.st} />}
                                        </TableCell>
                                        <TableCell align="left">
                                            <IssueSeverity severity={row.sv} />
                                        </TableCell>
                                        <TableCell align="left">{row.by}</TableCell>
                                        <TableCell align="left">{format(row.on.toDate(), 'MM/dd/yyyy hh:mm:ss')}</TableCell>

                                        <TableCell padding="none" align="right">
                                            <IssueActions vehicleId={row.vid} id={row.id} currentStatus={row.st} updatingStatus={handleUpdateStatusChange} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={9} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}