
import { Card, CardMedia, Grid, } from "@mui/material";
import React, { useState } from "react"
 

export default function InspectionImages({ inspection}) {
 
    const attachments = inspection?.inspectionData.filter(x => x.url !== null); 
    return (
        <Grid container spacing={2} mt={2}>
            {
                attachments.map((item) => (
                    <Grid item xs={6} md={4} xl={6} key={item.no}>
                        <Card  >
                            <CardMedia
                                component="img"
                                height="200"
                                image={item.url}
                            />
                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    )
}