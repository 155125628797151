import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';




LicenseInfo.setLicenseKey('1ed601c972637f162be995c3e751a263Tz00NjYwNCxFPTE2ODgyMzUzOTA3NzEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
export default function WorkOrderReportItems({ items }) {
    const gridItems = items === null || typeof items === 'undefined'  ? [] : [...items]; 
    const data = {
        rows: [...gridItems],
    
        columns: [
            {
                field: 'id',
                hide: true
            },
            {
                field: 'workorderId',
                headerName: 'WorkOrder'
            },
            {
                field: 'reg',
                headerName: 'Vehicle'
            },
            {
                field: 'status',
                headerName: 'Status'
            },
            {
                field: 'assignedTo',
                headerName: 'Assigned To'
            },
            {
                field: 'completedBy',
                headerName: 'Completed By'
            },
            {
                field: 'completedDate',
                headerName: 'Completed Date'
            },
            {
                field: 'created',
                headerName: 'Created'
            },
            {
                field: 'vendor',
                headerName: 'Vendor'
            },
            {
                field: 'invoiceNumber',
                headerName: 'Invoice Number'
            },
            {
                field: 'poNumber',
                headerName: 'PO Number'
            },
            {
                field: 'issueDate',
                headerName: 'Issue Date'
            },
    
            {
                field: 'startDate',
                headerName: 'Start Date'
            },
            {
                field: 'discountAmt',
                headerName: 'Discount'
            },
            {
                field: 'taxAmt',
                headerName: 'Tax'
            },
            {
                field: 'subTotalLabour',
                headerName: 'Total Labour'
            },
            {
                field: 'subTotalParts',
                headerName: 'Total Parts'
            },
            {
                field: 'grandTotal',
                headerName: 'Grand Total'
            }
        ]
    
    }
    
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Items
                </Typography>

                <div style={{ height: 500, width: "100%" }}>
                    <DataGridPro
                        {...data}
                        loading={data.rows.length === 0}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{
                            Toolbar: GridToolbar,
                        }}

                        componentsProps={{
                            toolbar: { showQuickFilter: true },
                        }}
                    />
                </div>

            </CardContent>
        </Card>
    )
}