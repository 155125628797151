
import {
    Button,
    Popover as MuiPopover,
} from "@mui/material";
import styled from "styled-components";
import { useRef, useState } from "react";
import React from "react";
import UserInviteForm from "./userInviteForm";



const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 600px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;


export default function InviteUserMenu({inviteUser, newItemAdded}) {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Button variant='contained' color="primary"  
                onClick={() => setOpen(true)} ref={ref}>Invite</Button>

            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                open={isOpen}
            >
                <React.Fragment>
                    <UserInviteForm  inviteUser={inviteUser} newItemAdded={newItemAdded} />
                </React.Fragment>
            </Popover>
        </>
    )
}