import * as React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

const languageOptions = {
  en: {
    icon: "/static/img/flags/us.png",
    name: "English",
  },
  fr: {
    icon: "/static/img/flags/fr.png",
    name: "French",
  },
  de: {
    icon: "/static/img/flags/de.png",
    name: "German",
  },
  nl: {
    icon: "/static/img/flags/nl.png",
    name: "Dutch",
  },
};

const flags = [
  { country: 'GB', icon: "/static/img/flags/gb-nir.png" },
  { country: 'IE', icon: "/static/img/flags/ie.png" }
]

function NavbarLanguagesDropdown() {
  const { i18n } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const selectedLanguage = languageOptions[i18n.language];

  const { profileData } = useAuth();
  let country = profileData?.country;

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };


  const currency = country === 'GB' ? '£' : '€';

  return (

    <React.Fragment>
      <Tooltip title={`${country}(${currency})`}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          // onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Flag src={country === 'GB' ? "/static/img/flags/gb-nir.png" : "/static/img/flags/ie.png"} alt={country} />
        </IconButton>

      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;
