import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,

  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import { LoadingButton } from "@mui/lab";
import { signupWithJoinCode } from "../../../functions/auth";
import { useNavigate } from "react-router-dom";
import PrivacyLabel from "../../common/privacyPolicyLabel";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  joincode: '',
  phone: ''
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(20).required("First name is required"),
  lastName: Yup.string().max(20).required("Last name is required"),
  joincode: Yup.string().max(20).required("Please enter the join code recived via your email."),
  phone: Yup.number().optional(),
  email: Yup.string()
    .email("Must be a valid email")
    .max(50)
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Must be at least 12 characters")
    .max(50)
    .required("Required"),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
})

function SignUpWithCodeForm() {

  const { signIn } = useAuth();
  const [terms, setTermsConditions] = useState(false);
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signupWithJoinCode(values).then(response => {
            console.log(response);
            if (response === 'emailNcode_not_found' || response === 'error') {

              setErrors({ submit: 'The join code provided is invalid or expired' });
            } else if (response === 'loginexists') {
              setErrors({ submit: 'Login already exists, please login or try forgot password.' });
            } else {
              return signIn(values.email, values.password).then(() => {
                setStatus({ success: false });
                navigate("/dashboard");
              });
            }
          })
          setSubmitting(false);
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: 'The join code provided is invalid or expired' });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="text"
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} >
              <Grid container spacing={2} >

                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="email"
                    label="Email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="phone"
                    label="Phone"
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} >
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} >
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6} >
                  <TextField
                    type="text"
                    name="joincode"
                    label="Join Code"
                    value={values.companyName}
                    error={Boolean(touched.companyName && errors.companyName)}
                    fullWidth
                    helperText='** Please contact your fleet manager if you do not have received via email.'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
              </Grid>
            </Grid>




            <Grid item xs={12} >
              <Divider />
            </Grid>

            <Grid item xs={12} >
              <FormControlLabel control={<Checkbox value={terms} onChange={(e) => {
                setTermsConditions(e.target.checked);
              }} />} label={<PrivacyLabel />} />
            </Grid>



            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !terms}
                  loading={isSubmitting}
                  sx={{ ml: 2, mt: 1, mr: 1 }}
                >
                  Join
                </LoadingButton>

              </Grid>
            </Grid>

          </Grid>



        </form>
      )}
    </Formik>
  );
}

export default SignUpWithCodeForm;
