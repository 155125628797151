import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    tasks: []
};


export const serviceTasksSlice = createSlice({
    name: "serviceTasks",
    initialState,
    reducers: {
        fetchServiceTasks: (state, action) => {
            state.tasks = [...action.payload.tasks]; 
        } 
    }
})


export default serviceTasksSlice.reducer;