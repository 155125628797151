import { customerQueriesCollection } from './collections';
import firebase from './config'

const db = firebase.firestore();


export function createCustomerQuery(customerQuery) {
    customerQuery.createdAt = firebase.firestore.FieldValue.serverTimestamp(); 
    return db.collection(customerQueriesCollection).add(customerQuery);
}
