import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Paper as MuiPaper,
    Chip as MuiChip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import {
    MoreVert
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ClientsTableToolbar } from "./clientsToolBar";
import { ClientsTableHead } from "./clientsTableHead";



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
        el,
        index,
    }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
}


const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.disabled && grey[700]};
  color: ${(props) =>
        (props.paid || props.sent) && props.theme.palette.common.white};
`;


const Paper = styled(MuiPaper)(spacing);

export default function ClientsTable({ clients }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("customer");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = clients.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, clients.length - page * rowsPerPage);


    return (
        <div>
            <Paper>
                <ClientsTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                    >
                        <ClientsTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={clients.length}
                        />
                        <TableBody>
                            {stableSort(clients, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`${row.id}-${index}`}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                    onClick={(event) => handleClick(event, row.id)}
                                                />
                                            </TableCell>

                                            <TableCell align="left">
                                                <Button mr={2} component={Link} to={`/clients/${row.id}`}>
                                                    {row.name}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="left">

                                                {row.subscriptionLevel === 1 && (
                                                    <Typography >Basic</Typography>
                                                )}

                                                {row.subscriptionLevel === 2 && (
                                                    <Typography >Standard</Typography>
                                                )}

                                                {row.subscriptionLevel === 3 && (
                                                    <Typography >Enterprise</Typography>
                                                )}

                                            </TableCell>
                                            <TableCell align="left">
                                                {row.subscriptionStatus === 'active' && (
                                                    <Chip
                                                        size="small"
                                                        mr={1}
                                                        mb={1}
                                                        label="Active"
                                                        active={+true}
                                                    />
                                                )}

                                                {row.subscriptionStatus === 'disabled' && (
                                                    <Chip
                                                        size="small"
                                                        mr={1}
                                                        mb={1}
                                                        label="Disabled"
                                                        disabled={+true}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                Tax: RE446373
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.email}
                                            </TableCell>
                                            <TableCell padding="none" align="right">
                                                <Box mr={2}>
                                                    <IconButton aria-label="share" size="large">
                                                        <MoreVert />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={clients.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}