import React from "react";

import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import styled from "styled-components/macro";

const Spacer = styled.div`
flex: 1 1 100%;
`;

  const ToolbarTitle = styled.div`
min-width: 150px;
`;


export const VehiclesTableToolbar = (props) => {
    // Here was 'let'
    const { numSelected } = props;
  
    return (
      <Toolbar>
        <ToolbarTitle>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              Vehicles
            </Typography>
          )}
        </ToolbarTitle>
        <Spacer />
        <div>
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="large">
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list" size="large">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    );
  };