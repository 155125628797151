
import { Button, Card, CardContent, Chip, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function InspectionDetailSection({ header, checkListItems, vehicleId }) {
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="subtitle2" gutterBottom>
                    {header}
                </Typography>

                <Table size="small">
                    <TableBody>
                        {checkListItems.map((chkItem) => (
                            <TableRow key={chkItem.key}>
                                <TableCell component="th" scope="row">
                                    <Typography gutterBottom>
                                        {chkItem.no}. {chkItem.qst}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    {typeof chkItem?.issueId !== 'undefined' &&
                                        <Button variant="text" color="primary" component={Link} to={`/maintanance/issues/${vehicleId}/${chkItem?.issueId}`}>#Issue</Button>
                                    }
                                </TableCell>
                                <TableCell align="right">
                                    {chkItem.ans === "OK" ? (
                                        <Chip label='Pass' color="success" variant="outlined" />
                                    ) : (
                                        <Chip label='Fail' color="error" variant="outlined" />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
